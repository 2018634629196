import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import productionPage from "../img/productionPage.png";
import photoNews281223 from "../img/img_news_281223.jpg";
import photoNews261223 from "../img/img_news_261223.jpg";
import photoNews141223 from "../img/img_news_141223.jpg";
import photoNews020223 from "../img/img_news_020223.jpg";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  header: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  root: {
    minWidth: 275,
    marginBottom: 20,
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
}));
function CommunicationWithProduction() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <CardMedia
        className={classes.header}
        component="img"
        alt=""
        image={productionPage}
        title=""
      />
      <div className="text">
        <Typography paragraph variant="h5">
          Зв'язок навчального закладу з промисловими підприємствами є
          невід'ємною частиною навчального процесу при підготовці кваліфікованих
          кадрів.
        </Typography>

        <Card className={classes.root} id="industry281223">
          <CardContent>
            <Typography variant="h5" component="h2">
              Екскурсія на виробництво
            </Typography>
            <Typography paragraph>28.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулася екскурсія викладачів та студентів на підприємство ТДВ
              "Брацлав". Це підприємство є одним з найпотужніших в Вінницькій
              області та має сучасне обладнання. На підприємстві є базові
              практики для студентів спеціальності Прикладна механіка.
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews281223} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Виступи та лекції представників стейкхолдерів
            </Typography>
            <Typography paragraph>26.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Було проведено відкриту лекцію начальником
              виробничо-технологічного відділу ТОВ «Грін Кул» Ницимайлом В.О. на
              тему «Система Lean Management»
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews261223}
                alt=""
                style={{ maxWidth: "1200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Виступи та лекції представників стейкхолдерів
            </Typography>
            <Typography paragraph>14.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Заступник начальника з науки та досліджень Казенного
              науково-виробничого об'єднання "Форт" МВС України Завадюк Сергій
              Вікторович провів студентам 131 спеціальності «Прикладна механіка»
              лекцію на тему "Інжекціне лиття порошку. Рекомендації з
              конструювання литих деталей"
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews141223}
                alt=""
                style={{ maxWidth: "1200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Екскурсія на виробництво
            </Typography>
            <Typography paragraph>04.11.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Студенти ФМТ відвідали підприємство ViOil “Вінницький
              олійножировий комбінат”{" "}
              <a
                href="https://fmt.vntu.edu.ua/studenty-fmt-vidvidaly-pidpryiemstvo-vinnytskyj-olijnozhyrovyj-kombinat-vinoil/#more-1581"
                target="_blank"
              >
                (посилання)
              </a>
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={
                  "https://fmt.vntu.edu.ua/wp-content/uploads/2023/11/vinoil02-1024x768.jpg"
                }
                alt=""
                style={{ maxWidth: "600px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Зустріч зі стейкхолдерами
            </Typography>
            <Typography paragraph>02.02.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулася зустріч колективу кафедри ТАМ із стейкхолдерами
              освітньо-професійної програми «Технології машинобудування» за
              спеціальністю 131 «Прикладна еханіка». На зустрічі були присутні
              представники з виробництва, зокрема начальник
              виробничо-технологічного відділу ТОВ «Грін Кул» Ницимайла В.О.,
              головний технологог ПрАТ «Вінницький завод «МАЯК» Марчук А.П.,
              студенти, випускники ). Усі підтримали ОПП, водночас внесли
              важливі пропозиції, що обовязково будуть враховані. Дякуємо усім
              за співпрацю!
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews020223}
                alt=""
                style={{ maxWidth: "1200px" }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </main>
  );
}

export default CommunicationWithProduction;
