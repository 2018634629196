const baseAndFilial = [
  {
    id: 1,
    type: "base",
    name: "Група компаній ТДВ «БРАЦЛАВ», м. Брацлав.",
  },
  {
    id: 2,
    type: "base",
    name: "Завод «ГРІН КУЛ» -  Вінницький кластер холодильного обладнання.",
  },
  {
    id: 3,
    type: "base",
    name: "ПРАТ «Калинівський машинобудівний завод».",
  },
  {
    id: 4,
    type: "base",
    name: "ТОВ «БАРЛІНЕК ІНВЕСТ».",
  },
  {
    id: 5,
    type: "base",
    name: "Підприємство ООО «АСТОК В»",
  },
  {
    id: 6,
    type: "base",
    name: "ОАО «Барський машзавод».",
  },
  {
    id: 7,
    type: "base",
    name: "Виробниче підприємство «Маріо»",
  },
  {
    id: 8,
    type: "base",
    name: "Вінницький завод тракторних агрегатів - ВАТ «ВЗТА».",
  },
  {
    id: 9,
    type: "base",
    name: "ТМ «Термія» - ПРАТ Вінницький завод «Маяк»",
  },
  {
    id: 10,
    type: "base",
    name: "ТОВ «Вінницький завод нестандартного обладнання»",
  },
  {
    id: 11,
    type: "base",
    name: "ТОВ «Турбівський машинобудівний завод»",
  },
  {
    id: 12,
    type: "base",
    name: "Компанія «ЛЮСТДОРФ»",
  },
  {
    id: 13,
    type: "base",
    name: "Підприємство «АВІС».",
  },
];
export default baseAndFilial;
