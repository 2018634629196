import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Paper } from "@material-ui/core";
import components from "../dataLists/components";
import { Button } from "@material-ui/core";
//import phdPageImage from "../img/phd_img.jpg";

import phdPageImage from"../img/newsPage.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  listItem: {
    fontSize: 18,
  },
  listItemBold: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: "-10px",
    marginBottom: "40px",
  },
  header: {
    backgroundColor: "#B5D3DE",
  },
  mainHeader: {
    backgroundColor: "#7fa0ba",
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperPadding: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));
function Phd() {
  const classes = useStyles();
  

  const componentsList = components.map((item) => {
    return (
      <li key={item.id}>
        <a target="_blank" rel="noreferrer" href={item.href1}>
          {item.title}
        </a>{" "}
      </li>
    );
  });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.media}
        component="img"
        alt=""
        image={phdPageImage}
        title=""
      />

<Typography paragraph>
Процес підготовки науково-педагогічних працівників розпочався у 1994 році у Вінницькому національному технічному університеті коли було відкрито  спеціалізовану вчену раду по захисту дисертацій на здобуття наукового ступеня кандидата технічних наук за спеціальностями: 05.02.03 – «Системи приводів» та 05.03.05 –« Процеси та машини обробки тиском». У 2007 році спеціальність 05.02.03 повністю увійшла до спеціальності 05.02.02 –«Машинознавство» і спеціалізованій вченій раді наказом ВАК України було надано право приймати та проводити захисти кандидатських дисертацій за спеціальностями: 05.02.02 (за напрямом „Системи приводів”) і 05.03.05. 
У 2015 році, відповідно до переліку галузей знань і спеціальностей, за якими здійснюється підготовка здобувачів вищої освіти, наказ Міністерства освіти і науки України від 06.11.2015р. N 1151 спеціальність «Машинознавство» увійшла до галузі знань 13 «Механічна інженерія» та спеціальності 131 «Прикладна механіка».

      </Typography>

      <Typography  id="programs" className={classes.mainHeader} variant="h5">
        ОСВІТНІ ПРОГРАМИ
      </Typography>
      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.header} variant="h5">
          2023 рік:
        </Typography>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/ONP2023.pdf">
            - Освітньо-наукова програма підготовки PhD за спеціальністю 131
            "Прикладна механіка"
          </a>
        </ol>
        <ol id="proekt" className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/ONP2023_proekt.pdf">
            - проєкт (обговорення) нової освітньо-наукової програми підготовки
            PhD за спеціальністю 131 "Прикладна механіка"
          </a>
        </ol>

        <Typography className={classes.header} variant="h5">
          Рецензії-відгуки:
        </Typography>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/review_03.pdf">- ТДВ Брацлав</a>{" "}
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/review_02.pdf">
            - НТУУ України Київський політехнічний університет імені Ігоря
            Сiкорського
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/review_01.pdf">
            - Гданська Політехніка
          </a>
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.header} variant="h5">
          2020 рік:
        </Typography>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/ONP2020.pdf">
            - Освітньо-наукова програма підготовки PhD за спеціальністю 131
            "Прикладна механіка"
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/ONP2020_proekt.pdf">
            - проєкт (обговорення) нової освітньо-наукової програми підготовки
            PhD за спеціальністю 131 "Прикладна механіка"
          </a>
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="componets" className={classes.header} variant="h5">
          Основні освітні компоненти (силабуси)
        </Typography>
        <ol className={classes.listItem}>{componentsList}</ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.mainHeader} variant="h5">
          ТЕМИ ДИСЕРТАЦІЙ
        </Typography>

        <ol className={classes.listItem}> Перепелиця В.І.</ol>
        <ol className={classes.listItem}>
          {" "}
          "Удосконалення системи гідроавтоматики приводу технологічного
          обладнання для формування сирих заготовок цегли"
        </ol>
        <ol className={classes.listItemBold}> д.т.н., професор Козлов Л.Г. </ol>
        <ol className={classes.listItem}> Поліщук О.В.</ol>
        <ol className={classes.listItem}>
          {" "}
          "Вдосконалення гідроагрегатів для мультирежимної гідросистеми
          будівельної міні спецтехніки"
        </ol>
        <ol className={classes.listItemBold}> д.т.н., професор Козлов Л.Г. </ol>
        <ol className={classes.listItem}> Молчанов Я.А.</ol>
        <ol className={classes.listItem}>
          {" "}
          Проектування та удосконалення конструкцій гідроагрегатів на основі
          технології пошарового синтезу полімерних матеріалів
        </ol>
        <ol className={classes.listItemBold}> к.т.н., доцент Петров О.В.</ol>

        <ol className={classes.listItem}> Фарафон А.Ю.</ol>
        <ol className={classes.listItem}>
          {" "}
          Удосконалення конструкції та гідроприводу опорно-поворотного пристрою
          машини маніпуляторного типу
        </ol>
        <ol className={classes.listItemBold}> к.т.н., доцент Петров О.В.</ol>

        <ol className={classes.listItem}> Кавецький О.І.</ol>
        <ol className={classes.listItem}>
          {" "}
          Багатопотоковий електрогідравлічний привод з системою керуванням на
          основі елементів штучного інтелекту
        </ol>
        <ol className={classes.listItemBold}>
          {" "}
          к.т.н., доцент Лозінський Д.О.
        </ol>

        <ol className={classes.listItem}> Півторак В.В.</ol>
        <ol className={classes.listItem}>
          {" "}
          Підвищення ефективності обладнання для обкочування роликом із
          гвинтовою робочою поверхнею
        </ol>
        <ol className={classes.listItemBold}> к.т.н., доцент Сердюк О.В.</ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography
          id="correspondence"
          className={classes.mainHeader}
          variant="h5"
        >
          Відповідність тем дисертацій та наукових публікацій аспірантів
          науковим напрямкам їхніх керівників
        </Typography>
        <Button
          variant="contained"
          size="small"
          className={classes.margin}
          target="_blank"
          rel="noreferrer"
          href="http://tam.vntu.edu.ua/images/correspondence.pdf"
        >
          переглянути
        </Button>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="plans" className={classes.mainHeader} variant="h5">
          Навчальні плани
        </Typography>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/NP_1_01.jpg">
            - Навчальний план (денна форма)
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/NP_1_02.jpg">
            - Перелік вибіркових дисциплін (денна форма)
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/NP_2_01.jpg">
            - Навчальний план (заочна форма)
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/NP_2_02.jpg">
            - Перелік вибіркових дисциплін (заочна форма)
          </a>
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.mainHeader} variant="h5">
          Конференції, в яких брали участь наші аспіранти:
        </Typography>
        <ol className={classes.listItem}>
          XLVІ Науково-технічна конференція факультету машинобудування та
          транспорту, Вінниця, ВНТУ, 2017
        </ol>
        <ol className={classes.listItem}>
          I-а Міжнародна науково-технічна інтернет-конференція «Перспективи
          розвитку машинобудування та транспорту – 2019»
        </ol>
        <ol className={classes.listItem}>
          IV Міжнародна науково-технічна інтернет-конференція " Гідро- та
          пневмоприводи машин – сучасні досягнення та застосування"
        </ol>
        <ol className={classes.listItem}>
          Міжнародна науково-технічна конференція «Гідроаеромеханіка в
          інженерній практиці» (2021)
        </ol>
        <ol className={classes.listItem}>
          XLVIІI Науково-технічна конференція підрозділів Вінницького
          національного технічного університету
        </ol>
        <ol className={classes.listItem}>
          XLIX Науково-технічна конференція факультету машинобудування та
          транспорту, ВНТУ, Вінниця, 2020
        </ol>
        <ol className={classes.listItem}>
          L Науково-технічна конференція підрозділів Вінницького національного
          технічного університету НТКП ВНТУ–2021, Вінниця
        </ol>
        <ol className={classes.listItem}>
          ІІ Міжнародна науково-технічна конференція «Перспективи розвитку
          машинобудування та транспорту – 2021», ВНТУ, Вінниця
        </ol>
        <ol className={classes.listItem}>
          Всеукраїнська науково-практична інтернет-конференція «Молодь в науці:
          дослідження, проблеми, перспективи» (МН-2021), Україна, 2021
        </ol>
        <ol className={classes.listItem}>
          Всеукраїнська науково-практична інтернет-конференція «Молодь в науці:
          дослідження, проблеми, перспективи» (МН-2022), Україна, 2022
        </ol>
        <ol className={classes.listItem}>
          LI Науково-технічна конференція факультету машинобудування та
          транспорту, ВНТУ, Вінниця, 2022
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="contact-us" className={classes.header} variant="h5">
        ЗАУВАЖЕННЯ ТА ПРОПОЗИЦІЇ ЩОДО ОСВІТНЬОЇ ПРОГРАМИ
        </Typography>
        
        <ol className={classes.listItem}>Гарант освітньої програми </ol>
        <ol className={classes.listItem}>к.т.н, доцент Лозінський Д.О. </ol>
        <ol className={classes.listItem}>
        Корпоративна електронна адреса гаранта освітньої програми: lozinskiy_dmitriy@vntu.edu.ua
        </ol>
        <ol className={classes.listItem}>ВНТУ, кафедра ТАМ, аудиторія 1114 </ol>
      </Paper>
    </main>
  );
}

export default Phd;
