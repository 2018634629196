import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import coopeartionPagePageImage from "../img/coopeartionPage.png";

import teachers from "../dataLists/teachers";
import internships from "../dataLists/internship";
import { CardMedia, Paper } from "@material-ui/core";

import diplom1 from "../img/diplom1.png";
import diplom2 from "../img/diplom2.png";
import diplom3 from "../img/diplom3.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  header: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  headerText: {
    width: "100%",
    marginBottom: 20,
    backgroundColor: "#B5D3DE",
    fontSize: "1rem",
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  root: {
    minWidth: 275,
    marginBottom: 20,
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
  listItem: {
    fontSize: 14,
    marginLeft: "-35px",
  },
  paperPadding: {
    padding: theme.spacing(2),
    margin: theme.spacing(0),
  },
}));

function InternationalCooperation() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <CardMedia
        className={classes.header}
        component="img"
        alt=""
        image={coopeartionPagePageImage}
        title=""
      />
      <div className="text">
        <Typography paragraph></Typography>

        <Typography
          className={classes.headerText}
          variant="h6"
          style={{ marginTop: "10px" }}
        >
          Міжнародне співробітництво
        </Typography>
      </div>

      <Typography paragraph>
        На кафедрі ТАМ підтримуються зв’язки з закордонними виробничими та
        навчальними установами, зокрема: австрійською фірмою «FESTO», німецькою
        фірмою «SIEMENS», технічним університетом імені Георга Асахі міста Яси
        (Румунія).{" "}
      </Typography>

      <Typography paragraph className={classes.text}>
        Кафедра підтримує взаємовигідні контакти із провідними науковими
        установами: Технічним університетом м. Яси (Румунія), Західночешським
        університетом м. Пльзень (Чехія), Технологічним університетом м. Гданськ
        (Польща).
      </Typography>

      <Typography
        className={classes.headerText}
        variant="h6"
        style={{ marginTop: "10px" }}
      >
        Міжнародне співробітництво з Ясським технічним університетом «Gheorghe
        Аsaсhi»
      </Typography>

      <Typography paragraph>
        Співробітництво з факультетом інженерії матеріалів (Materials and
        Science Engineering ) Ясського технічного університету «Gheorghe Аsaсhi»
        (Румунія) і факультету машинобудування та транспорту ВНТУ розпочато в
        1997 році і його основою стала кафедра ТАМ. Це співробітництво
        безперервно продовжується і в даний час. Засновники – декан факультету
        Materials and Science Engineering професор Адріан Діма (в ті часи) є
        почесним професором ВНТУ, а декан (з1996 по 2020 рр.) факультету
        машинобудування та транспорту професор Юрій Бурєнніков – почесним
        професором університету «Gheorghe Аsaсhi». Ця наукова співдружність
        включала і технічні факультети університетів міст Сучава і Бакеу
        (Румунія). Обмін делегаціями для участі в наукових конференціях,
        публікація наукових статей в збірниках наукових праць, участь в роботі
        оргкомітетів конференцій стало основою плідних наукових і педагогічних
        стосунків. В минулому – десятки конференцій в університеті «Gheorghe
        Аsaсhi», університетах міст Сучави і Бакеу, наукових публікацій тощо.
        Остання участь в науковій конференції університету «Gheorghe Аsaсhi» –
        2023 рік. Наукова стаття (Perepelytsia V., Kozlov L., Buriennikov Iu.,
        Burennikova N., Kozlov S., Rusu O., Rusu I. Optimization of hydraulic
        drives for synchronizing working movements of automated brick production
        installation. International Journal of Modern Manufacturing
        Technologies. Vol. XV, No. 3. 2023. DOI: https://doi.org/
        10.54684/ijmmt.2023.15.3.199.) за цими матеріалами опублікована в
        збірнику, який входить до науко-метричної бази SCOPUS.
      </Typography>

      <Typography paragraph>
        Працівники кафедри, аспіранти беруть участь у Європейській виставці
        винаходів, яка кожні 2 роки проходить на базі університету «Gheorghe
        Аsaсhi». Професори Л. Козлов і Ю. Бурєнніков нагороджені трьома золотими
        медалями переможців виставок, доцент С. Репінський – однією. За внесок у
        розвиток дружніх відносин між Румунією і Україною, університетами ВНТУ і
        «Gheorghe Аsaсhi» професор Ю. Бурєнніков нагороджений медаллю засновника
        ЯТУ Gheorghe Аsaсhi.
      </Typography>

      <Typography
        className={classes.headerText}
        variant="h6"
        style={{ marginTop: "10px" }}
      >
        Міжнародні нагороди
      </Typography>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <div className={classes.photocontainer}>
          <img className={classes.photo} src={diplom2} alt="" style={{maxWidth:"900px"}} />
        </div>
        <Typography
          paragraph
          className={classes.text}
          style={{ textAlign: "center" }}
        >
          Золоті медалі Європейської виставки винаходів «INVENTIKA» і срібна
          медаль з виставки винаходів в Будапешті за представлені кафедральні
          винаходи, які мали практичне впровадження
        </Typography>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <div className={classes.photocontainer}>
          <img className={classes.photo} src={diplom1} alt="" style={{maxWidth:"900px"}} />
        </div>
        <Typography
          paragraph
          className={classes.text}
          style={{ textAlign: "center" }}
        >
          Винахід «Varlable pump control system» нагороджено золотою медаллю
          Європейської виставки творчості та інновацій EUROINVENT (2019 р.).
        </Typography>
      </Paper>

     

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <div className={classes.photocontainer}>
          <img className={classes.photo} src={diplom3} alt="" />
        </div>
        <Typography
          paragraph
          className={classes.text}
          style={{ textAlign: "center" }}
        >
          Медаль засновника Ясського технічного університету Gheorghe Аsaсhi.
        </Typography>
      </Paper>
    </main>
  );
}

export default InternationalCooperation;
