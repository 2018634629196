import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CardMedia } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";

import photo from "../img/Foto Kozlov.jpg";
import photo1 from "../img/105_2.jpg";
import photo2 from "../img/105_3 (1).jpg";
import forEntrantPageImage from "../img/forEntrantPage.png";

const drawerWidth = 240;

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="100%"
      minwidth="270px"
      height="255"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    margin: 0,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  img: {
    width: "100%",
  },
  main: {
    margin: 5,
    textAlign: "center",
  },
  mainTextCard: {
    margin: 5,
    textAlign: "center",
  },

  mainText: {
    margin: 5,
    textAlign: "center",
    lineHeight: 1.1,
  },

  textWrapper: {
    margin: 5,
    widows: "100%",
    color: "#b80730",

    "&:hover": {
      background: "#FBFBF9",
      //transform: "scaleX(1.1)"
    },
  },

  textlist: {
    color: "#174e5c",
    fontWeight: "bold",
    marginLeft: -20,
  },

  video: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    minWidth: "200px",
  },

  videoCard: {
    margin: 7,
  },

  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
  bossPhoto: {
    justifyContent: "center",
    display: "flex",
    maxWidth: "300px",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
  },
}));
const ForEntrant = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />

      <CardMedia
        className={classes.media}
        // component="img"
        alt=""
        image={forEntrantPageImage}
        title=""
      />

      <Card className={classes.mainTextCard}>
        <CardContent>
          <Button
            style={{ marginTop: "10px", marginBottom: "10px", color: "red" }}
            href="https://vstup.edbo.gov.ua/offer/1343246/"
            variant="contained"
            //variant="outlined"
            color="error"
          >
            Заяви подані на вступ у 2024 !!!
          </Button>

          <Typography variant="h6" className={classes.mainText}>
            Шановний, абітурієнт! Запрошуємо тебе на навчання для отримання
            ступеня вищої освіти "бакалавр" за спеціалістю 131 – “Прикладна
            механіка” та продовження навчання для отримання ступеня вищої освіти
            "магістр".
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.textWrapper}>
        <CardContent>
          <Typography variant="h8">
            ПРИКЛАДНА МЕХАНІКА В СУЧАСНОМУ МАШИНОБУДУВАННІ – ЦЕ КОМП’ЮТЕРНЕ
            ПРОЕКТУВАННЯ ТА ВИРОБНИЦТВО МАШИН, ПРОГРАМУВАННЯ ТА ОБСЛУГОВУВАННЯ
            АВТОМАТИЗОВАНИХ СИСТЕМ, РОБОТИЗОВАНИХ КОМПЛЕКСІВ І ВЕРСТАТІВ З
            ЧИСЛОВИМ ПРОГРАМНИМ ЗАБЕЗПЕЧЕННЯМ. ПРИКЛАДНА МЕХАНІКА Є ПРІОРИТЕТНОЮ
            В УКРАЇНІ:
          </Typography>
          <ul className={classes.textlist}>
            <li> Звичайна стипендія 2550 грн, а підвищена – 3700 грн </li>
            <li> Прохідний бал на бюджет від 130 балів (дані 2023 року) </li>
            <li>
              Для навчання бакалаврів передбачено більше 30 бюджетних місць
            </li>
            <li>
              Практикується стажування та отримання подвійного диплому на базі
              університетів Польщі та Чехії
            </li>
            <li> Іногородні студенти забезпечуються гуртожитком </li>
            <li>
              За бажанням студент може пройти військову підготовку з отримання
              військового звання «молодший лейтенант запасу»
            </li>
          </ul>
          Для довідкової інформації звертайтеся за телефоном: +38 (097)
          212-05-33
        </CardContent>
      </Card>

      <Card className={classes.main}>
        <CardContent>
          <Typography paragraph>
            <a
              target="_blank"
              rel="noreferrer"
              href="http://tam.vntu.edu.ua/images/ОПП_131_bakalavr_2020%20(1).pdf"
            >
              Освітньо-професійна програма підготовки бакалаврів за
              спеціальністю 131 - Прикладна механіка
            </a>
          </Typography>

          <Typography paragraph>
            <a
              target="_blank"
              rel="noreferrer"
              href="http://tam.vntu.edu.ua/images/ОPP%20131%20Bakalav_.pdf"
            >
              Освітньо-професійна програма підготовки бакалаврів за
              спеціальністю 131 - Прикладна механіка (для скороченого терміну
              навчання)
            </a>
          </Typography>

          <Typography variant="h6">
            Отримання ступеня вищої освіти "магістр"
          </Typography>
          <Typography variant="h6">
            <a
              target="_blank"
              rel="noreferrer"
              href="http://tam.vntu.edu.ua/images/FMT_PM_2024.pdf"
            >
              Інформація для абітурієнта
            </a>
          </Typography>
          <Typography variant="h6">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vstup.vntu.edu.ua/images/2024/Programi_magistr/131.pdf"
            >
              Програма фахового вступного іспиту в магістратуру
            </a>
          </Typography>
        </CardContent>
      </Card>

      <div className={classes.video}>
        <div className={classes.videoCard}>
          <YoutubeEmbed embedId="WuuqUgmM0Ag" />
        </div>

        <div className={classes.videoCard}>
          <YoutubeEmbed embedId="OIOqSKsRZkI" />
        </div>
        <div className={classes.videoCard}>
          <YoutubeEmbed embedId="4yDPMaMPFrc" />
        </div>
      </div>      
      <Typography paragraph>
        Випусковою для зазначених спеціальностей та напрямку є кафедра
        “Технологій та автоматизації машинобудування”, яка забезпечує підготовку
        бакалаврів, магістрів та докторів філософії (PhD) денної та заочної форм
        навчання. З 2016 р. кафедру очолює д. т. н., професор Козлов Л.Г.&nbsp;
      </Typography>
      <div className={classes.photocontainer}>
        <img className={classes.bossPhoto} src={photo} alt="" />
      </div>
      <Box style={{ fontSize: "1rem" }}>
        Протягом понад 50 років існування кафедра здійснює підготовку
        інженерів-механіків широкого профілю. На даний час підготовка фахівців
        здійснюється за трьома напрмяками:
        <ul>
          <li>
            інформаційні технології підготовки виробництва в машинобудуванні;
          </li>
          <li>технології машинобудування;</li>
          <li>
            комп’ютеризовані технології та механотронні системи в
            машинобудуванні;
          </li>
        </ul>
        На кафедрі діє аспірантура за науковою спеціальністю 131 -”Прикладна
        механіка”.
      </Box>
      <div className={classes.photocontainer}>
        <img className={classes.photo} src={photo1} alt="" />
      </div>
      <Typography paragraph>
        Підготовка бакалаврів та магістрів за даним фахом відповідає потребам
        регіону. У Вінницькій області й наближених до неї регіонах існує
        розвинутий машинобудівний комплекс, а також багато підприємств харчової,
        переробної, хімічної промисловості, до складу яких входять підрозділи,
        що забезпечують експлуатацію, відновлення та ремонт технологічного
        обладнання і потребують спеціалістів саме цього профілю.&nbsp;
      </Typography>
      <Typography paragraph>
        На сьогоднішній день розподіл молодих спеціалістів, підготовлених
        кафедрою, відбувається на 100%. Наші випуск можуть обіймати посади:
        інженер-конструктор, інженер-технолог, інженер- дослідник, інженер з ТБ,
        майстер цеху, механік цеху та інші суміжні керівні посади. Завдяки
        універсальності спеціальності “Технологія машинобудування”, що
        забезпечує високий рівень фундаментальних та спеціальних інженерних
        знань, вони можуть працювати не тільки в установах і організаціях
        машинобудівного, сільгосптехнічного і авіаційного профілю, але й в інших
        галузях і напрямах.
      </Typography>

      <Typography paragraph>
        Враховуючи, що практично на всіх підприємствах машинобудівного профілю
        передбачається прискорення інноваційного розвитку машинобудування,
        зокрема, для агропромислового комплексу, за рахунок переоснащення
        галузі, застосування новітніх технологій та обладнання, потреби регіону
        в таких спеціалістах є і будуть зростати. Це забезпечить
        працевлаштування випускників інженерів-механіків протягом найближчих
        10-15 років.
      </Typography>

      <div className={classes.photocontainer}>
        <img className={classes.photo} src={photo2} alt="" />
      </div>

      <Typography paragraph>
        Студенти-випускники кафедри ТАМ працевлаштовуються на підприємства
        Вінниці та Вінницької області: холдингова компанія “Nemiroff”, ДП
        “ROSHEN”, ТДВ «БРАЦЛАВ» (м. Брацлав), Завод «ГРІН КУЛ» - Вінницький
        кластер холодильного обладнання, ПРАТ «Калинівський машинобудівний
        завод», ТОВ «БАРЛІНЕК ІНВЕСТ», Підприємство ООО «АСТОК В», ОАО «Барський
        машзавод», Вінницький завод тракторних агрегатів - ВАТ «ВЗТА», ТМ
        «Термія» - ПРАТ Вінницький завод «Маяк», ТОВ «Вінницький завод
        нестандартного обладнання», ТОВ «Турбівський машинобудівний завод»,
        Компанія «ЛЮСТДОРФ», Підприємство «АВІС», Виробниче підприємство
        «Маріо», ВАТ “Вінницький агрегатний завод”, ВАТ “Ямпільський
        приладобудівний завод”, КНВО “ФОРТ”, Турбівський машинобудівний завод
        “АТЕКО”, УВП “УТОС”, 45-й експериментальний завод, Картографічна
        фабрика, завод “Аналог”, завод “Термінал”, HВП “АДТ”, Могилів-
        Подільський машинобудівний завод ім. Кірова, ВАТ “Гідроциліндр”,
        Хмільниксільмаш, ВТТУ, НВФ “Еліт”, Вінницятрансприлад, Вінницький
        дослідний завод та ін.
      </Typography>
      <Typography paragraph>
        Кафедра повністю визнає необхідність поєднання змісту і цілей навчання з
        реаліями сучасного виробництва. В зв’язку з появою нових форм виробничої
        діяльності – малих підприємств, доля яких у машинобудівному комплексі
        постійно зростає, відповідно має зростати і попит на спеціалістів
        широкого, універсального характеру підготовки, що підвищує гнучкість їх
        адаптаційних можливостей до самостійної практичної роботи. Особливу
        актуальність набуває комп’ютерна підготовка майбутніх
        інженерів-механіків, що значно розширює можливості працевлаштування
        після закінчення університету. Це оволодіння сучасними пакетами
        програмного забезпечення: “Solid Works”, “Autodesk Inventor”, “ArtCAM”,
        “MathCad”, “MatLab” та іншими. Ще одним пріоритетним напрямком
        підготовки молодих спеціалістів є оволодіння системами автоматизованого
        програмування верстатів з числовим програмним керуванням – “Solid CAM”.
        Сумісно з виробничими підприємствами створено декілька
        <a href="/practice/#branches" target="_blank">
          {" "}
          філій кафдери
        </a>
        , які використовуються в навчальному процесі, при проходжені практики
        тощо.
      </Typography>
      <Typography paragraph>
        На кафедрі працюють науково-дослідні лабораторії “Борекс-гідравліка” і
        “Гідроагрегат”,
        <a href="/laboratory" target="_blank">
          {" "}
          навчальні лабораторії та начально-методичні центри
        </a>
        . Розвивається прикладна теорія деформівності суцільних та пористих тіл
        при складному та немонотонному навантаженні. Під керівництвом професора,
        к. т. н. Бурєннікова Ю. А. та професора, д.т.н. Козлова Л. Г.
        здійснюються дослідження з моделювання та синтезу гідравлічних систем
        технологічних машин.
      </Typography>
    </div>
  );
};
export default ForEntrant;
