import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Paper } from "@material-ui/core";
import masterComponents from "../dataLists/masterComponents";
import forEntrantPageImage from "../img/forEntrantPage.png";
import articles from "../dataLists/articles";
import ArticleCard from "../components/ArticleCard";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  listItem: {
    fontSize: 18,
  },
  listItemBold: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: "-10px",
    marginBottom: "40px",
  },
  header: {
    backgroundColor: "#B5D3DE",
  },
  mainHeader: {
    backgroundColor: "#7fa0ba",
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperPadding: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));
function Master() {
  const classes = useStyles();

  const componentsList = masterComponents.map((item) => {
    return (
      <li key={item.id}>
        <a target="_blank" rel="noreferrer" href={item.href1}>
          {item.title}
        </a>{" "}
      </li>
    );
  });

  const papersList = articles
    .filter(
      (item) =>
        item.type === "thesis" && item.name.includes(",") && item.year > 2017
    )
    .sort((a, b) => b.year - a.year)
    .map((item, i) => {
      return (
        <ArticleCard
          key={i}
          name={item.name}
          title={item.title}
          description={item.description}
        />
      );
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.media}
        component="img"
        alt=""
        image={forEntrantPageImage}
        title=""
      />

      <Typography paragraph>
        Магістратура вважається одним з трьох елементів Болонської системи вищої
        освіти, поряд з бакалавратом та аспірантурою. Ця система покликана
        полегшити інтеграцію студентів з різних країн в інші європейські
        університети за рахунок системи оцінювання і кредитування навчального
        процесу. Формат магістерського диплому, отриманого в рамках Болонської
        системи, дозволяє без проблем розширювати і поглиблювати свою
        кваліфікацію в будь-якій країні, яка бере участь в Болонському процесі.
      </Typography>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="programs" className={classes.mainHeader} variant="h5">
          ОСВІТНІ ПРОГРАМИ
        </Typography>
        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <Typography className={classes.header} variant="h5">
            2023 рік:
          </Typography>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/M_OPP2023.pdf">
              - Освітньо-професійна програма "Технології машинобудування" для
              підготовки магістрів за спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>
          <ol id="proekt" className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/M_OPP2023_proekt.pdf">
              - Проєкт (обговорення) нової освітньо-професійної програми
              "Технології машинобудування" для підготовки магістрів за
              спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/resultsDiscussion_2023.pdf">
              - Результати обговорення нової освітньо-професійної програми
              "Технології машинобудування" для підготовки магістрів за
              спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>

          <Typography className={classes.header} variant="h5">
            Рецензії-відгуки:
          </Typography>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/master_review_03.pdf">
              - ТОВ "ГРІН КУЛ"
            </a>{" "}
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/master_review_02.pdf">
              - ТОВ "ВП РАДІАТОР"
            </a>
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/master_review_01.pdf">
              - ПрАТ "Вінницький завод "Маяк"
            </a>
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/master_review_04.pdf">
              - Машинобудівний завод ТОВ "ТД Контакт"
            </a>
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/master_review_05.pdf">
              - Інженерно-технологічний факультет ВНАУ
            </a>
          </ol>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <Typography className={classes.header} variant="h5">
            2021 рік:
          </Typography>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/M_OPP2021.pdf">
              - Освітньо-професійна програма "Технології машинобудування" для
              підготовки магістрів за спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/M_OPP2021_proekt.pdf">
              - Проєкт (обговорення) нової освітньо-професійної програми
              "Технології машинобудування" для підготовки магістрів за
              спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/resultsDiscussion_2021.pdf">
              - Результати обговорення нової освітньо-професійної програми
              "Технології машинобудування" для підготовки магістрів за
              спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <Typography className={classes.header} variant="h5">
            2020 рік:
          </Typography>
          <ol className={classes.listItem}>
            <a href="http://tam.vntu.edu.ua/images/master/M_ONP2020.pdf">
              - Освітньо-професійна програма "Технології машинобудування" для
              підготовки магістрів за спеціальністю 131 "Прикладна механіка"
            </a>
          </ol>
        </Paper>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="componets" className={classes.header} variant="h5">
          Основні освітні компоненти (силабуси)
        </Typography>
        <ol className={classes.listItem}>{componentsList}</ol>
      </Paper>

      {/* <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >    
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/MV_2023.pdf">
          Методичні вказівки для виконання магістерських кваліфікаційних робіт для
        студентів спеціальності -131 "Прикладна механікка"
          </a>
        </ol>  
      </Paper> */}

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="plans" className={classes.mainHeader} variant="h5">
          Навчальні плани
        </Typography>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/M_NP_1_01.pdf">
            - Навчальний план (денна форма)
          </a>
        </ol>
        {/* <ol className={classes.listItem}>
          <a
          // href="http://tam.vntu.edu.ua/images/master/M_NP_1_02.jpg"
          >
            - Перелік вибіркових дисциплін (денна форма)
          </a>
        </ol> */}
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/M_NP_2_01.pdf">
            - Навчальний план (заочна форма)
          </a>
        </ol>
        {/* <ol className={classes.listItem}>
          <a
          //href="http://tam.vntu.edu.ua/images/master/M_NP_2_02.pdf"
          >
            - Перелік вибіркових дисциплін (заочна форма)
          </a>
        </ol> */}

        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/M_NP_3_01.pdf">
            - Навчальний план (іноземці, денна форма)
          </a>
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
        id="trainings"
      >
        <Typography id="plans" className={classes.mainHeader} variant="h5">
          Тренінги та стажування здобувачів
        </Typography>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/certificate_m_01.pdf">
            - Лєонтьєв Володимир. Міжнародне стажування за темою «Modern Methods
            in Education»
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/certificate_m_02.pdf">
            - Рижих Олександра. Тренінг на тему «Формуємо активну соціальну
            поведінку» і підтвердженням вміння «Ведення взаємовигідних
            переговорів та уміння створювати надихаючу візію»
          </a>
        </ol>
        <ol className={classes.listItem}>
          <a href="http://tam.vntu.edu.ua/images/master/certificate_m_03.pdf">
            - Мицик Іван. Тренінг на тему «Формуємо активну соціальну поведінку»
            і підтвердженням вміння «Ведення взаємовигідних переговорів та
            уміння створювати надихаючу візію»
          </a>
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
        id="papers"
      >
        <Typography className={classes.mainHeader} variant="h5">
          Публікації здобувачів
        </Typography>

        <ol className={classes.listItem}>{papersList}</ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.mainHeader} variant="h5">
          Конференції, в яких брали участь магістранти:
        </Typography>
        <ol className={classes.listItem}>
          I-а Міжнародна науково-технічна інтернет-конференція «Перспективи
          розвитку машинобудування та транспорту – 2019»
        </ol>
        <ol className={classes.listItem}>
          IV Міжнародна науково-технічна інтернет-конференція " Гідро- та
          пневмоприводи машин – сучасні досягнення та застосування"
        </ol>
        <ol className={classes.listItem}>
          Міжнародна науково-технічна конференція «Гідроаеромеханіка в
          інженерній практиці» (2021)
        </ol>
        <ol className={classes.listItem}>
          XLVIІI Науково-технічна конференція підрозділів Вінницького
          національного технічного університету
        </ol>
        <ol className={classes.listItem}>
          XLIX Науково-технічна конференція факультету машинобудування та
          транспорту, ВНТУ, Вінниця, 2020
        </ol>
        <ol className={classes.listItem}>
          L Науково-технічна конференція підрозділів Вінницького національного
          технічного університету НТКП ВНТУ–2021, Вінниця
        </ol>
        <ol className={classes.listItem}>
          ІІ Міжнародна науково-технічна конференція «Перспективи розвитку
          машинобудування та транспорту – 2021», ВНТУ, Вінниця
        </ol>
        <ol className={classes.listItem}>
          Всеукраїнська науково-практична інтернет-конференція «Молодь в науці:
          дослідження, проблеми, перспективи» (МН-2021), Україна, 2021
        </ol>
        <ol className={classes.listItem}>
          Всеукраїнська науково-практична інтернет-конференція «Молодь в науці:
          дослідження, проблеми, перспективи» (МН-2022), Україна, 2022
        </ol>
        <ol className={classes.listItem}>
          Всеукраїнська науково-практична інтернет-конференція «Молодь в науці:
          дослідження, проблеми, перспективи» (МН-2023), Україна, 2023
        </ol>
        <ol className={classes.listItem}>
          LI Науково-технічна конференція факультету машинобудування та
          транспорту, ВНТУ, Вінниця, 2022
        </ol>
        <ol className={classes.listItem}>
          LII Науково-технічна конференція факультету машинобудування та
          транспорту, ВНТУ, Вінниця, 2023
        </ol>
        <ol className={classes.listItem}>
          ІІ Міжнародна науково-технічна конференція «Перспективи розвитку
          машинобудування та транспорту – 2023», ВНТУ, Вінниця
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="contact-us" className={classes.header} variant="h5">
          ЗАУВАЖЕННЯ ТА ПРОПОЗИЦІЇ ЩОДО ОСВІТНЬОЇ ПРОГРАМИ
        </Typography>

        <ol className={classes.listItem}>Гарант освітньої програми </ol>
        <ol className={classes.listItem}>к.т.н, доцент Сердюк О.В. </ol>
        <ol className={classes.listItem}>
          Корпоративна електронна адреса гаранта освітньої програми:
          serdyuk0904@vntu.edu.ua
        </ol>
        <ol className={classes.listItem}>ВНТУ, кафедра ТАМ, аудиторія 1114 </ol>
      </Paper>
    </main>
  );
}

export default Master;
