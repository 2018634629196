import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Paper } from "@material-ui/core";
import components from "../dataLists/components";
import graduates from "../dataLists/graduates";

import { Button } from "@material-ui/core";
//import phdPageImage from "../img/phd_img.jpg";

import phdPageImage from "../img/newsPage.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  listItem: {
    fontSize: 18,
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "-30px",
  },
  listItemBold: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: "-10px",
    marginBottom: "40px",
  },
  header: {
    backgroundColor: "#B5D3DE",
  },
  mainHeader: {
    backgroundColor: "#7fa0ba",
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperPadding: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));
function Graduates() {
  const classes = useStyles();

  const componentsList = graduates.map((item) => {
    return (
      <React.Fragment key={item.id}>
        <Typography className={classes.header} variant="h6">
          {item.name}
        </Typography>
        {item.degree && <ol className={classes.listItem}> {item.degree} </ol>}
        <ol className={classes.listItem}> {item.description} </ol>
        {item.description1 && <ol className={classes.listItem}> {item.description1} </ol>}
        {item.description2 && <ol className={classes.listItem}> {item.description2} </ol>}
        {item.description3 && <ol className={classes.listItem}> {item.description3} </ol>}
      </React.Fragment>
    );
  });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.media}
        component="img"
        alt=""
        image={phdPageImage}
        title=""
      />

      <Typography paragraph>Випускники кафедри ТАМ</Typography>

      <Typography id="programs" className={classes.mainHeader} variant="h5">
        Випускники кафедри ТАМ
      </Typography>
      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        {componentsList}
      </Paper>
    </main>
  );
}

export default Graduates;
