const components = [
  {
    id: "1",
    title: "Філософсько-світоглядні засади сучасної науки й цивілізації",
    href1: "http://tam.vntu.edu.ua/images/ok_1.pdf",
  },
  {
    id: "2",
    title: "Іноземна мова наукового спрямування",
    href1: "http://tam.vntu.edu.ua/images/ok_2.pdf",
  },
  {
    id: "3",
    title: "Сучасні педагогічні технології у закладах вищої освіти",
    href1: "http://tam.vntu.edu.ua/images/ok_3.pdf",
  },
  {
    id: "4",
    title: "Математичне моделювання в наукових дослідженнях",
    href1: "http://tam.vntu.edu.ua/images/ok_4.pdf",
  },
  {
    id: "5",
    title: "Приводи машин, їх структурний та параметричний синтез",
    href1: "http://tam.vntu.edu.ua/images/ok_5.pdf",
  },
  {
    id: "6",
    title:
      "Технологія виготовлення та методи забезпечення надійності деталей і приводів машин",
    href1: "http://tam.vntu.edu.ua/images/ok_6.pdf",
  },
  {
    id: "7",
    title: "Апаратні та програмні засоби керування приводами машин",
    href1: "http://tam.vntu.edu.ua/images/ok_7.pdf",
  },
  {
    id: "8",
    title: "Методи та засоби експериментальних досліджень машин та механізмів",
    href1: "http://tam.vntu.edu.ua/images/ok_8.pdf",
  },
  {
    id: "9",
    title: "Моделювання та імітаційні дослідження технологічних процесів, механізмів та машин",
    href1: "http://tam.vntu.edu.ua/images/ok_9.pdf",
  },
  {
    id: "10",
    title: "Педагогічна практика",
    href1: "http://tam.vntu.edu.ua/images/ok_10.pdf",
  },
];
export default components;
