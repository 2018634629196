import * as React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { CenterFocusStrong } from "@mui/icons-material";
// const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    zIndex: 2,
    backgroundColor: "#026E89",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    flexGrow: 1,
    marginLeft: 0,
    minHeight:40,    
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="inherit">
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function StickyFooter() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Box>
        <CssBaseline />

        <Box>
          <Container
          // maxWidth="sm"
          >
            <Typography variant="body1">
              КАФЕДРА ТАМ {"Copyright © "}
              {new Date().getFullYear()}  {"    Телефон для довідки: +38 (097) 212-05-33"}
            </Typography>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
