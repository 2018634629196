const booksMethod = [
    {
        "id": "13",
        "title": "Методичні вказівки до виконання курсової роботи з дисципліни «САПР технологічної підготовки машинобудівного виробництва» / Укладачі: О.В.Петров, Л.Г.Козлов, Д.О.Лозінський.– Вінниця: ВНТУ, 2013. – 60 с.",
    },
    {
        "id": "14",
        "title": "Методичні вказівки для виконання контрольних робіт з дисципліни «Технологічна оснастка» / Укладачі: О.В.Петров, С.І.Сухоруков, Н.В.Ляховченко.– Вінниця: ВНТУ, 2012. – 45 с.",
    },
    {
        "id": "15",
        "title": "Методичні вказівки до виконання лабораторних робіт з дисциплін «Комп’ютерна графіка в технологічному проектуванні» та «Комп’ютерна графіка в машинобудуванні»/ Ю.В.Булига, С.І.Сухоруков.– Вінниця: ВНТУ, 2013. – 78с.",
    },
    {
        "id": "16",
        "title": "Методичні вказівки для виконання контрольних та курсових робіт з дисципліни САПР ТП / Уклад.Л.Г.Козлов, В.В.Савуляк, С.І.Сухоруков, Д.О.Лозінський, О.В.Петров.– Вінниця: ВНТУ, 2010.- 51с.",
    },
]

export default booksMethod;