import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 240,
    margin: "20px auto",
    display: "flex",
    flexDirection: "column",
    justifyContent:  "space-between",

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  

  media: {
    height: 350,
    width: 208,
    paddingTop: 5,
    textAlign: "center",
    borderRadius: 20,
    margin: "0 auto",
  },
  buttonCard: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function TeachersCard(props) {
  console.log(props);
  console.log("111", `${window.location.origin}/certification#${props.id}`);
  console.log("window.location", window.location);

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <CardMedia
          image={props.image}
          component="img"
          alt="Cont"
          className={classes.media}
          title=""
        />
        <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
          {props.name}
        </Typography>
        <Typography
          component="h4"
          color="primary"
          fontStyle="italic"
          gutterBottom
        >
          {props.status}
        </Typography>

        <Typography variant="body2" component="p">
          {props.description}
          <br />
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {props.info}
        </Typography>
        {/* <Typography className={classes.pos} color="textSecondary">
          {props.info2}
        </Typography> */}
        <Typography className={classes.pos} color="textSecondary">
          e-mail:{props.eMail}
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonCard}>
        <Button
          rel="noreferrer"
          href={`${window.location.origin}/certification#${props.id}`}
          size="small"
          // style={{color: " #026E80"}}
        >
          Стажування
        </Button>
        <Button target="_blank" rel="noreferrer" href={props.page} size="small" style={{whiteSpace: "nowrap"}} color="secondary">
          про викладача
        </Button>
      </CardActions>
    </Card>
  );
}
