import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import historyPageImage from "../img/historyPage.png";
import ListItem from "@material-ui/core/ListItem";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  header: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  media: {
    height: 300,
    width: 190,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
    
  },
  text:{
    marginBottom: "5px",
  }


}));
function History() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.header}
        component="img"
        alt=""
        image={historyPageImage}
        title=""
      />
      <div className="text">
        <Typography paragraph className={classes.text}>
          Кафедра технологій та автоматизації машинобудування розпочала свою
          історію з вересня 1968 року. Завідувачем кафедри був призначений І.А.
          Немировський, разом з ним до складу кафедри увійшли його учні –
          випускники Томського політехнічного інституту: Ю.М. Дівєєв, В.М. Лобан
          та В.В. Сиркін. У 1969 році до них приєдналися кандидати технічних
          наук А. А. Козлов та Ю. Я. Комісаренко, які за запрошенням І.А.
          Немировського переїхали до Вінниці. Саме ці шість високоосвічених
          інженерів-механіків утворили кістяк кафедри і протягом тривалого часу
          визначали рівень та зміст наукової і навчальної роботи.
        </Typography>

        <Typography paragraph className={classes.text}>
          Для роботи на кафедрі запрошувалися її кращі випускники, які виявили
          здатність до наукової роботи ще у студентські роки: Ж.П. Дусанюк
          (1970р.), В.А. Дусанюк (1970 р.), М.І. Іванов (1970 р.), Ю.А.
          Бурєнніков (1971 р.), Ю.І. Муляр (1971 р.), В.І. Савуляк (1971), О.В.
          Дерібо (1973 р.), В.К. Дьяков (1974 р.), О.М. Переяславський (1974
          р.), В.П. Пурдик (1975 р.), В.О. Чорний (1975 р.), В.К. Зозуля (1976
          р.), В.Т. Дядькович (1976), Л.К.Поліщук (1976), А.А. Гуменчук (1976),
          Л. Г. Козлов (1976 р.), О.М. Мироненко ( 1976 р.), П.М. Москалюк (
          1976 р.), Н.С. О.І. Третяк (1980), Семічаснова (1980) та інші. У
          1980-1990 роках кількісний склад співробітників кафедри вже сягав 60
          чоловік.
        </Typography>

        <Typography paragraph className={classes.text}>
          Починаючи з 70-х років на кафедрі стрімко розвинулась і стала добре
          відомою в галузі гідропривода та гідропневмоавтоматики наукова школа,
          очолювана професором І. А. Немировським. Під його керівництвом
          підготовлено 18 кандидатів технічних наук.
        </Typography>

        <Typography paragraph className={classes.text}>
          У червні 1978 року з ініціативи старшого викладача Л. П. Середи на
          базі кафедри ТАМ створена галузева науково-дослідна лабораторія
          «Гідроагрегат», науковим керівником якої був призначений І. А.
          Немировський, а завідувачем – В. Б. Петров. В той час ця лабораторія
          підпорядковувалась Міністерству сільськогосподарського машинобудування
          СРСР та Міністерству вищої і середньої спеціальної освіти УРСР. Саме з
          цього часу на кафедрі ТАМ почалися інтенсивно виконуватись роботи у
          напрямі дослідження, розробки та удосконалення гідроприводів і
          гідроагрегатів сільськогосподарських машин.
        </Typography>

        <Typography paragraph className={classes.text}>
          Розробки виконані науковцями кафедри ТАМ зі значним економічним
          ефектом впроваджені на багатьох підприємствах: заводах
          «Таджикгідроагрегат» (м. Душанбе), НВО «Арсенал» (м. Київ),
          Калинівському машинобудівному заводі та інших.
        </Typography>

        <Typography paragraph className={classes.text}>
          У 2001 році на базі кафедри ТАМ з ініціативи заслуженого працівника
          освіти України проф. Бурєннікова Ю.А. та д.т.н. Козлова Л.Г. був
          створений навчально-науковий центр «ВНТУ-Festo», який укомплектований
          сучасним обладнанням для навчання в галузі механотроніки.
        </Typography>

        <Typography paragraph className={classes.text}>
          На кафедрі ефективно використовується парк верстатів з ЧПК,
          модернізованих фірмою «Siemens», а також унікальна лабораторія
          гідроприводу та гідроавтоматики. Основними напрямами підготовки
          фахівців на сучасному етапі є широке впровадження комп’ютерних
          технологій, систем механотроніки, сучасних технологій в галузі
          метало- і матеріалообробки на базі обладнання з ЧПК.
        </Typography>

        <Typography paragraph className={classes.text}>
          Кафедра підтримує взаємовигідні контакти із провідними науковими
          установами та промисловими підприємствами: КНВО «Форт», Інститутом
          надтвердих матеріалів, Інститутом проблем міцності, Національним
          технічним університетом України «Київський політехнічний інститут ім.
          Ігоря Сікорського», Національним авіаційним університетом, Технічним
          університетом м. Яси (Румунія), Західночешським університетом м.
          Пльзень (Чехія), Технологічним університетом м. Гданськ (Польща).
        </Typography>

        <Typography paragraph className={classes.text}>
          Наукові школи кафедри підготували 3 докторів наук, 2 професорів та
          більше 20 кандидатів наук. В останні роки успішно захистили
          кандидатські дисертації випускники кафедри Лозінський Д.О., Петров
          О.В., Савуляк В.В., Сухоруков С.І., Репінський С.В., Сердюк О.В.,
          Піонткевич О.В., Пилявець В.Г., Перепелиця В.І., докторську
          дисертацію - Козлов Л.Г.. Науковими напрямами кафедри є «Моделювання та
          синтез гідросистем та гідроагрегатів технологічних та мобільних
          машин», керівництво яким здійснюють д.т.н., проф. Козлов Л.Г., к.т.н.,
          проф. Бурєнніков Ю.А., та «Розвиток прикладної теорії деформовності
          суцільних та пористих тіл при складному навантаженні та удосконалення
          на цій основі технологічних процесів обробки тиском», заснований
          д.т.н. проф.. Сиваком І.О., керівництво яким на даний час здійснюють
          к.т.н., доц. Сухоруков С.І. та к.т.н., доц. Савуляк В.В. Результати
          наукової роботи опубліковані в десяти монографіях, сотнях статей,
          авторських свідоцтвах та патентів. Авторським колективом кафедри
          видано каталог гідроагрегатів для сільськогосподарських машин в 5
          томах. Розробки науковців кафедри неодноразово нагороджувались
          золотими та срібними медалями на вітчизняних і міжнародних виставках.
        </Typography>

        <Typography paragraph className={classes.text}>
          Проведення занять та навчально-методичної роботи на високому рівні
          забезпечують Товкач А.О., Ковальчук В.А., Лозінська М.М.
        </Typography>

        <Typography paragraph className={classes.text}>Кафедру очолювали:</Typography>
        <ListItem>
          у 1968 – 1983 роках – к. т. н., доц. Немировський І. А.;
        </ListItem>

        <ListItem>у 1983 – 1988 роках – к. т. н, доц. Дівєєв Ю. М.;</ListItem>

        <ListItem>
          у 1988 – 1993 та у 1994 – 2001 роках – к. т. н, доц. Іванов М. І.;
        </ListItem>

        <ListItem>
          у 1993– 1994 роках д. т. н. та д. х. н., проф. Жуков А. О.;
        </ListItem>

        <ListItem>у 2001 – 2016 роках – д. т. н., проф. Сивак І. О;</ListItem>

        <ListItem>
          З 2016 року по теперішній час – д. т. н. Козлов Л.Г.
        </ListItem>

        <Typography paragraph className={classes.text}>
          За 55 років успішної роботи кафедра підготувала понад 3000 інженерів,
          бакалаврів та магістрів, які успішно працюють в багатьох галузях
          промисловості України та поза її межами.{" "}
        </Typography>      
        <CardMedia
          className={classes.media}
          component="img"
          alt=""
          image="http://tam.vntu.edu.ua/images/nemirovskiy.jpg"
          title=""
        />
      </div>
    </main>
  );
}

export default History;
