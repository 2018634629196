const internships = [
  {
    teacherId: "1",
    description:
      'Wyższa Szkoła Zarządzania Ochroną Pracy w Katowicach, дистанційна, стажування за кордоном, "/ ZAPEWNIENIE JAKOŚCI KSZTAŁCENIA W SZKOLE WYŻSZEJ "/, з 01.06.2021 р. по 30.09.2021 р., Сертифікат про проходження стажування, 2021-09-30, 180 год, 6 кред.',
    certificateId: "11/2020/2021",
    credits: 6,
    year: 2021,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/kozlov_012021.pdf",
  },
  {
    teacherId: "1",
    description:
      'Вінницький національний технічний університет, дистанційна, стажування, LII науково-технічної конференції підрозділів ВНТУ, з 21.06.2023 р. по 23.06.2023 р., Тези доповідей: "ОГЛЯД КОНСТРУКЦІЙ ТА ХАРАКТЕРИСТИК СУЧАСНИХ РЕГУЛЯТОРІВ ДЛЯ НАСОСІВ ЗМІННОГО РОБОЧОГО ОБ`ЄМУ", "МЕХАТРОННА ГІДРОСИСТЕМА З АДАПТИВНИМ РЕГУЛЯТОРОМ" та "ПІДВИЩЕННЯ ЕФЕКТИВНОСТІ ВИКЛАДАННЯ ДИСЦИПЛІН «ВСТУП ДО ФАХУ» І ДИСЦИПЛІН З ІНФОРМАЦ, номера відсутній, 2023-06-23, 15 год, 0,5 кред.',
    certificateId: "",
    credits: 0.5,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/kozlov_022023.pdf",
  },
  {
    teacherId: "1",
    description:
      "Sikorsky Challenge Vinnytsia, TRANSFOTECH, Startup Mastery Igor Peer, дистанційна, стажування за кордоном, For successfully completing entrepreneurship development course under the startup school program, Term: 19.04.2023 - 09.11.2023, Title of final project: EcoWorker - an automated scissor lift based on an electric carrier, Certificate Number: 51, 2023-11-09, 120 год, 4 кред.",
    certificateId: "51",
    credits: 4,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/kozlov_032023.pdf",
  },

  {
    teacherId: "12",
    description:
      'Lutsk National Technical University, Навчально-науковий центр "Volyn Business Hub", очна, навчання за освітньою програмою професійного розвитку, Certificate Grade B in the First Certificate in English (FCE) - B2 First Council of Europe Level B2, з 09.06.2023 р. по 20.09.2023 р.,Сертифікат: № ПК 05477296/000607-23, 2023-09-20, 60 год, 2 кред.',
    certificateId: "ПК 05477296/000607-23",
    credits: 2,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_012023.pdf",
  },

  {
    teacherId: "12",
    description:
      'Zustricz Foundation, Department of Polish-Ukrainian Studies of Jagiellonian University in Krakow, Career Development Center of NGO Sobornist, Luhansk Regional Institute of Postgraduate Pedagogical Education, дистанційна, стажування за кордоном, "FUNDRAISING AND ORGANIZATION OF PROJECT ACTIVITIES IN EDUCATIONAL ESTABLISHMENTS: EUROPEAN EXPERIENCE", з 11.09.2021 р. по 17.10.2021 р., "Formation of the Professional Competencies for the Technical Specialists based on Modern CAD/CAM Systems", Сертифікат про проходження стажування. Серійний номер: SZFL-000832, 2021-10-17, 180 год, 6 кред.',
    certificateId: "SZFL-000832",
    credits: 6,
    year: 2021,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_022021.pdf",
  },

  {
    teacherId: "12",
    description:
      "Міністерство цифрової трансформації України. Дія (Освіта). НАДС, дистанційна, участь у тренінгу, За успішне проходження освітнього серіалу: Особиста безпека підлітка, 25.11.2023, Сертифікат: Т0058011452, 2023-11-25, 3 год, 0,1 кред.",
    certificateId: "Т0058011452",
    credits: 0.1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_032023.pdf",
  },

  {
    teacherId: "12",
    description:
      "Міністерство цифрової трансформації України. Дія (Освіта). НАДС, дистанційна, участь у тренінгу, За успішне проходження освітнього серіалу: Навчання під час воєнного стану, 26.11.2023, , Сертифікат: Т0058069251, 2023-11-26, 3 год, 0,1 кред.",
    certificateId: "Т0058069251",
    credits: 0.1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_042023.pdf",
  },

  {
    teacherId: "12",
    description:
      'Вінницький національний технічний університет, дистанційна, стажування, LII науково-технічної конференції підрозділів ВНТУ, з 21.06.2023 р. по 23.06.2023 р., Тези доповідей: "CAD/CAE АНАЛІЗ ЕЛЕМЕНТІВ ФРЕЗЕРНО-ГРАВІРУВАЛЬНОГО ВЕРСТАТА З ЧПК" та "ПІДБІР ШПИНДЕЛЯ ДЛЯ ФРЕЗЕРНО-ГРАВІРУВАЛЬНОГО ВЕРСТАТА З ЧПК", Сертифікат: номера відсутній, 2023-06-23, 15 год, 0,5 кред.',
    certificateId: "",
    credits: 0.5,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_052023.pdf",
  },

  {
    teacherId: "12",
    description:
      'Вінницький національний технічний університет, очна, стажування, ІІІ Міжнародна науково-технічна конференція "Перспективи розвитку машинобудування та транспорту", з 01.06.2023 р. по 03.06.2023 р., Тези доповідей: "Інженерний аналіз конструкцій полиць холодильного обладнання для зменшення їх собівартості" та "Тhe influence of the reserve power of the hydraulic drive on its static and dynamic characteristics", Сертифікат: номера відсутній, 2023-06-03, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_062023.pdf",
  },

  {
    teacherId: "12",
    description:
      'Міністерство освіта і науки України, Національний університет "Запорізька політехніка", дистанційна, стажування, Всеукраїнський форум "Дні освітнього лідерства", з 01.06.2023 р. по 03.06.2023 р., , Сертифікат: АР 818/0113-23, 2023-06-03, 30 год, 1 кред.',
    certificateId: "АР 818/0113-23",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_072023.pdf",
  },

  {
    teacherId: "12",
    description:
      "Міністерство цифрової трансформації України. Дія (Освіта). НАДС, дистанційна, участь у тренінгу, За успішне проходження освітнього серіалу ChatGPT для підвищення ефективності, 10.09.2023, , Сертифікат: Т0051489962, 2023-09-10, 3 год, 0,1 кред.",
    certificateId: "Т0051489962",
    credits: 0.1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_082023.pdf",
  },

  {
    teacherId: "12",
    description:
      "Міністерство цифрової трансформації України. Дія (Освіта). Фонд Східна Європа. НАДС, дистанційна, участь у тренінгу, За успішне проходження освітнього серіалу: Програмування для новачків, 27.11.2023, , Сертифікат: Т0058265227, 2023-11-27, 6 год, 0,2 кред.",
    certificateId: "Т0058265227",
    credits: 0.2,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_092023.pdf",
  },

  {
    teacherId: "12",
    description:
      'Міністерство цифрової трансформації України, ГО "Прогресивні", дистанційна, стажування, Курс підвищення кваліфікації "Штучний інтелект та майбутнє освіти", з 07.11.2023 р. по 23.11.2023 р., , Сертифікат: ШІ-1510, 2023-11-23, 30 год, 1 кред.',
    certificateId: "ШІ-1510",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_0102023.pdf",
  },

  {
    teacherId: "12",
    description:
      "Sikorsky Challenge Vinnytsia, TRANSFOTECH, Startup Mastery Igor Peer, дистанційна, стажування за кордоном, For successfully completing entrepreneurship development course under the startup school program, Term: 19.04.2023 - 09.11.2023, Title of final project: EcoWorker - an automated scissor lift based on an electric carrier, Certificate Number: 52, 2023-11-09, 120 год, 4 кред.",
    certificateId: "52",
    credits: 4,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_0112023.pdf",
  },

  {
    teacherId: "12",
    description:
      "Information Technology Video Developers Network, online-курс, стажування, Курс підвищення кваліфікації «С-sharp 8.0 Starter», з 23.01.2024 р. по 09.02.2024 р., , Сертифікат: ТР78120719, 2024-02-09, 30 год, 1 кред.",
    certificateId: "ТР78120719",
    credits: 1,
    year: 2024,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/piontkevych_0122024.pdf",
  },

  {
    teacherId: "8",
    description:
      'Вінницький національний технічний університет, дистанційна, стажування, LII науково-технічної конференції підрозділів ВНТУ, з 21.06.2023 р. по 23.06.2023 р., Тези доповідей: "Системи автоматизованого проектування (САПР) заготовок деталей машин" та "До питання визначення складових мінімального проміжного припуску для попередньої обробки "Головних отворів у литих заготовках деталей типу «фланець»", Сертифікат: номера відсутній, 2023-06-23, 15 год, 0,5 кред.',
    certificateId: "",
    credits: 0.5,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/deribo_012023.pdf",
  },

  {
    teacherId: "8",
    description:
      'Вінницький  національний технічний університет, очна, стажування, ІІІ Міжнародна науково-технічна конференція "Перспективи розвитку машинобудування та транспорту", з 01.06.2023 р. по 03.06.2023 р., Тези доповідей: "Розробка алгоритму та комп`ютерної програми для розрахунку собівартості заготовки, виготовленої литтям" та "З досвіду викладання дисципліни «розмірно-точнісне моделювання конструкцій і технологічних процесів» у вінницькому національн, Сертифікат: номера відсутній, 2023-06-03, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/deribo_022023.pdf",
  },

  {
    teacherId: "8",
    description:
      "Zustricz Foundation, Department of Polish-Ukrainian Studies of Jagiellonian University in Krakow, Career Development Center of NGO Sobornist, Luhansk Regional Institute of Postgraduate Pedagogical Education, дистанційна, стажування за кордоном, FUNDRAISING AND ORGANIZATION OF PROJECT ACTIVITIES IN EDUCATIONAL ESTABLISHMENTS: EUROPEAN EXPERIENCE, з 04.10.2023 р. по 10.12.2023 р., Management of the Implementation Process of the Dual Form of Higher Education, Сертифікат про проходження стажування. Серійний номер: SZFL-002784, 2023-12-10, 180 год, 6 кред.",
    certificateId: "SZFL-002784",
    credits: 6,
    year: 2023,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/deribo_032023.pdf",
  },

  //------------------

  {
    teacherId: "2",
    description:
      'Вінницький національний технічний університет, очна, навчання за освітньою програмою професійного розвитку, Розвиток професійно-педагогічної кваліфікації викладача. Модуль "Риторика: формула успіху", з 01.11 2020 р. по 29.12.2020 р., , Свідоцтво про підвищення кваліфікації ПК № 020706930210 - 21, 2021-06-15, 30 год, 1 кред.',
    certificateId: "ПК № 020706930210 - 21",
    credits: 1,
    year: 2020,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/burennikov_012020.pdf",
  },

  {
    teacherId: "2",
    description:
      "Zustricz Foundation, Department of Polish-Ukrainian Studies of Jagiellonian University in Krakow, Career Development Center of NGO Sobornist, Luhansk Regional Institute of Postgraduate Pedagogical Education, дистанційна, стажування за кордоном, FUNDRAISING AND ORGANIZATION OF PROJECT ACTIVITIES IN EDUCATIONAL ESTABLISHMENTS: EUROPEAN EXPERIENCE, з 04.10.2023 р. по 10.12.2023 р., Management of the Implementation Process of the Dual Form of Higher Education, Сертифікат про проходження стажування. Серійний номер: SZFL-002762, 2023-12-10, 180 год, 6 кред.",
    certificateId: "SZFL-002762",
    credits: 6,
    year: 2023,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/burennikov_022023.pdf",
  },

  {
    teacherId: "2",
    description:
      'Вінницький національний технічний університет, очна, стажування, ІІІ Міжнародна науково-технічна конференція "Перспективи розвитку машинобудування та транспорту", з 01.06.2023 р. по 03.06.2023 р., Тези доповіді: "Adaptive hydraulic circuit for mobile machines", Сертифікат: номера відсутній, 2023-06-03, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/burennikov_032023.pdf",
  },

  //------------------

  {
    teacherId: "3",
    description:
      "Університет суспільних наук (UNS) у м. Лодзь (Польща), фундація Central European Academy Studies and Certifications (CEASC), дистанційна, стажування за кордоном, «Міжнародна кар`єра науковця та управління науковими проектами», з 04.12.2020 р. до 10.02.2021 р., , Сертифікат про підвищення кваліфікації №2021/02/0035.21, 2021-02-10, 180 год, 6 кред.",
    certificateId: "2021/02/0035.21",
    credits: 6,
    year: 2021,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/petrov_012021.pdf",
  },

  {
    teacherId: "3",
    description:
      "Науково-методичний центр вищої та фахової передвищої освіти, Міністерство освіти і науки України, дистанційна, навчання за освітньою програмою професійного розвитку, Особливості управління закладами вищої освіти та освітнім процесом в умовах воєнного стану, з 05 грудня 2022 року по 13 грудня 2022 року, , Свідоцтво про підвищення кваліфікавції. Серія СС 38282994/4937-22, 2023-01-11, 45 год, 1,5 кред.",
    certificateId: "Серія СС 38282994/4937-22",
    credits: 1.5,
    year: 2022,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/petrov_022022.pdf",
  },

  {
    teacherId: "3",
    description:
      'Національне агентство із забезпечення якості вищої освіти, дистанційна, участь у тренінгу, Тренінг для тренерів Національного агенства "Підготовка експертів з акредитації освтніх програм: інструменти та підходи", 2024-11-02, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/petrov_032021.pdf",
  },
  //sukhorukov

  {
    teacherId: "4",
    description:
      'Poznan university of technology "Politechnika Poznanska", дистанційна, участь у семінарі, 5th International Conference on Disign, Simulation, Manufacturing: The Innovation Exchange, з 07.06.22 р. по 10.06.22 р.,Сертифікат про участь в підготовці та роботі конференції, 2022-06-10, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2022,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/sukhorukov_012022.pdf",
  },

  {
    teacherId: "4",
    description:
      "ДУ «Інститут патології хребта та суглобів ім. проф. М. І. Ситенка НАМН України», очна, участь у практикумі, BioArt application for sustainable development, з 06.09.2021 по 10.09.2021, , Сертифікат про підвищення кваліфікації № 2-21/2021, 2021-09-13, 30 год, 1 кред.",
    certificateId: "№ 2-21/2021",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/sukhorukov_022021.pdf",
  },

  {
    teacherId: "4",
    description:
      "Національне агентство із забезпечення якості вищої освіти, дистанційна, участь у тренінгу, Тренінг для керівників експертних груп, з 29.06.2021 по 02.07.2021, Сертифікат про підвищення кваліфікації № 0481/2021(185), 2021-07-02, 30 год, 1 кред.",
    certificateId: "№ 0481/2021(185)",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/sukhorukov_032021.pdf",
  },

  {
    teacherId: "4",
    description:
      'Вінницький національний технічний університет, очна, участь у семінарі, 3 Міжнародна науково-технічна конференція "Перспективи розвитку машинобудування та транспорту", з 01.06.2023 по 03.06.2023, Сертифікат про участь в підготовці та роботі конференції, 2023-06-05, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/sukhorukov_042023.pdf",
  },

  {
    teacherId: "4",
    description:
      "Міністерство цифрової трансформації, online-курс, участь у вебінарі, Штучний інтелект майбутнє освіти, з 07.11.2023 р. по 23 листопада 2023 р., Свідоцтво про підвищення кваліфікації. № ШІ-1894, 2023-12-08, 30 год, 1 кред.",
    certificateId: "ШІ-1894",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/sukhorukov_052023.pdf",
  },

  {
    teacherId: "4",
    description:
      "ДНУ “Київський Академічний Університет”, online-курс, участь у вебінарі, Tools of Machine Learning, з 30.10.2023 р. по 01.12.2023 р., Сертифікат про проходження онлайн курсу, 2023-12-08, 30 год, 1 кред.",
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/sukhorukov_062023.pdf",
  },

  // {
  //     "teacherId": "5",
  //     "description": 'ВНТУ, очна, участь у семінарі, "Розвиток професійно-педагогічної кваліфікації викладачів" Модуль ІІІ - Інструменти формування 4К-компетенцій у студентів, з 02.02.2022р. по 25.05.2022р., за наявності, Свідоцтво про підвищення кваліфікації Серія ПК № 020706930295-22, 2022-05-26, 30 год, 1 кред.',
  //     "certificateId": 'Серія ПК № 020706930295-22',
  //     "credits": 1,
  //     "year": 2022,
  //     "international": false,
  //     "url": "http://tam.vntu.edu.ua/data/internship/semichasnova_012022.pdf",
  // },
  //------------------

  {
    teacherId: "6",
    description:
      "Department of Polish-Ukrainian studies of Jagiellonian university in Krakow, дистанційна, стажування, Міжнародне стажування за програмою підвищення кваліфікації «ФАНДРЕЙЗИНГ ТА ОРГАНІЗАЦІЯ ПРОЄКТНОЇ ДІЯЛЬНОСТІ В ЗАКЛАДАХ ОСВІТИ: ЄВРОПЕЙСЬКИЙ ДОСВІД» для педагогічних та науково-педагогічних працівників, 12.02.2022 - 20.03.2022, Дуальна освіта, Сертифікат SZFL-001848, 2022-03-20, 180 год, 6 кред.",
    certificateId: "SZFL-001848",
    credits: 6,
    year: 2022,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/savulyakv_012022.pdf",
  },
  {
    teacherId: "6",
    description:
      "Вінницький національний технічний університет, очна, участь у семінарі, ВИГОТОВЛЕННЯ ВІСЕСИМЕТРИЧНИХ ДЕТАЛЕЙ ХОЛОДНИМ ЛИСТОВИМ ШТАМПУВАННЯМ, 01.06.23 - 03.06.23, , Сертифікат про участь, 2023-06-03, 30 год, 1 кред.",
    certificateId: "SZFL-001848",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/savulyakv_022023.pdf",
  },

  //repinskiy
  {
    teacherId: "7",
    description:
      "Вінницький національний технічний університет, очна, стажування, «Розвиток професійно-педагогічної кваліфікації викладачів» Модуль ІІІ. Інструменти формування 4К-компетенцій у студентів, з 10.02.2021 р. по 30.04.2021 р., Розвиток 4К-компетенцій у студентів під час вивчення дисципліни «Гідравліка, гідро- та пневмоприводи», Свідоцтво про підвищення кваліфікації. Серія ПК № 020706930227 – 21, 2021-06-01, 30 год, 1 кред.",
    certificateId: "Серія ПК № 020706930227 – 21",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/repinskiy_012021.pdf",
  },

  {
    teacherId: "7",
    description:
      "Вінницький національний технічний університет, очна, стажування, ІІІ Міжнародна науково-технічна конференція «Перспективи розвитку машинобудування та транспорту», з 01.06.2023 р. по 03.06.2023 р., теза доповіді: Розробка алгоритму та комп`ютерної програми для розрахунку собівартості заготовки, виготовленої литтям, сертифікат, 2023-06-03, 30 год, 1 кред.",
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/repinskiy_022023.pdf",
  },

  {
    teacherId: "7",
    description:
      "Вінницький національний технічний університет, очна, стажування, LII науково-технічна конференція підрозділів ВНТУ, з 21.06.2023 р. по 23.06.2023 р., Теза доповіді: Системи автоматизованого проектування (САПР) заготовок деталей машин., сертифікат, 2023-06-23, 15 год, 0,5 кред.",
    certificateId: "",
    credits: 0.5,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/repinskiy_032023.pdf",
  },

  {
    teacherId: "7",
    description:
      'Вінницький національний технічний університет, очна, стажування, ІІ Міжнародна науково-технічна конференція "Перспективи розвитку машинобудування та транспорту", з 13.05.2021 р. по 15.05.2021 р., Теза доповіді: Імітаційне дослідження впливу параметрів електрогідравлічного регулятора на динамічні характеристики аксіально-поршневого регульованого насоса., Сертифікат, 2021-05-15, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/repinskiy_042021.pdf",
  },

  {
    teacherId: "7",
    description:
      "Компанія «Наукові публікації», дистанційна, участь у вебінарі, «Міжнародний досвід у публікаційній сфері. Успішні публікації у Scopus та Web of Science», з 07.02.2022 р. по 11.02.2022 р., , сертифікат № АА 3549, 2022-02-11, 30 год, 1 кред.",
    certificateId: "",
    credits: 1,
    year: 2022,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/repinskiy_052022.pdf",
  },

  {
    teacherId: "7",
    description:
      "Національний університет «Одеська юридична академія», дистанційна, навчання за освітньою програмою професійного розвитку, «Освітній процес в умовах воєнного стану в Україні», з 03.05.2022 р. по 13.06.2022 р., , Свідоцтво про підвищення кваліфікації. № ADV-0305137-OLA, 2022-06-13, 180 год, 6 кред.",
    certificateId: "№ ADV-0305137-OLA",
    credits: 6,
    year: 2022,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/repinskiy_062022.pdf",
  },

  //lozinskyi

  {
    teacherId: "9",
    description:
      "Вінницький національний технічний університет, очна, участь у семінарі, Перспективи розвитку машинобудування та транспорту. ІІ Міжнародна науково-технічна конференція, з 13.05.2021р. по 15.05.2021р., Сертифікат, 2021-05-15, 30 год, 1 кред.",
    certificateId: "",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_012021.pdf",
  },

  {
    teacherId: "9",
    description:
      "EPAM Ukraine, online-курс, участь у тренінгу, .NET Summer Program 2022, з 20.06.2022р. по 24.10.2022р., Сертифікат, 2022-11-07, 180 год, 6 кред.",
    certificateId: "",
    credits: 6,
    year: 2022,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_022022.pdf",
  },

  {
    teacherId: "9",
    description:
      "Національне агентство із забезпечення якості вищої освіти, платформа масових відкритих онлайн-курсів Prometheus, online-курс, участь у тренінгу, ЕКСПЕРТ З АКРЕДИТАЦІЇ ОСВІТНІХ ПРОГРАМ: ОНЛАЙН ТРЕНІНГ, з 02.11.2021р. по 04.11.2021р., СЕРТИФІКАТ, 2021-11-04, 30 год, 1 кред.",
    certificateId: "",
    credits: 1,
    year: 2021,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_032021.pdf",
  },

  {
    teacherId: "9",
    description:
      "Вінницький національний технічний університет, очна, навчання за освітньою програмою професійного розвитку, «Розвиток професійно-педагогічної компетентності викладачів». Модуль І: «Педагогічна комунікація і риторика в сучасному освітньому середовищі», з 19.04.2023р. по 13.06.2023р., , Свідоцтво про підвищення кваліфікації. Серія ПК №020706930339-23, 2023-10-06, 60 год, 2 кред.",
    certificateId: "Серія ПК №020706930339-23",
    credits: 2,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_042023.pdf",
  },

  {
    teacherId: "9",
    description:
      "Вінницький національний технічний університет, очна, навчання за освітньою програмою професійного розвитку, Розвиток професійно-педагогічної компетентності викладачів. МОДУЛЬ ІІІ. Інструменти формування 4К-компетенцій у студентів, з 19.04.2023р. по 13.06.2023р.,Свідоцтво про підвищення кваліфікації. Серія ПК №020706930331-23, 2023-10-06, 60 год, 2 кред.",
    certificateId: " Серія ПК №020706930331-23",
    credits: 2,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_052023.pdf",
  },

  {
    teacherId: "9",
    description:
      "Інституту модернізації змісту освіти Асоціація інноваційної та цифрової освіти і компанія «UBOS.tech». За сприяння МОН України, online-курс, участь у вебінарі, Штучний інтелект та майбутнє освіти, з 07.11.2023р. по 23.11.2023р., Свідоцво про підвищення кваліфікаці. №ШІ-1071, 2023-11-23, 30 год, 1 кред.",
    certificateId: "ШІ-1071",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_062023.pdf",
  },

  {
    teacherId: "9",
    description:
      'Вінницький національний технічний університет, очна, участь у семінарі, ІІІ Міжнародна науково-технічна конференція "Перспективи розвитку машинобудування та транспорту", з 01.06.2023р. по 03.06.2023р., Сертифікат, 2023-06-05, 30 год, 1 кред.',
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/lozinskyi_072023.pdf",
  },

  //serdyuk

  {
    teacherId: "10",
    description:
      "Department of Polish-Ukrainian Studies of Jagiellonian University in Krakow, дистанційна, стажування за кордоном, FUNDRAISING AND ORGANIZATION OF PROJECT ACTIVITIES IN EDUCATIONAL ESTABLISHMENTS: EUROPEAN EXPERIENCE, з 11.09.2021 р. по 17.10.2021 р., Formation of the Professional Competencies for the Technical Specialists based on Modern CAD/CAM Systems, сертифікат про проходження стажування. Серійний номер SZFL-000872, 2021-11-01, 180 год, 6 кред.",
    certificateId: "SZFL-000872",
    credits: 6,
    year: 2021,
    international: true,
    url: "http://tam.vntu.edu.ua/data/internship/serdyuk_012021.pdf",
  },

  {
    teacherId: "10",
    description:
      'Lutsk National Technical University, Навчально-науковий центр "Volyn Business Hub", очна, навчання за освітньою програмою професійного розвитку, Certificate Grade B in the First Certificate in English (FCE) - B2 First Council of Europe Level B2, з 09.03.2022 р. по 20.06.2022 р, , Сертифікат: № ПК 05477296/000115-22, 2022-06, 2022-06-20, 60 год, 2 кред.',
    certificateId: "№ ПК 05477296/000115-22",
    credits: 2,
    year: 2022,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/serdyuk_022022.pdf",
  },

  {
    teacherId: "10",
    description:
      "Національне агентство із забезпечення якості вищої освіти, платформа масових відкритих онлайн-курсів Prometheus з 02.11.2021р. по 04.11.2021р., , СЕРТИФІКАТ, 2021-11-04,, online-курс, стажування, Експерт з акредитації освітніх програм: онлайн тренінг та як написати якісний звіт про результати акредитаційної експертизи освітньої програми, з 02.07.2023 р. по 02.08.2023 р, , Сертифікат, 2023-08-02, 30 год, 1 кред.",
    certificateId: "",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/serdyuk_032023.pdf",
  },

  {
    teacherId: "10",
    description:
      ' Міністерство цифрової трансформації України, ГО "Прогресивні" з 07.11.2023 р. по 23.11.2023 р., Сертифікат: ШІ-1510, 2023-11-23, 30 год, 1 кред., дистанційна, стажування, Курс підвищення кваліфікації "Штучний інтелект та майбутнє освіти, з 07.11.2023 р. по 23.11.2023 р, , Сертифікат: ШІ-1733, 2023-11-23, 30 год, 1 кред.',
    certificateId: "ШІ-1733",
    credits: 1,
    year: 2023,
    international: false,
    url: "http://tam.vntu.edu.ua/data/internship/serdyuk_042023.pdf",
  },
];

export default internships;
