import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Paper } from "@material-ui/core";
import components from "../dataLists/components";
import { Button } from "@material-ui/core";

import photo1051 from "../img/105_2.jpg";
import photo1052 from "../img/105_3 (1).jpg";

import photo4081 from "../img/408.png";
import photo4082 from "../img/408p2.png";

import photo2211 from "../img/221.png";
import photo2212 from "../img/2212.jpg";

import photo1041 from "../img/1041.png";
import photo1042 from "../img/1042.png";

import photo1061 from "../img/106 (1).jpg";
import photo1062 from "../img/106 (2).jpg";
import photo1063 from "../img/106 (3).jpg";
import photo1064 from "../img/106 (4).jpg";
import photo1065 from "../img/106 (5).jpg";
import photo1066 from "../img/106 (6).jpg";

import photo211 from "../img/211.jpg";

import photo409 from "../img/409.jpg";

import photo1043 from "../img/104 (1).jpg";
import photo1044 from "../img/104 (2).jpg";
import photo1045 from "../img/104 (3).jpg";
import photo1046 from "../img/104 (4).jpg";
import photo1047 from "../img/104 (5).jpg";
import photo1048 from "../img/104_(6).jpg";

import photo21161 from "../img/2116_ (1).jpg";
import photo21162 from "../img/2116_ (2).jpg";
import photo21163 from "../img/2116_ (3).jpg";

import photoSponsors1 from "../img/sponsors_ (1).jpg";
import photoSponsors2 from "../img/sponsors_ (2).jpg";

import practice_base_1 from "../img/practice_base_ (1).jpg";
import practice_base_2 from "../img/practice_base_ (2).jpg";

import phdPageImage from "../img/newsPage.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  text: {
    fontSize: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  listItem: {
    fontSize: 18,
  },
  listItemBold: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: "-10px",
    marginBottom: "40px",
  },
  header: {
    backgroundColor: "#B5D3DE",
  },
  mainHeader: {
    backgroundColor: "#7fa0ba",
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperPadding: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
  photocontainer: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Apply when screen size is small or below (mobile)
    },
  },
}));

function LaboratoryBase() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.media}
        component="img"
        alt=""
        image={phdPageImage}
        title=""
      />

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.header} variant="h5">
          Науково-дослідні лабораторії
        </Typography>

        <Typography paragraph className={classes.text}>
          При кафедрі працюють три науково-дослідні лабораторії:
        </Typography>
        <ol className={classes.text}>
          <li>
            галузева науково-дослідна лабораторія „Гідроагрегат” (ауд.1114а)
          </li>
          <li>лабораторія пластичних деформацій (ауд.1114а)</li>
          <li>науково-дослідна лабораторія „Борекс-гідравліка” (ауд.1116)</li>
        </ol>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.header} variant="h5">
          Навчальні лабораторії
        </Typography>

        <Typography paragraph className={classes.text}>
          За кафедрою ТАМ закріплено 13 приміщень загальною площею понад 800
          кв.м., які повністю забезпечують навчальний процес кафедри, зокрема,
          це навчальні лабораторії:
        </Typography>
        <ol className={classes.text}>
          <li>навчально-науковий центр механотроніки „ВНТУ-ФЕСТО”</li>
          <li>лабораторія моделювання та програмування систем керування</li>

          <li>
            лабораторія автоматизації процесів машинобудування (ауд. 1104)
          </li>
          <li>лабораторії технології машинобудування (ауд. 1105,1106)</li>
          <li>
            лабораторія взаємозамінності, стандартизації та управління якістю
            продукції (ауд. 1221)
          </li>
        </ol>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo4081} alt="" />
            <img className={classes.photo} src={photo4082} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Навчально-науковий центр механотроніки „ВНТУ-ФЕСТО”.&nbsp;
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo1044} alt="" />
            <img className={classes.photo} src={photo1041} alt="" />
            <img
              className={classes.photo}
              src={photo1046}
              alt=""
              style={{ display: "block" }}
            />
            <img className={classes.photo} src={photo1042} alt="" />
          </div>
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo1048} alt="" />
            <img
              className={classes.photo}
              src={photo1045}
              alt=""
              style={{ display: "block", height: "100%", maxHeight: "380px" }}
            />
            <img className={classes.photo} src={photo1043} alt="" />
            <img className={classes.photo} src={photo1047} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Лабораторія автоматизації процесів машинобудування (ауд.
            1104).&nbsp;
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo1051} alt="" />
            <img className={classes.photo} src={photo1052} alt="" />
            <img className={classes.photo} src={photo1064} alt="" />
          </div>
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo1062} alt="" />
            <img className={classes.photo} src={photo1063} alt="" />
            <img className={classes.photo} src={photo1061} alt="" />
          </div>
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo1065} alt="" />
            <img className={classes.photo} src={photo1066} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Лабораторії технології машинобудування (ауд. 1105,1106).&nbsp;
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo2211} alt="" />
            <img className={classes.photo} src={photo2212} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Лабораторія взаємозамінності, стандартизації та управління якістю
            продукції (ауд. 1221).&nbsp;
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo21161} alt="" />
            <img className={classes.photo} src={photo21162} alt="" />
            <img className={classes.photo} src={photo21163} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Міжкафедральний лабораторний центр BioArt (ауд. 1116).&nbsp;
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo211} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Обчислювальний центр (ауд. 1209, 1211).&nbsp;
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photo409} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Лабораторія моделювання та програмування систем керування (ауд.
          7409).&nbsp
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={photoSponsors1} alt="" />
            <img className={classes.photo} src={photoSponsors2} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Обладнання надане спонсорами кафедри &nbsp;
          </Typography>
        </Paper>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography id="cooperation" className={classes.header} variant="h5">
          Співпраця з підприємствами
        </Typography>

        <Typography paragraph className={classes.text}>
          Науково-дослідна лабораторія «Пластичність» успішно співпрацює із
          підприємствами КНВО «ФОРТ» (м. Вінниця), установами НАН України:
          Інститутом надтвердих матеріалів ім. В.М. Бакуля, Інститутом проблем
          міцності та багатьма технічними університетами з міст Києва,
          Краматорська та ін. Науково дослідна лабораторія «Борекс-гідравліка»
          починаючи з 1993 року ефективно співпрацює з підприємствами України у
          галузі створення енергоощадних гідроприводів та гідроагрегатів для
          мобільних машин. Результати наукової та навчально-методичної роботи
          кафедри відображені у 8 монографіях (І.А. Немировський, І.О.Сивак,
          Ю.А.Бурєнніков, О.В. Петров), сотнях наукових статей, авторських
          свідоцтв та патентів. Розробки науковців кафедри відзначені медалями
          на науково-технічних виставках в Україні (автори розробок –
          І.А.Немировський, Ю.А. Бурєнніков, Л.Г. Козлов, О.В.Дерібо, Ю.І.
          Муляр, В.О. Чорний, В.П. Пурдик). За досягнення у винахідницькій
          діяльності І.А. Немировський відзначений почесним званням –
          «Заслужений винахідник», а В.П. Пурдик – «Кращий винахідник України».
          На міжнародних виставках винаходів (2000р. – м. Будапешт, 2003р., та
          2017р. – м. Яси) отримано три золоті та одну срібну медалі (Ю.А.
          Бурєнніков, Л.Г. Козлов, С.В. Репінський, Н.С. Семічаснова). Для
          навчання студентів та перепідготовки інженерно-технічних працівників
          промислових підприємств Подільського регіону в галузі автоматизації
          виробничих процесів на базі кафедри ТАМ у 2001 році створений
          навчально-науковий центр «ВНТУ-ФЕСТО» (науковий керівник – проф. Ю.А
          Бурєнніков., директор – проф. Л.Г. Козлов, технічний директор-доц.
          Д.О. Лозінський). Спільно із всесвітньовідомою компанією FESTO центр
          обладнано сучасним німецьким та австрійським обладнанням.
        </Typography>
      </Paper>

      <Paper
        elevation={3}
        variant="outlined"
        sx={{ padding: "5px" }}
        className={classes.paperPadding}
      >
        <Typography className={classes.header} variant="h5">
          Філії кафедри
        </Typography>

        <Typography paragraph className={classes.text}>
          Кафедрою заключені угоди про створення філій на провідних
          підприємствах м. Вінниця:
        </Typography>

        <ol className={classes.text}>
          <li>ВАТ "Вінницький агрегатний завод".</li>
          <li>Учбово-виробниче підприємство "УТОС".</li>
          <li>Казенне науково-виробниче підприємство "ФОРТ".</li>
          <li>ТОВ "Вінницький авіаремонтний завод".</li>
        </ol>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
        >
          <div className={classes.photocontainer}>
            <img className={classes.photo} src={practice_base_1} alt="" />
            <img className={classes.photo} src={practice_base_2} alt="" />
          </div>
          <Typography
            paragraph
            className={classes.text}
            style={{ textAlign: "center" }}
          >
            Філії та бази практики &nbsp;
          </Typography>
        </Paper>
      </Paper>
    </main>
  );
}

export default LaboratoryBase;
