import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import newsPageImage from "../img/newsPage.png";
import practice_base_1 from "../img/practice_base_ (1).jpg";
import practice_base_2 from "../img/practice_base_ (2).jpg";
import practice_base_3 from "../img/practice_base_ (3).jpg";
import practice_base_4 from "../img/practice_base_ (4).jpg";
import practice_1 from "../img/practice_1.jpg";
import practice_2 from "../img/practice_2.jpg";
import baseAndFilial from "../dataLists/baseAndFilial";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  header: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  text: {
    fontSize: 20,
  },
  root: {
    minWidth: 275,
    marginBottom: 20,
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
  mainHeader: {
    backgroundColor: "#7fa0ba",
  },
  paperPadding: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  listItem: {
    fontSize: 18,
    textAlign: "left",
  },
  listItemBold: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: "-10px",
    marginBottom: "40px",
  },

  photocontainer: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Apply when screen size is small or below (mobile)
    },
  },
  middleTitle: {
    textAlign: "left",
    fontSize: 20,
  },
}));

function Practice() {
  const classes = useStyles();

  const baseAndFilialList = baseAndFilial
    .sort((b) => b.id)
    .map((item, i) => {
      return (
        <ol className={classes.listItem}>
          {i + 1}
          {". "}
          {item.name}
        </ol>
      );
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.header}
        component="img"
        alt=""
        image={newsPageImage}
        title=""
      />
      <div className="text">
        <Card className={classes.root}>
          <CardContent>
            <Typography
              className={classes.mainHeader}
              variant="h5"
              component="h2"
            >
              Практична підготовка студентів.
            </Typography>

            <Typography
              paragraph
              className={classes.text}
              style={{ marginTop: "20px" }}
            >
              Практика здобувачів вищої освіти є невід’ємною частиною
              освітньо-професійної програми підготовки студентів, важливою та
              обов’язковою ланкою освітнього процесу, сприяє здобувачам вищої
              освіти у набутті визначених освітньою програмою компетентностей та
              програмних результатів навчання, необхідних для професійної
              діяльності. Практична підготовка здобувачів вищої освіти
              проводиться на базах виробничої практики (підприємствах,
              організаціях, філіях кафедри, структурних підрозділах ВНТУ тощо),
              які мають сучасне навчально-технічне оснащення та надають
              студентам можливість практичної реалізації набутих знань,
              підвищення професійного рівня та подальшого професійного росту та
              кар’єри.
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <Paper
            elevation={3}
            variant="outlined"
            sx={{ padding: "5px" }}
            className={classes.paperPadding}
          >
            <Typography
              className={classes.mainHeader}
              style={{ marginLeft: "10px" }}
              variant="h5"
            >
              Види практики
            </Typography>

            <ol className={classes.listItem}>
              {" "}
              <a href="#practiceproduction"> Виробнича практика </a>
            </ol>
            <ol className={classes.listItem}>
              {" "}
              <a href="#practicebachelor">
                {" "}
                Переддипломна практика. Бакалаврат{" "}
              </a>
            </ol>
            <ol className={classes.listItem}>
              {" "}
              <a href="#practicemaster">
                {" "}
                Переддипломна практика. Магістратура{" "}
              </a>
            </ol>
            <ol className={classes.listItem}>
              <a href="#practicephd"> Педагогічна практика </a>{" "}
            </ol>
          </Paper>
        </Card>

        <Paper
          elevation={3}
          variant="outlined"
          sx={{ padding: "5px" }}
          className={classes.paperPadding}
          id="branches"
        >
          <Typography
            className={classes.mainHeader}
            style={{ marginLeft: "10px", textAlign: "center" }}
            variant="h5"
          >
            Філії та бази практики &nbsp;
          </Typography>

          <ol className={classes.listItem}>{baseAndFilialList}</ol>

          <div className={classes.photocontainer}>
            <img className={classes.photo} src={practice_base_1} alt="" />
            <img className={classes.photo} src={practice_base_2} alt="" />
          </div>
          <div className={classes.photocontainer}>
            <img
              className={classes.photo}
              src={practice_base_3}
              style={{ maxWidth: "1000px" }}
              alt=""
            />
          </div>
          <div className={classes.photocontainer}>
            <img
              className={classes.photo}
              src={practice_base_4}
              style={{ maxWidth: "1000px" }}
              alt=""
            />
          </div>
        </Paper>

        <Typography id="practicebachelor" h2 style={{ color: "transparent" }}>
          for link
        </Typography>

        <Card className={classes.root}>
          <Paper
            elevation={3}
            variant="outlined"
            sx={{ padding: "5px" }}
            className={classes.paperPadding}
          >
            <CardContent>
              <Typography
                className={classes.mainHeader}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                variant="h5"
              >
                Переддипломна практика. Бакалаврат
              </Typography>

              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: "5px" }}
                className={classes.paperPadding}
              >
                <Typography h4 className={classes.middleTitle}>
                  Документація
                </Typography>
                <ol className={classes.listItem}>
                  <a href="https://zakon.rada.gov.ua/laws/show/z0035-93#Text">
                    Положення про проведення практики студенті вищих навчальних
                    закладів України
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="https://vntu.edu.ua/projects/career-center/Polozhennia-pro-vyrobnychu-praktyku.pdf">
                    Положення про організацію та проведення виробничої практики
                    здобувачів вищої освіти Вінницького національного технічного
                    університету
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/OP.pdf">
                    Інструкція №5 з охорони праці здобувачів вищої освіти, які
                    від’їжджають на практику за межі університету
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practicebachelor.pdf">
                    Програма практики
                  </a>
                </ol>
              </Paper>
            </CardContent>
          </Paper>
        </Card>

        <Typography id="practicemaster" h2 style={{ color: "transparent" }}>
          for link
        </Typography>

        <Card className={classes.root}>
          <Paper
            elevation={3}
            variant="outlined"
            sx={{ padding: "5px" }}
            className={classes.paperPadding}
          >
            <CardContent>
              <Typography
                className={classes.mainHeader}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                variant="h5"
              >
                Переддипломна практика. Магістратура
              </Typography>

              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: "5px" }}
                className={classes.paperPadding}
              >
                <Typography h4 className={classes.middleTitle}>
                  Документація
                </Typography>
                <ol className={classes.listItem}>
                  <a href="https://zakon.rada.gov.ua/laws/show/z0035-93#Text">
                    Положення про проведення практики студенті вищих навчальних
                    закладів України
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="https://vntu.edu.ua/projects/career-center/Polozhennia-pro-vyrobnychu-praktyku.pdf">
                    Положення про організацію та проведення виробничої практики
                    здобувачів вищої освіти Вінницького національного технічного
                    університету
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/OP.pdf">
                    Інструкція №5 з охорони праці здобувачів вищої освіти, які
                    від’їжджають на практику за межі університету
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/master/practicemasters.pdf">
                    Програма практики
                  </a>
                </ol>
              </Paper>
            </CardContent>
          </Paper>
        </Card>

        <Typography id="practiceproduction" h2 style={{ color: "transparent" }}>
          for link
        </Typography>

        <Card className={classes.root}>
          <Paper
            elevation={3}
            variant="outlined"
            sx={{ padding: "5px" }}
            className={classes.paperPadding}
          >
            <CardContent>
              <Typography
                className={classes.mainHeader}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                variant="h5"
              >
                Виробнича практика
              </Typography>

              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: "5px" }}
                className={classes.paperPadding}
              >
                <Typography h4 className={classes.middleTitle}>
                  Документація
                </Typography>

                <ol className={classes.listItem}>
                  <a href="https://zakon.rada.gov.ua/laws/show/z0035-93#Text">
                    Положення про проведення практики студенті вищих навчальних
                    закладів України
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="https://vntu.edu.ua/projects/career-center/Polozhennia-pro-vyrobnychu-praktyku.pdf">
                    Положення про організацію та проведення виробничої практики
                    здобувачів вищої освіти Вінницького національного технічного
                    університету
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/OP.pdf">
                    Інструкція №5 з охорони праці здобувачів вищої освіти, які
                    від’їжджають на практику за межі університету
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/ugoda.pdf">
                    Угода на проведення практики здобувачів вищої освіти. Бланк.
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/ugoda_2.pdf">
                    Угода на проведення практики здобувачів вищої освіти.
                    Приклад заповнення.
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/povidomlenya.pdf">
                    Повідомлення про прибуття на практику
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/diary.pdf">
                    Щоденник студента щодо проходження практики.
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/practiceproduction/anketa.pdf">
                    Анкета-відгук. Приклад заповнення. (з 2024 р. студент
                    заповнює щоденник проходження практики)
                  </a>
                </ol>
              </Paper>
              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: "5px" }}
                className={classes.paperPadding}
              >
                <Typography h4 className={classes.middleTitle}>
                  Проходження практики
                </Typography>

                <ol className={classes.listItem}>
                  Генеральний директор компаній ТДВ «Брацлав» Михайленко П. М.
                  разом з провідними фахівцями підприємства і керівниками
                  практики від ВНТУ професором Бурєнніковим і доцентом Дерібо О.
                  В. обговорюють результати виробничої практики студентів 3-го
                  курсу спеціальності «Прикладна механіка», (липень 2023 р.).
                </ol>
                <div className={classes.photocontainer}>
                  <img
                    className={classes.photo}
                    src={practice_1}
                    style={{ maxWidth: "1000px" }}
                    alt=""
                  />
                </div>

                <ol className={classes.listItem}>
                  Студент 4-го курсу гр. 1ПМ-20б Малюта О., Домославський В.,
                  Левіщенко А. доповідають студентам 1-го, 2-го 1 3-го курсів
                  про проходження ними влітку 2023 р. виробничої практики. Тепер
                  вони працюють на інженерних посадах (жовтень 2023 р.).
                </ol>
                <div className={classes.photocontainer}>
                  <img
                    className={classes.photo}
                    src={practice_2}
                    style={{ maxWidth: "1000px" }}
                    alt=""
                  />
                </div>
              </Paper>
            </CardContent>
          </Paper>
        </Card>

        <Card className={classes.root} id="practicephd">
          <Paper
            elevation={3}
            variant="outlined"
            sx={{ padding: "5px" }}
            className={classes.paperPadding}
          >
            <CardContent>
              <Typography
                className={classes.mainHeader}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                variant="h5"
              >
                Педагогічна практика
              </Typography>

              <Paper
                elevation={3}
                variant="outlined"
                sx={{ padding: "5px" }}
                className={classes.paperPadding}
              >
                <Typography h4 className={classes.middleTitle}>
                  Документація
                </Typography>

                <ol className={classes.listItem}>
                  <a href="https://ida.vntu.edu.ua/wp-content/uploads/2022/06/Polozhennya_pro_Ped_praktyka-VNTU_02.06.2022.pdf">
                    Положення про проведення педагогічної практики здобувачів вищої освіти ступеня доктора філософії ВНТУ
                  </a>
                </ol>

                <ol className={classes.listItem}>
                  <a href="http://tam.vntu.edu.ua/images/ok_10.pdf">Силабус</a>
                </ol>
              </Paper>
            </CardContent>
          </Paper>
        </Card>
      </div>
    </main>
  );
}

export default Practice;
