const graduates = [
  {
    id: 1,
    name: "Бурєнніков Юрій Аннатолійович",
    degree: "к.т.н., професор",
    description:
      "Заслужений працівник освіти України, дійсний член  Транспортної академії України, Академії інженерних наук України",
    description1:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» (ТАМ) Вінницького національного технічного університету (ВНТУ) з 1971 р. по 1988 р.",
    description2:
      "Проректор з навчальної роботи, перший проректор (ВНТУ) з 1989 р. по 1997 р.",
    description3:
      "Декан факультету машинобудування та транспорту (ВНТУ) з 1997 р. по 2019 р.",
  },
  {
    id: 2,
    name: "Козлов Леонід Геннадійович",
    degree: "д.т.н., професор",
    description:
      "завідувач кафедри технологій та автоматизації машинобудування Вінницького національного технічного університету (ВНТУ), академік АІНУ, входить до складу Президії Академії інженерних наук України.",
    description1:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» (ТАМ) ВНТУ з 1978 р. по 2002 р.",
    description2:
      "Декана факультету технології, автоматизації та комп’ютеризації машинобудування ВНТУ з 2002 р. по 2011 р.",
    description3: "",
  },

  {
    id: 3,
    name: "Пурдик Віктор Петрович",
    degree: "к.т.н., доцент",
    description:
      "Науковий співробітник, асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» Вінницького національного технічного університету (ВНТУ) з 1975 р. по 2020 р.",
    description1:
      "Завідувач Галузевої науково-дослідної лабораторії «Гідроагрегат» при ВНТУ (до 2020 р.).",
    description2:
      "Автор 36 винаходів, захищених авторським свідоцтвами СРСР і патента-ми України. Більшість з цих винаходів упроваджені у виробництво.",
    description3: "",
  },

  {
    id: 4,
    name: "Іванов Микола Іванович",
    degree: "к.т.н., професор",
    description:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» (ТАМ) Вінницького національного технічного університету (ВНТУ) з 1970 р. по 1989 р.",
    description1: "Завідувач кафедри ТАМ ВНТУ з 1989 р. по 2001 р.",
    description2:
      "Завідувач кафедри «Машини та обладнання сільськогосподарського виробництва» Вінницького національного аграрного університету (ВНАУ) з 2001 р. по 2018 р.",
    description3:
      "Декан факультету механізації сільського господарства ВНАУ з 2005р. по 2009 р.",
  },

  {
    id: 5,
    name: "Поліщук Леонід Клавдійович",
    degree: "д.т.н., професор",
    description:
      "Завідувач кафедри «Галузеве машинобудування» Вінницького національ-ного технічного університету з 2018 р. по т. ч.",
    description1: "",
    description2: "",
    description3: "",
  },

  {
    id: 6,
    name: "Дусанюк Віктор Аркадійович",
    degree: "к.т.н., доцент",
    description:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» (ТАМ) Вінницького національного технічного університету (ВНТУ) з 1970 р. по 1993 р.",
    description1:
      "Доцент кафедри «Машини та обладнання сільськогосподарського виробництва» Вінницького національного аграрного університету (ВНАУ) з 1993 р. по 2018 р.",
    description2:
      "Декан факультету механізації сільського господарства ВНАУ з 1997 р. по 2005 р.",
    description3: "",
  },

  {
    id: 7,
    name: "Дерібо Олександр Володимирович",
    degree: "к.т.н., доцент",
    description:
      "Науковий співробітник, асистент, старший викладач, доцент, професор кафедри «Технології та автоматизація машинобудування» Вінницького націо-нального технічного університету (ВНТУ) з 1973 р. по т. ч.",
    description1:
      "Начальник науково-дослідної частини ВНТУ з 1999 р. по 2010 р.",
    description2: "",
    description3: "",
  },

  {
    id: 8,
    name: "Дусанюк Жанна Павлівна",
    degree: "к.т.н., доцент",
    description:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» Вінницького національного технічного університету (ВНТУ) з 1970 р. по 2020 р.",
    description1: "",
    description2: "",
    description3: "",
  },
  {
    id: 9,
    name: "Савуляк Валерій Іванович.",
    degree: "д.т.н., професор",
    description: "завідувач кафедри технології підвищення зносостійкості ВНТУ",
    description1: "",
    description2: "",
    description3: "",
  },
  {
    id: 10,
    name: "Петров Олександр Васильович.",
    degree: "к.т.н., доцент",
    description:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» Вінницького національного технічного університету (ВНТУ), декан факультету машинобудування та транспорту з 2020 р. по 2021 р., Проректор з науково-педагогічної роботи та організації освітнього процесу ВНТУ",
    description1: "",
    description2: "",
    description3: "",
  },
  {
    id: 11,
    name: "Сухоруков Сергій Іванович.",
    degree: "к.т.н., доцент",
    description:
      "Асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» Вінницького національного технічного університету (ВНТУ),декан факультету машинобудування та транспорту ВНТУ",
    description1: "",
    description2: "",
    description3: "",
  },

  {
    id: 12,
    name: "Лесько Олександр Йосипович",
    degree: "к.е.н., доцент",
    description:
      "Завідувач кафедри «Економіка підприємства та виробничий менеджмент» Вінницького національного технічного університету з 2005 р. по 2007 р. і з 2014 р. по т. ч.",
    description1: "",
    description2: "",
    description3: "",
  },

  {
    id: 13,
    name: "Середа Леонід Павлович",
    degree: "д.т.н., професор",

    description:
      "Ректор вінницького сільськогосподарського інституту з 1992 р. по 2000 р., ректор Вінницького державного аграрного університету  з 2000 р. по 2009 р.,  професор кафедри експлуатації машинно-тракторного парку і технічного сервісу, факультету механізації сільського господарства з 2017 р. по т. ч.",
    description1: "",
    description2: "",
    description3: "",
  },
  {
    id: 14,
    name: "Гунько Ірина Василівна",
    degree: "к.т.н., доцент",
    description:
      "Доцент кафедри загальнотехнічних дисциплін інженерно-технологічного факультету (Вінницький національний аграрний університет), проректор з науково-педагогічної та навчальної роботи ",
    description1: "",
    description2: "",
    description3: "",
  },

  {
    id: 15,
    name: "Переяславський Олексій Миколайович",
    degree: "к.т.н., доцент",
    description:
      "Науковий співробітник, асистент, старший викладач, доцент кафедри «Технології та автоматизація машинобудування» (ТАМ) Вінницького націо-нального технічного університету (ВНТУ) з 1974 р. по 2001 р.",
    description1:
      "Доцент кафедри «Машини та обладнання сільськогосподарського виробництва» Вінницького національного аграрного університету (ВНАУ) з 2001 р. по 2021 р.",
    description2: "",
    description3: "",
  },

  {
    id: 16,
    name: "Гуменчук Олександр Олександрович",
    degree: "к.т.н., доцент",
    description:
      "Старший викладач, доцент кафедри «Металорізальні верстати та обладнання автоматизованого виробництва» Вінницького національного технічного університету (ВНТУ) з 1990 р. по 1995 р.",
    description1:
      "Комерційний директор Спільного українсько-іспанського підприємства «СПЕРКО УКРАЇНА» з 1995 р. по 2012 р.",
    description2:
      "Комерційний директор ТОВ «Ерсель Фарма Україна» 2012 р. по т. ч.",
    description3: "",
  },

  {
    id: 17,
    name: "Гижко Петро Андрійович",
    degree: "к.т.н., доцент",
    description:
      "Начальник Регіонального відділення Фонду державного майна України у Вінницькій області з 1992 р. по 2014 р.",
    description1: "Генеральний директор ТОВ «Гідрокаскад» з 2016 р. по т. ч.",
    description2: "",
    description3: "",
  },
  {
    id: 18,
    name: "Михайленко Петро Миколайович.",
    degree: "к.т.н.",
    description:
      "Генеральний директор підприємства «Брацлав-ЗМ», заслужений машинобудівник України",
    description1: "",
    description2: "",
    description3: "",
  },
  {
    id: 19,
    name: "Марчук Анатолій Васильович",
    degree:
      "Головний технолог ПРАТ «Вінницький завод «Маяк» з 2019 р. по т. ч.",
    description: "",
    description1: "",
    description2: "",
    description3: "",
  },

  {
    id: 120,
    name: "Лещенко Петро Парфентійович",
    degree:
      "Головний конструктор 45-го експериментального механічного заводу (м. Вінниця) з 1992 р. по2000 р.",
    description:
      "Заступник начальника 45-го експериментального механічного заводу з комерційних питань з 2000 р. по 2006 р.",
    description1:
      "Перший заступник директора 45-го експериментального механічного за-воду з 2008 р. по 2010 р.",
    description2: "",
    description3: "",
  },

  {
    id: 21,
    name: "Олексієнко Леонід Миколайович",
    degree:
      "Генеральний директор ПАТ «Закордоненергокомплектбуд» (м. Канів)з 1998 р. по 2011 р.",
    description: "",
    description1: "",
    description2: "",
    description3: "",
  },

  {
    id: 22,
    name: "Михайлюк Іван Архипович",
    degree: "",
    description:
      "Перший заступник міського голови м.Вінниці (до 2019 р.), тепер голова Ради Поважних  при міському голові м. Вінниці. ",
    description1:
      "Закінчив ВНТУ в 1978 році за спеціальністю «Технологія машинобудування, металорізальні верстати та інструменти».",
    description2: "",
    description3: "",
  },
  {
    id: 23,
    name: "Пилявець Георгій Борисович ",
    degree: "",
    description:
      "директор ТОВ «Поділлякомункомплект». Працював заступником директора Вінницького заводу газових аналізаторів.",
    description1:
      "Закінчив ВНТУ в 1972 році за спеціальністю «Технологія машинобудування, металорізальні верстати та інструменти». ",
    description2: "",
    description3: "",
  },
  {
    id: 24,
    name: "Коц Іван Васильович",
    degree: "к.т.н., професор",
    description:
      "Професор кафедри інженерних систем у будівництві ВНТУ, завідувач і науковий керівник  науково-дослідної лабораторії гідродинаміки Вінницького національного технічного університету, є дійсним членом Академії будівництва України.",
    description1:
      "Закінчив ВНТУ у 1971 році за спеціальністю «Технологія машинобудування, металорізальні верстати та інструменти»",
    description2: "",
    description3: "",
  },
  {
    id: 25,
    name: "Михалевич Володимир Маркусович",
    degree: "д.т.н., професор",
    description:
      "завідувач кафедри вищої математики ВНТУ. Осн. наук. дослідж. у галузі моделювання процесів руйнування при пластич. деформуванні та повзучості із застосуванням до обробки матеріалів тиском. Співукладач «Тлумачного словника з інформаційно-педагогічних технологій» (В., 2010). ",
    description1:
      "Закінчив ВНТУ у 1975 році за спеціальністю «Технологія машинобудування, металорізальні верстати та інструменти».",
    description2: "",
    description3: "",
  },
  {
    id: 26,
    name: "Матвійчук Віктор Андрійович",
    degree: "д.т.н., професор",
    description:
      "завідувач кафедри електроенергетики, електротехніки та електромеханіки інженерно-технологічного факультету Вінницького національного аграрного університету (до 2023 р.). Науковий напрям «Розвиток ресурсозберігаючих процесів локального деформування на основі оцінки деформівності металів».  Голова екзаменаційної комісії із захисту магістерських кваліфікаційних робіт зі  спеціальності «Прикладна механіка», ВНТУ (до 2023 р. ).",
    description1:
      "Закінчив ВНТУ у 1977 році за спеціальністю «Технологія машинобудування, металорізальні верстати та інструменти».",
    description2: "",
    description3: "",
  },
  {
    id: 26,
    name: "Мальований Валентин Володимирович",
    degree: "",
    description:
      "Засновник (1994 р.), директор відомої в Україні і за її межами ювелірної фірми «МАЛЬВА», Вінниця. ",
    description1:
      "По закінченні ВНТУ за спеціальністю «Технологія машинобудування, металорізальні верстати та інструменти» у 1982 році працював на ДПЗ-18 інженером, майстром інструментального цеху. Пізніше,  на заводі «Кристал» – ведучим інженером-конструктором, начальником конструкторського бюро відділу головного технолога. З 1988 року працює в напрямі ювелірних технологій.",
    description2: "",
    description3: "",
  },

  {
    id: 27,
    name: "Гончарук Віталій Валерійович",
    degree: "",
    description:
      "Засновник, директор виробництва машинобудівного профілю ТОВ «АНТАРЕС СТАЛЬ ГРУП»",
    description1:
      "Під час навчання у ВНТУ з 2000 по 2005 рік брав активну участь як в  студентській науково-технічній діяльності, так і в студентському самоврядуванні в якості голови студентської ради  факультету машинобудування і транспорту.",
    description2:
      "По закінченні навчання почав займатись винахідницькою діяльністю і з часом створив сучасне потужне виробництво металообробного профілю.",
    description3: "",
  },
  {
    id: 28,
    name: "Ницимайло Вячеслав Олегович ",
    degree: "",
    description:
      "Начальник виробничо-технологічної служби підприємства ТОВ «ГРІН КУЛ» ",
    description1: "",
    description2: "",
    description3: "",
  },
];

export default graduates;
