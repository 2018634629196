const articles = [
  {
    id: 1,
    name: "Сухоруков С.І.",
    title: "Оцінка деформуємості заготовок при поперечно-клиновій прокатці",
    description:
      "/ С.І. Сухоруков, К.І. Коцюбівська., І.О. Сивак // Наукові нотатки: міжвузівський зб. – Луцьк: ЛДТУ, 2009. -.С. 272 – 275.",
    year: 2009,
    type: "thesis",
  },
  {
    id: 2,
    name: "Kotsiubivska K.I.",
    title: "Radial Extrusion of workpieces using backpressure",
    description:
      "/ K.I. Kotsubivska, Yu. A. Burennikov, I. Rusu and I.O. Sivak // Buletinul Institutului Politehnik din Jasi: Tomul LV (LIX), Fasc. 4, 2009. – P. 195 – 202.",
    year: 2009,
    type: "article",
  },
  {
    id: 3,
    name: "Kozlov L.G.",
    title:
      "Dynamic characteristics of the proportionally-controlled pressure valve",
    description:
      "/ L.G. Kozlov, Yu.A. Burennikov, V.A. Kovalchuk // Bulletin of the Polytechnic Institute of Iasi. – 2009. – Vol. LV (LIX), Part 4. – Р. 203-209.",
    year: 2009,
    type: "article",
  },
  {
    id: 4,
    name: "Коцюбівська К.І., Бурєнніков Ю.А., Сивак І.О.",
    title:
      "Напружено-деформований стан та деформуємість заготовок при поперечному видавлюванні з протитиском",
    description:
      "// збірник наукових праць Вінницького державного аграрного університету. Серія: Технічні науки. Вип. 4. – 2010. – С.55-58",
    year: 2010,
    type: "thesis",
  },
  {
    id: 5,
    name: "Сивак І.О., Ярошенко Т.В.",
    title: "Зміцнення поверхневого шару металу у разі вдавлювання кульки",
    description: "// Вісник ВПІ. – №4(91).– 2010. – С.54-58.",
    year: 2010,
    type: "article",
  },
  {
    id: 6,
    name: "Сіивак Р.І., Сивак І.О.",
    title: "Пластичність металів при складному навантеженніи",
    description:
      "// Вісник НТУУ «КПІ» №60. Серія: Машинобудування. – 2010.–С129-132.",
    year: 2010,
    type: "article",
  },
  {
    id: 7,
    name: "Сивак Р.І., Сердюк О.В., Сивак І.О.",
    title: "Вплив немонотонності пластичної деформації на напружений стан",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорськ: ДГМА.– №2(23).– 2010.– С.3-7",
    year: 2010,
    type: "thesis",
  },
  {
    id: 8,
    name: "Ярошенко Т. В., Сивак І. О.",
    title:
      "Ступінь зміцнення металлу та залишкові напруження в поверхневому шарі деталі після обкочування тороїдальним роликом.",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №3(28). – 2011. – С. 12 – 15. ISN 2076-2151.",
    year: 2011,
    type: "thesis",
  },
  {
    id: 9,
    name: "Коцюбівська К.І., Бурєнніков Ю. А., Сивак І. О.",
    title:
      "Ресурс пластичності металів при поперечному видавлюванні з протитиском.",
    description: "- Монографія - Вінниця: ВНТУ, 2011. – 156 с.",
    year: 2011,
    type: "article",
  },
  {
    id: 10,
    name: "Сивак Р. і., Нахайчук О. В., Сивак і. О.",
    title: "Вплив геометрії траекторії навантаження на пластичність",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №1(26). – 2011. – С. 22 – 25. ISN 2076-2151",
    year: 2011,
    type: "thesis",
  },
  {
    id: 11,
    name: "Сивак Р. И., Савчинский И. Г., Сивак И. О.",
    title: "Оцінка граничних деформацій при немонотонному навантажені",
    description:
      "// Вісник Національного технічного університету України «КПІ» Серія. Машинобудування. – К.: НТУУ «КПІ». - №60. – 2011. – С. 247 - 250.",
    year: 2011,
    type: "article",
  },
  {
    id: 12,
    name: "Козлов Л.Г., Шаматієнко Я.В., Ковальчук А.І., Черніюк В.М.",
    title:
      "Стійкість гідропривода з пропорційним керуванням при знакозмінному навантаженні",
    description:
      "// Вісник Хмельницького національного університету – Хмельницький- №4-2011-С.14-19",
    year: 2011,
    type: "article",
  },
  {
    id: 13,
    name: "Дерібо О.В., Сердюк О.В., Сивак І.О.",
    title:
      "Електрогідравлічний стежний привод пристрою для обробки поверхневим пластичним деформуванням",
    description:
      "// Вісник Вінницького політехнічного інституту –Вінниця,:ВНТУ-№6-2010-С.76-80",
    year: 2010,
    type: "article",
  },
  {
    id: 14,
    name: "Савуляк В.В.",
    title:
      "Вплив тертя на процес штампування регулярних профілів з листових матеріалів",
    description:
      "[Електронний ресурс] // Наукові праці Вінницького національного технічного університету.– 2011.– №3.– Режим доступу до журн.: http://www.nbuv.gov.ua/e-journals/VNTU/2011_2/2011-2.files/uk/11dolped_ua.pdf",
    year: 2011,
    type: "thesis",
  },
  {
    id: 15,
    name: "Дерібо О.В., Дусанюк Ж.П. ",
    title:
      "Досвід викладання теми «Сумарна похибка механічної обробки та її складові» дисципліни «Теоретичні основи технології виробництва деталей та складання машин»",
    description:
      "// Вісник Вінницького політехнічного інституту –Вінниця,:ВНТУ-№5-2011-С.194-198",
    year: 2011,
    type: "article",
  },
  {
    id: 16,
    name: "Sivak I., Savulyak V., Chorna J.",
    title:
      "CONSTRUCTION OF PLASTICITY DIAGRAMS ON THE BASIS OF LIMIT FORMING DURING UPSETTING PROCESS",
    description:
      " /Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 4, 2011-P.209-217",
    year: 2011,
    type: "article",
  },
  {
    id: 17,
    name: "SAVULIAK V.",
    title:
      "MODELLING OF THE PROCESS OF REGULAR ROFILES STAMPING FROM SHEET MATERIALS",
    description:
      "Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 4, 2011-P.199-208",
    year: 2011,
    type: "article",
  },
  {
    id: 18,
    name: "KOZLOV L.",
    title: "ENERGY-SAVING  MECHATRONIC DRIVE OF THE MANIPULATOR",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 3, 2011-P.231-239",
    year: 2011,
    type: "article",
  },
  {
    id: 19,
    name: "KOZLOV   L.,   LOZOVSKIY   S.,   KOVALCHUK   V.,   TSIMBAL   V.",
    title:
      "DYNAMIC CHARACTERISTICS OF THE HYDRAULIC DRIVE WITH PROPORTIONAL FLOW RATE CONTR",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 3, 2011-P.239-247",
    year: 2011,
    type: "article",
  },
  {
    id: 20,
    name: "BURENNIKOV YU., KOZLOV L., PETROV A.",
    title:
      "DYNAMIC AND STATIC CHARACTERISTICS OF THE LS HYDRAULIC DRIVE ON THE BASIS OF MULTIMODE DIRECTIONAL CONTROL VALVE",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 2, 2011-P.211-219",
    year: 2011,
    type: "article",
  },
  {
    id: 21,
    name: "BURENNIKOV YU., KOZLOV L., REPINSKIY S.",
    title:
      "OPTIMIZATION OF THE DESIGN PARAMETERS OF A COMBINED FLOW REGULATOR FOR THE VARIABLE AXIAL-PISTON PUMP",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 2, 2011-P.219-229",
    year: 2011,
    type: "article",
  },
  {
    id: 22,
    name: "Лозінський Д. О.",
    title:
      "Дослідження системи керування пропорційним електрогідравлічним розподільником",
    description:
      "[Електронний ресурс] / Д. О. Лозінський, Л. Г. Козлов, М. М. Лозінська, В. О. Сенченко, Є. І. // Наукові праці Вінницького національного технічного університету.– 2011.– №2.– Режим доступу до журн.:   http://www.nbuv.gov.ua/e-journals/VNTU/2011_2/2011-2.files/uk/11dolped_ua.pdf",
    year: 2011,
    type: "thesis",
  },
  {
    id: 23,
    name: "Лозан Н.М., Муляр Ю.І., Сапожинський Ю.М., Дупляк В.Д.",
    title: "Конструкція пристрою для оброблення деревини",
    description:
      "/Науковий вісник НЛТУ України – Львів – 2011 –Випуск 21.13 - С.141-146.",
    year: 2011,
    type: "article",
  },
  {
    id: 24,
    name: "SAVULIAK V., SEMICHASNOVA N.",
    title:
      "ENSURING RELIABLE OPERATION OF HYDRAULIC DRIVES WITH HYDRAULIC MOTORS",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 4, 2011-P.191-199.",
    year: 2011,
    type: "article",
  },
  {
    id: 25,
    name: "BURENNIKOV YU., RUSU I., LOZAN N.",
    title: "METAL PLASTICITY UNDER NON-MONOTONIC LOADING",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 2, 2011 - P.229-237.",
    year: 2011,
    type: "article",
  },
  {
    id: 26,
    name: "BURENNIKOV YU., BURENNIKOV YU. JR, DOBROVOLSKY A., KRIVA S.",
    title: "BUSINESS PROCESSES PERFECTION OF SMALL MOTOR TRANSPORTENTERPRISES",
    description:
      "/Buletinul institutului politehnic Din Iasi.- Tomul LVII (LXI), Fasc. 2, 2011 - P.237-245.",
    year: 2011,
    type: "article",
  },
  {
    id: 27,
    name: "Савуляк В. В.,  Мальований І. Ю.",
    title:
      "Розширення технологічних можливостей отримання гнутих профілів з листових матеріалів із покриттям.",
    description:
      "/Наукові праці ВНТУ, №2. – 2012. – 5 с. http://praci.vntu.edu.ua/article/download/2328/2581",
    year: 2012,
    type: "thesis",
  },
  {
    id: 28,
    name: "Савуляк В. В., Ницимайло В. О.",
    title:
      "Вибір параметрів покриття для забезпечення мінімальних радіусів згину.",
    description: "/Вісник ВПІ, №4. -2012. с.168 – 172.",
    year: 2012,
    type: "article",
  },
  {
    id: 29,
    name: "Сивак І. О., Ярошенко Т. В.",
    title:
      "Оцінка ступеня зміцнення і пошкодженості поверхневого шару металу при вдавлюванні кульки",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №1(26). – 2011. – С. 49 – 53. ISN 2076-2151",
    year: 2011,
    type: "thesis",
  },
  {
    id: 30,
    name: "Сивак І. О., Ярошенко Т. В.",
    title:
      "Дослідження зміцнення поверхневого шару металу при вдавлюванні кульки",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №1(27). – 2012. – С. 40 – 44. ISN 2076-2151",
    year: 2012,
    type: "thesis",
  },
  {
    id: 31,
    name: "Сухоруков С. І., Сивак І. О., Коцюбівська К. І.",
    title:
      "Вплив параметрів розбиття скінченно-елементної моделі на точність результатів моделювання процесу поперечно-клинової прокатки",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №3 (32). – 2012. – С. 35 – 40. ISN 2076-2151",
    year: 2012,
    type: "thesis",
  },
  {
    id: 32,
    name: "Сердюк О. В.",
    title:
      "Моделювання процесу деформування поверхневого слою при обкатці циліндричним роликом",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №3 (32). – 2012. – С. 15 – 19. ISN 2076-2151.",
    year: 2012,
    type: "thesis",
  },
  {
    id: 33,
    name: "Гайдамак О.Л., Огородніков В.А., Гончарук А.О., Сивак І.О.",
    title:
      "Дослідження силових характеристик процесу формоутворення радіальним обтискуванням канавок на деталях типу стрижень",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №3 (32). – 2012. – С. 131 – 134. ISN 2076-2151.",
    year: 2012,
    type: "thesis",
  },
  {
    id: 34,
    name: "Козлов Л.Г.",
    title:
      "Вплив конструктивних параметрів системи керування на стійкість гідропривода, чутливого до навантаження",
    description:
      "/ Л.Г. Козлов, М.В. Гесаль, В.А. Ковальчук, В.Є. Арбузова // Наукові нотатки. Луцький національний технічний університет .– 2011.–Вип. №35.–С.96-100",
    year: 2011,
    type: "article",
  },
  {
    id: 35,
    name: "Савуляк В.І.",
    title: "Аналіз відмов гідроприводів мобільних машин під час запуску",
    description:
      "/Промислова гідравліка та пневматика. 2012, №1(35), – С.12-14",
    year: 2012,
    type: "article",
  },
  {
    id: 36,
    name: "Іскович-Лотоцький Р. Д.",
    title:
      "Вплив вібраційних навантажень на процес зневоднення жому цукрового буряка",
    description:
      "/ Р. Д. Іскович-Лотоцький, О. В. Поліщук, С. В. Репінський, А. В. Слабкий // Збірник наукових праць Вінницького національного аграрного університету. – 2012. – № 10 т.1 (58). – С. 128–136.",
    year: 2012,
    type: "article",
  },
  {
    id: 37,
    name: "Бурєнніков Ю. А.",
    title:
      "Оптимізація конструктивних параметрів комбінованого регулятора подачі аксіально-поршневого регульованого насоса",
    description:
      "/ Ю. А. Бурєнніков, Л. Г. Козлов, С. В. Репінський, О. В. Поліщук // Промислова гідравліка і пневматика. – 2012. – № 1(35). – С. 73–77.",
    year: 2012,
    type: "article",
  },
  {
    id: 38,
    name: "Бурєнніков Ю. А.",
    title:
      "Система керування аксіально-поршневого регульованого насоса з профільованим вікном золотника комбінованого регулятора подачі",
    description:
      "/ Ю. А. Бурєнніков, Л. Г. Козлов, С. В. Репінський // Вісник Національного технічного університету України «Київський політехнічний інститут». Серія Машинобудування. – 2012. – № 64. – С. 113–118.",
    year: 2012,
    type: "article",
  },
  {
    id: 39,
    name: "Бурєнніков Ю. А.",
    title:
      "Аналіз переваг та недоліків існуючих регуляторів подачі та потужності в системі керування аксіально-поршневого регульованого насоса",
    description:
      "/ Ю. А. Бурєнніков, Л. Г. Козлов, С. В. Репінський, О. В. Поліщук // Вісник Вінницького політехнічного інституту. – 2012. – № 5. – С. 107-113.",
    year: 2012,
    type: "article",
  },
  {
    id: 40,
    name: "Лозінський Д. О.",
    title:
      "Порівняльні дослідження варіантів першого каскаду пропорційного електрогідравлічного розподільника",
    description:
      "[Електронний ресурс] / Д.О. Лозінський, Л.Г. Козлов, Є.І. Шевчук // Наукові праці Вінницького національного технічного університету.– 2012.– №2.– Режим доступу до журн.: http://www.nbuv.gov.ua/e-journals/VNTU/2012_2/2012-2.files/uk/ 12dalehd_ua.pdf",
    year: 2012,
    type: "thesis",
  },
  {
    id: 41,
    name: "Петров О.В.",
    title:
      "Формування величини зрівноважу вального перепаду тиску в гідроприводі чутливому до навантаження, на базі мультирежимного гідророзподільника",
    description:
      "/ О.В. Петров, Л.Г. Козлов// Вісник ВНТУ. – 2012 . - №2 .- с. 179 -184.",
    year: 2012,
    type: "article",
  },
  {
    id: 42,
    name: "Козлов Л.Г.",
    title:
      "Енергоощадний гідропривід , чутливий до навантаження, на базі мультирежимного гідро розподільника",
    description:
      "/ Л.Г. Козлов, О.В. Петров // Промислова гідравліка і пневматика.-  Вінниця: ВДАУ, 2012.- №2 (36) .- С. 77-80 ",
    year: 2012,
    type: "article",
  },
  {
    id: 43,
    name: "Петров О.В.",
    title:
      "Інтерфейс обміну даних  у середовищі MatLab Simulink для математичного моделювання робочих процесів у гідроприводі, чутливому до навантаження",
    description:
      "/ О.В. Петров Л.Г. Козлов С.М. Лозовський О.С. Дроздов // Вісник СДУ. Сер. Технічні науки .- 2011 .- №3 .- С. 103-110.",
    year: 2011,
    type: "article",
  },
  {
    id: 44,
    name: "Петров О.В.",
    title:
      "Дослідження гідравлічних втрат тиску у тривимірних моделях гідроагрегатів за допомогою комп’ютерного моделювання гідродинамічних процесів у CAD/CAE  - системі",
    description:
      " / О.В. Петров  С.І. Сухоруков П.О. Печенкін О.О. Павлюк // Промислова гідравліка та пневматика. -  Вінниця : ВДАУ, 2012 .- №1 (35) .- С. 78-80.",
    year: 2012,
    type: "article",
  },
  {
    id: 45,
    name: "Сухоруков С.І.",
    title:
      "Вплив параметрів процесу плоскої  поперечно-клинової прокатки на величину використаного ресурсу пластичності",
    description:
      "/ С.І. Сухоруков, В.О. Сорокоумова, В.О. Багрій / Вісник Хмельницького національного університету. – Хмельницький, 2013. – №1. – C. 37-40. ",
    year: 2013,
    type: "article",
  },
  {
    id: 46,
    name: "Сивак І.О.",
    title:
      "Розподіл внутрішніх силових факторів в перерізі циліндричної заготовки при локальному навантаженні",
    description:
      "/  І.О. Сивак, О.М. Мироненко, Є.І. Шевчук / Наукові праці Вінницького національного технічного університету, №1. 2013. с.32 – 36. ",
    year: 2013,
    type: "thesis",
  },
  {
    id: 47,
    name: "Сухоруков С. И., Сивак И. О., Коцюбивская Е. И.",
    title:
      "Вплив параметрів розбиття чкінченно-елементної моделі на точність результатів моделювання процесі поперечно-клинової прокатки",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №3 (32). – 2012. – С. 35 – 40.",
    year: 2012,
    type: "thesis",
  },
  {
    id: 48,
    name: "Сердюк О. В.",
    title:
      "Моделювання процесу деформування поверхневого слою при обкатці цилиндричним роликом",
    description:
      "// Обробка металлів тиском: збірн. праць. – Краматорск: ДГМА. - №3 (32). – 2012. – С. 15 – 19.",
    year: 2012,
    type: "thesis",
  },
  {
    id: 49,
    name: "Козлов Л.Г.",
    title: "Мехатронна гідросистема мобільної машини",
    description:
      "// Вісник Східноукраїнсько-го університету імені Володимира Даля – 2012 -  №6 – С.22-30.",
    year: 2012,
    type: "article",
  },
  {
    id: 50,
    name: "Бурєнніков Ю. А., Козлов Л.Г., Репінський С. В.",
    title:
      "Система керування аксиально-поршневого насоса з профільованим вікном золотника регулятора подачi",
    description: "// Вісник КПІ. «Машинобудування»  – 2012 – № 64 – С.113-118.",
    year: 2012,
    type: "article",
  },
  {
    id: 51,
    name: "Бурєнніков Ю. А., Козлов Л.Г., Репінський С. В., Поліщук О.В.",
    title:
      "Аналіз переваг та недоліків існуючих регуляторів подачі та потужності в системах керування аксиально-поршневих регульованих насосів",
    description: "// Вісник ВПІ.  – 2012 – № 5 –С.107-113.",
    year: 2012,
    type: "article",
  },
  {
    id: 52,
    name: "Козлов Л.Г., Петров О.В., Павлюк О.О., Печенкін П.О.",
    title:
      "Удосконалення елементів конструкції гідроагрегатів на основі комп’ютерного моделювання гідродинамічних процесів у CAD/CAE-системі",
    description:
      "// Збірник нвукових праць SWorld. Матеріали міжнародної, науково-практичної конференції «Сучасні проблеми і шляхи їх вирішення в науці, транспорті, виробництві і освіті’2012». – Випуск 4. Том 5. – Одесса: КУПРИЕНКО, 2012. – ЦИТ: 412-0756. – С. 75-79.",
    year: 2012,
    type: "thesis",
  },
  {
    id: 53,
    name: "Козлов Л.Г., Бойко І.В., Піонткевич О.В.",
    title:
      "Вплив параметрів системи керування на стійкість гідропривода інваріантного до знакозмінного навантаження",
    description: "// Наукові нотатки. –ЛНТУ – 2013 – № 40 – С. 118-124.",
    year: 2013,
    type: "article",
  },
  {
    id: 54,
    name: "Козлов Л.Г., Товкач А.О., Зінченко А.В.",
    title: "Зменшення втрат потужності в гідросистемі мобільної робочої машини",
    description: "// Вісник ХНУ – 2013 – № 4 – С. 53-61.",
    year: 2013,
    type: "article",
  },
  {
    id: 55,
    name: "Козлов Л.Г., Гайдамак О.Л., Піонткевич О.В.",
    title:
      "Вплив параметрів на стійкість та швидкодію гідропривода з електрогідравлічним керуванням",
    description: "// Вісник ХНУ – 2013 – № 4 – С. 29-34.",
    year: 2013,
    type: "article",
  },
  {
    id: 56,
    name: "Kozlov L.",
    title:
      "Digital PD controller for dynamic correction of the differential component coefficient for a mechatronic hydraulic system",
    description:
      "// Tehnomus journal: Proceedings of the XVIIth International Conference “New Technologies and Products in Machine  Manufacturing  Technologies”.  –  Suceava,  Romania,  May  17  –  18,  2013.  – P. 120-125.",
    year: 2013,
    type: "article",
  },
  {
    id: 57,
    name: "Пурдик В.П.",
    title:
      "Математичне моделювання регулятора витрати з робочим органом із полімерного матеріалу",
    description:
      "/ Брицький О.Л.// Промислова гідравліка та пневматика .  – 2013. - №1(39). - С.75-77.",
    year: 2013,
    type: "article",
  },
  {
    id: 58,
    name: "Пурдик В. П.",
    title:
      "Стенд для експериментального дослідження динамічних характеристик гнучких рукавів",
    description:
      "[електронний ресурс]./ Поздняков М.Ю., // Наукові праці Вінницького національного технічного університету.–№1.–2013.Режим доступу до журналу http://www.nbuv.gov.ua/e-journals/VNTU/2013-1.files/uk/",
    year: 2013,
    type: "thesis",
  },
  {
    id: 59,
    name: "Пурдик В.П.",
    title:
      "Методика визначення  динамічних характеристик гнучких рукавів високого тиску.",
    description:
      "/ Поздняков М.Ю.//Луцький національний технічний університет. Наукові нотатки. Міжвузівський збірник (за галузями знань «Машинобудування та металообробка», «Інженерна механіка», «Металургія та матеріало-знавство»  Випуск 41 Частина 2 (квітень – червень, 2013) С. 139-143.",
    year: 2013,
    type: "thesis",
  },
  {
    id: 60,
    name: "Брицький О.Л. ",
    title:
      "Особливості математичного моделювання регулятора витрати з робочим органом із полімерного матеріалу",
    description:
      "/ Пурдик В.П.// – Луцький національний технічний університет. Наукові нотатки. Міжвузівський збірник (за галузями знань «Машинобудування та металообробка», «Інженерна механіка», «Металургія та матеріало-знавство»  Випуск 42 Частина 2 (квітень – червень, 2013) С. 216-219.",
    year: 2013,
    type: "thesis",
  },
  {
    id: 61,
    name: "Пурдик В.П.",
    title:
      "Експериментальні дослідження динамічних характеристик гнучких трубопроводів високого тиску",
    description:
      "/Поздняков М.Ю.//  Промислова гідравліка та пневматика.– 2013 -  №4(39)  -  С.75-77.",
    year: 2013,
    type: "article",
  },
  {
    id: 62,
    name: "Лозінський Д.О.",
    title:
      "Експериментальні дослідження першого каскаду пропорційного електрогідравлічного розподлільника з незалежним керуванням потоків",
    description:
      "/ Д.О. Лозінський, А. М. Білінський, А. О. Наконечна // Вісник факультету машинобудування та транспорту.  – 2017. – №1. – С. 53–59.",
    year: 2017,
    type: "article",
  },
  {
    id: 63,
    name: "Лозінський Д.О.",
    title:
      "Дослідження динамічних характеристик пропорційного електрогідравлічного розподільника  з незалежним керуванням потоків та стежною системою",
    description:
      "Д. О. Лозінський,  А. М. Білінський, М. М. Лозінська, А. В. Коломійчук // Вісник факультету машинобудування та транспорту.  – 2018. – №2. – С. 45–50.",
    year: 2018,
    type: "article",
  },
  {
    id: 64,
    name: "O. Petrov,",
    title: "Improvement of the Hydraulic Units Design Based on CFD Modeling",
    description:
      "/O. Petrov, L. Kozlov, D. Lozinskiy, O. Piontkevych// Advances in Design, Simulation and Manufacturing II. Lecture Notes in Mechanical Engineering. DSMIE 2019. LNME. Springer, Cham. 2020. P. 653-660. doi: 10.1007/978-3-030-22365-6_65.",
    year: 2019,
    type: "article",
  },
  {
    id: 65,
    name: "Dmytro O. Lozinskyi",
    title:
      ". Application of feedback elements in proportional electrohydraulic directional control valve with independent flows control",
    description:
      "/ Dmytro O. Lozinskyi , Oleksandr V. Petrov , Natalia S. Semichasnova // Vol. II. London: Taylor & Francis Group, CRC Press, Balkema book, 2020 P. 127-137. doi: 10.1201/9781003224136.",
    year: 2020,
    type: "article",
  },
  {
    id: 66,
    name: "Petrov O.",
    title:
      "Modeling of the Operating Process in LS Hydraulic Drive Using MatLab GUI Tools",
    description:
      "/ Petrov O., Slabkyi A., Vishtak I., Kozlov L.: Mathematical Modeling of the Operating Process in LS Hydraulic Drive Using MatLab GUI Tools. DSMIE 2020, Lecture Notes in Mechanical Engineering, 1-11 (2020). https://doi.org/10.1007/978-3-030-50491-5",
    year: 2020,
    type: "article",
  },
  {
    id: 67,
    name: "О. В. Петров",
    title:
      "Дослідження стійкості роботи гідроприводу, чутливого до навантаження, на базі мультирежимного гідророзподільника ",
    description:
      "/ О. В. Петров, Л. Г. Козлов, Н. С. Семічаснова, О. О. Завальнюк // Вісник машинобудування та транспорту. – 2020. – № 2. – С. 93-99.",
    year: 2020,
    type: "article",
  },
  {
    id: 68,
    name: "В. Г. Пилявець",
    title:
      "Експериментальні дослідження адаптивної гідросистеми на базі розподільника з пропорційним електрогідравлічним керуванням",
    description:
      "] / В. Г. Пилявець, А. О. Товкач, Л. Г. Козлов [та ін.] // Наукові праці ВНТУ. – 2020. – № 1. – Режим доступу: https://praci.vntu.edu.ua/index.php/praci/article/view/594/559.",
    year: 2020,
    type: "thesis",
  },
  {
    id: 69,
    name: "Leonid К Polishchuk, Oleh V Khmara, Oleh V Piontkevych, Oksana O Adler, Aigul Tungatarova",
    title:
      "Dynamics of the conveyor speed stabilization system at variable loads",
    description:
      "Informatyka, Automatyka, Pomiary w Gospodarce i Ochronie Środowiska, 12(2), 2022, 60-63 pp.",
    year: 2022,
    type: "article",
  },
  {
    id: 70,
    name: "Obertyukh R., Slabkyi A., Petrov O., Bakalets D., Sukhorukov S.",
    title:
      "Substantiation of the Design Calculation Method for the Vibroturning Device",
    description:
      "Lecture Notes in Mechanical Engineeringthis link is disabled, 2022, pp. 185–195. DOI: 10.1007/978-3-031-06025-0_19",
    year: 2022,
    type: "article",
  },
  {
    id: 71,
    name: "Н. В. Бурєннікова, Л. Г. Козлов, Ю. А. Бурєнніков, І. В. Завгородній",
    title:
      "Теоретичні засади результативності трансферу технологій: сутність, оцінювання, управління",
    description:
      "Бізнес Інформ Науково-дослідний центр індустріальних проблем розвитку НАН України; Харківський національний економічний університет, № 7, с. 162-170, 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 72,
    name: "В. І. Перепелиця, Л. Г. Козлов",
    title:
      "Визначення параметрів установки для формування заготовок цегли, що забезпечують мінімальну похибку переміщення",
    description:
      "Вісник Хмельницького національного університету, Серія: «Технічні науки», № 4, 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 73,
    name: "О. В. Дерібо, Ж. П. Дусанюк, і С. В. Репінський",
    title:
      "ВПЛИВ КОЛИВАНЬ ПОДАЧІ ШЕСТЕРЕННОГО НАСОПА НА ДИНАМІЧНІ ПРОЦЕСИ В ГІДРОПРИВОДІ З ДОВГОЮ НАПІРНОЮ ГІДРОЛІНІЄЮ",
    description: "Вісник ВПІ, вип. 5, с. 86–91, Жовт. 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 74,
    name: "О. В. Дерібо, С. В. Репінський, і К. С. Коваленко",
    title:
      "ПОРІВНЯЛЬНИЙ АНАЛІЗ РЕЗУЛЬТАТІВ ВИЗНАЧЕННЯ ПРИПУСКІВ ЗА НОРМАТИВАМИ ТА ЗА РОЗРАХУНКОВО-АНАЛІТИЧНИМ МЕТОДОМ З ВИКОРИСТАННЯМ РОЗМІРНОГО АНАЛІЗУ ТЕХНОЛОГІЧНИХ ПРОЦЕСІВ",
    description: "Вісник ВПІ, вип. 6, с. 69–74, Груд. 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 75,
    name: "Мовчанюк В.О., Жук П.М., Карпінська О.Д., Карпінський М.Ю., Сухоруков С.І.",
    title:
      "Експериментальне дослідження щільності кісткової тканини при монокандилярній артропластиці колінного суглоба за попередніми даними КТ та інтраопераційного її визначення",
    description: "Травма, № 1, с. 12-18, 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 76,
    name: "Жук П.М., Мовчанюк В.О., Маціпура М.М., Шаммо А.М., Сухоруков С.І.",
    title:
      "Особливості мінеральної щільності великогомілкової кістки на рівні її опилу при монокондилярній артропластиці колінного суглоба",
    description:
      "Вісник Вінницького національного медичного університету, т. 26, № 2, с. 214-219, 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 77,
    name: "В. В. Савуляк",
    title:
      "Вплив коливання фізико-механічних характеристик листових матеріалів на результати експериментальних досліджень",
    description:
      "Міжвузівський збірник «Наукові нотатки», № 73, с. 153-158, 2022.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 78,
    name: "О. В. Піонткевич, С. І. Сухоруков, О. В. Сердюк, В. М. Домославський",
    title:
      "Про лазерний технологічний комплекс на машинобудівному підприємстві",
    description: "Вісник машинобудування та транспорту, 16 (2), 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 79,
    name: "Kozlov, L., Bilichenko, V., Kashkanov, A., Tovkach, A., Kovalchuk, V.",
    title:
      "Parametric Synthesis of Electrohydraulic Control System for Variable Displacement Pump",
    description: "Lecture Notes in Mechanical Engineering, 2024, pp. 48–57.",
    year: 2024,
    type: "article",
  },
  {
    id: 80,
    name: "Kozlov, L., Poliakov, A., Yakobinchuk, O., Gubarev, O., Makarova, T.",
    title:
      "Mechatronic Hydraulic System with Adaptive Regulator for a Manipulator of the Mobile Working Machine",
    description: "Lecture Notes in Mechanical Engineering, 2023, pp. 64–73.",
    year: 2023,
    type: "article",
  },
  {
    id: 81,
    name: "Leonid Polishchuk, Leonid Kozlov, Yuri Burennikov, Vasil Strutinkiy, Valerii Kravchuk",
    title:
      "Application of hydraulic automation equipment for the efficiency enhancement of the operation elements of the mobile machinery",
    description:
      "Informatics Control Measurement in Economy and Environment Protection, n 2 (2019), p. 72 -78.",
    year: 2019,
    type: "article",
  },
  {
    id: 82,
    name: "Bereziuk O., Petrov O., Lemeshev M., Slabkyi A., Sukhorukov S.",
    title:
      "Transient Processes Quality Indicators of the Rotation Lever Hydraulic Drive for the Dust-Cart Manipulator",
    description:
      "Advances in Design, Simulation and Manufacturing VI. DSMIE 2023. Lecture Notes in Mechanical Engineering. Springer, Cham. https://doi.org/10.1007/978-3-031-32774-2_1.",
    year: 2023,
    type: "article",
  },
  {
    id: 83,
    name: "Viacheslav Perepelytsia, Leonid Kozlov, Iurii Buriennikov, Nataliia Burennikova, Sergii Kozlov, Oana Rusu, loan Rusu",
    title:
      "Optimization of hydraulic drives for synchronizing working movements of automated brick production installation",
    description:
      "International Journal of Modern Manufacturing Technologies ISSN 2067-3604, Vol. XV, No. 3 / 2023.",
    year: 2023,
    type: "article",
  },
  {
    id: 84,
    name: "В. І. Перепелиця, Л. Г. Козлов",
    title:
      "Система управління на базі контролера для керування швидкістю руху каретки установки для формування заготовок цегли",
    description:
      "Сучасні технології, матеріали і конструкції в будівництві, Том 34, № 1, с. 190-196, 2023. DOI: https://doi.org/10.31649/2311-1429-2023-1-190-196",
    year: 2023,
    type: "article",
  },
  {
    id: 85,
    name: "Лозінський Д. О., Козлов Л. Г., Піонткевич О. В., Кавецький О. І.",
    title:
      "Оптимізація електрогідравлічного розподільника з незалежним керуванням потоків",
    description:
      "Вісник машинобудування та транспорту. – 2023. – № 1. – С. 87–91.",
    year: 2023,
    type: "article",
  },
  {
    id: 86,
    name: "Інтегративний підхід до викладання спеціальних і фундаментальних дисциплін: сутність та напрями реалізації професійної адаптації студентів першого курсу машинобудівних спеціальностей",
    title: "Бурєнніков Ю., Хом`юк І., Козлов Л., Бурєннікова Н., Хом`юк В.",
    description:
      "Нова педагогічна думка. 2023. № 2(114). С. 97-111. DOI: https://doi.org/10.37026/2520-6427-2023-114-2-97-111",
    year: 2023,
    type: "article",
  },
  {
    id: 87,
    name: "Kovalevskyy S. V., Osipov V. M., Kukosh M.S.",
    title:
      "The role of the machine-building cluster in ensuring the defence capability of Ukraine",
    description:
      "Економічні інновації – 2023. – Том 25, Випуск 4(89). – С. 58-67.",
    year: 2023,
    type: "article",
  },
  {
    id: 88,
    name: "Ковалевський С.В., Ковалевська О.С., Сидюк Д.М",
    title:
      "Резонансна діагностика виробничого простору генеративних систем штучного інтелекту",
    description:
      "Міжнародний науково-технічний журнал «Штучний інтелект». «Фізико-математичні та технічні науки». – 2023. – № 2(96). –  С.94-107.",
    year: 2023,
    type: "article",
  },
  {
    id: 89,
    name: "Ковалевський С.В., Кошева Л.В.",
    title: "Вектор духовності в підготовці фахівців з відбудови України",
    description:
      "«Духовність особистості: методологія, теорія і практика» : збірник наукових праць. Вип. 5 (109). – СНУ ім. В.Даля, (Київ)  2023. – С. 112–123.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 90,
    name: "S. Kovalevskyy, D. Sydiuk",
    title:
      "Мodeling of diagnostics of engineering objects to optimize their life cycle",
    description: "Математичне моделювання, Випуск 2 (47), 2022. – С.105-113.",
    year: 2022,
    type: "article",
  },
  {
    id: 91,
    name: "Ковалевський С.В., Ковалевська О.С., Сидюк Д.М",
    title: "Комплексне управління життєвим циклом виробів машинобудування",
    description: "Технічна інженерія, 2(92), 67–73.",
    year: 2023,
    type: "article",
  },
  {
    id: 92,
    name: "Ковалевський С. В.",
    title:
      "Some aspects of the application of artificial intelligence for the recovery and development of Ukraine",
    description:
      "Міжнародний науково-технічний журнал «Штучний інтелект». «Фізико-математичні та технічні науки». – 2023. – № 3(97). –  С.117-125.",
    year: 2023,
    type: "article",
  },
  {
    id: 93,
    name: "С. В. Ковалевський, О. С. Ковалевська, Д. М. Сидюк",
    title:
      "Інноваційні рішення для адаптації технологічних систем до сучасних вимог",
    description: "Вісник ВПІ, вип. 5, с. 85–92, Жовт. 2023.",
    year: 2023,
    type: "article",
  },
  {
    id: 94,
    name: "О. В. Дерібо, Ж. П. Дусанюкс. В. Репінський",
    title:
      "Вплив коливань подачі шестеренного насоса на динамічні процеси в гідроприводі з довгою напірною гідролінією",
    description:
      "Вісник Вінницького політехнічного інституту № 5с. 86-91, 2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 95,
    name: "О. В. Дерібос. В. Репінський, К. С. Коваленко",
    title:
      "Порівняльний аналіз результатів визначення припусків за нормативами та за розрахунково-аналітичним методом з використанням розмірного аналізу технологічних процесів",
    description: "Вісник Вінницького політехнічного інституту № 6с.   2022.",
    year: 2022,
    type: "article",
  },
  {
    id: 96,
    name: "Хмара О. В., Поліщук Л. К., Піонткевич О. В.",
    title: "Адаптивна гідросистема конвеєра в режимі аварійних перевантажень",
    description:
      "XXIIІ міжнародна науково-технічна конференція АС ПГП «Промислова гідравліка і пневматика» Наукове видання матеріалів конференції, м. Київ, 15-16 грудня 2022.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 97,
    name: "І.В. Віштак, О.В. Петров",
    title:
      "Аналіз самозбудження осьових коливань в шпиндельних вузлах на конічних газових опорах",
    description:
      "Збірник наукових праць Х Міжнародної науково-технічної конференції «Прогресивні технології у машинобудуванні РТМЕ-2022» 1-5 лютого 2022 р., – Івано-Франківськ – Яремче, 2022. Тези доповідей. – 2022. с. 61-62.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 98,
    name: "К. С. Коваленко, О. В. Дерібо",
    title:
      "Порівняння результатів визначення припусків для обробки головних отворів у корпусних деталях за нормативами та за допомогою розрахункового методу з використанням розмірного аналізу технологічних процесів",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2023)» Електронний ресурс] – 2023. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2023/paper/viewFile/16861/14060.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 99,
    name: "В. В. Савуляк, Д. О. Салін, В. В. Василишен",
    title:
      "Покращення ефективносні кожухотрубного теплообмінника за допомогою імітаційного моделювання в СAE-системах",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2023)» Електронний ресурс] – 2023. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2023/paper/view/16889/14093.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 100,
    name: "Лозінський Д. О., Бакалєйнік Н. Д., Кавецький О. І.",
    title: "Гідропривод мобільної машини для вантажно-розвантажувальних робіт",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2023)» Електронний ресурс] – 2023. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2023/paper/view/16844.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 101,
    name: "С. В. Репінський, Ю. А. Бурєнніков, В. Н. Лукаш",
    title:
      "Застосування математичного планування експерименту для мінімізації шорсткості при розточуванні центрального отвору заготовки деталі «Корпус підшипника»",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2022)» Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2022/paper/view/14194.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 102,
    name: "О. М. Сікач, О. В. Дерібо",
    title:
      "Аналіз точності обробки і визначення коефіцієнтів уточнення, що забезпечуються чистовим і тонким точінням крупногабаритних заготовок на верстатах з ЧПК",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2022)» Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2022/paper/view/14178.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 103,
    name: "Мицик І.С., Сухоруков С.І.",
    title:
      "Розробка засобів автоматизації процесу проектування верстатних пристосувань",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2022)» Електронний ресурс] – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2022/paper/view/14580/12327.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 104,
    name: "О.В. Калінніков, Н. С. Семічаснова",
    title:
      "Аналіз впливу конструктивних параметрів пневмокамери на її силові характеристики",
    description:
      "Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2022)» Електронний ресурс] – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2022/paper/view/14576.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 105,
    name: "Ю. А. Бурєнніков, Л. Г. Козлов, А. Г. Буда",
    title:
      "Підвищення ефективності викладання дисципліни «Вступ до фаху» на машинобудівних спеціальностях",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15672.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 106,
    name: "Ю. А., Бурєнніков, О. В. Петров, І. І. Власенко",
    title:
      "Автоматизація розрахунку параметрів комбінованих ексцентрикових затискних пристроїв",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16210.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 107,
    name: "А. О. Товкач, Л. Г. Козлов, В. А. Ковальчук, Д. О. Катеринчук",
    title:
      "Експериментальні дослідження гідросистеми з електрогідравлічним регулятором насоса та контролером",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15600.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 108,
    name: "Л. Г. Козлов, В. І. Перепелиця",
    title:
      "Вплив параметрів автоматизованого пристрою для формування цегли на його динамічні характеристики",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15724.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 109,
    name: "Л. Г. Козлов, Ю. А. Бурєнніков, О. В. Гарболінський, О. М. Лукіянов",
    title: "Адаптивний регулятор для механотронної гідросистеми маніпулятора",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15816.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 110,
    name: "О. В. Дерібос. В. Репінський, О. В. Сердюк",
    title:
      "Досвід викладання спеціальних дисциплін технологічного спрямування на основі комплексного підходу у навчанні студентів бакалаврату спеціальності 131 – Прикладна механіка",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16151.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 111,
    name: "С. В. Репінський, Ю. С. Кобилянська, А. В. Малюшко",
    title:
      "Автоматизація гідравлічного розрахунку сумарних втрат тиску в трубопроводі",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15673.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 112,
    name: "О. В. Дерібо, А. В. Таранік, М. І. Котик",
    title:
      "До питання визначення складових мінімального проміжного припуску для попередньої обробки отворів у заготовках деталей типу «Корпус»",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15818.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 113,
    name: "Калінніков О.В., Сухоруков С.І.",
    title: "Мобільні застосунки для фотограмметрії",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16104.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 114,
    name: "В. В. Савуляк ",
    title:
      "Забезпечення якості гофрованих виробів з листових металів методами холодного штампування",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15958.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 115,
    name: "Лозінський Д. О., Кавецький О.І.",
    title:
      "Багатопотоковий електрогідравлічний привод з системою керуванням на основі елементів штучного інтелекту",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15659.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 116,
    name: "Гончарук К.І., Метельний О.Д., Гаврилюк В.В., Лозінський Д. О.",
    title:
      "Застосування САD/CAМ-систем для технологічної підготовки виготовлення деталей в машинобудуванні",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15664.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 117,
    name: "Сердюк О.В.",
    title: "Дослідження процесу обкочування валів",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15771.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 118,
    name: "Сердюк О.В, Олексюк Б.І., Корчинський О.А.",
    title:
      "Вплив технологічних параметрів на шорсткість поверхні при суміщеній обробці",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16082.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 119,
    name: "Сердюк О.В, Ільченко В.І. Федорчук М.А.",
    title:
      "Напруження в поверхневому шарі металу при обкочуванні роликом із гвинтовою робочою поверхнею",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16092.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 120,
    name: "Олександра Вікторівна Рижих, Олег Володимирович Піонткевич",
    title: "Нейронні мережі для САПР",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15169.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 121,
    name: "Артем Дмитрович Барановський, Андрій Миколайович Гуцалюк, Богдан Вікторович Василишен, Олег Володимирович Піонткевич",
    title:
      "Покращення фізико-механічних характеристик елементів приводу подрібнювача за допомогою CAD/CAE-систем",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15983.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 122,
    name: "Олег Володимирович Піонткевич, Олег Макарович Мироненко, Наталія Степанівна Семічаснова, Олександр Володимирович Калінніков, Максим Іванович Котик, Ольга Олександрівна Завальнюк, Артьом Вікторович Таранік",
    title:
      "Розроблення комплекту конструкторської документації на контейнери із саморозвантажуванням",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16069.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 123,
    name: "І. С. Мицик",
    title: "Перспективні види кінематики для 3D-принтерів",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/14985.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 125,
    name: "Н. С. Семічаснова, М. Є. Соколовський",
    title: "Вітроенергетичні установки як альтернативні джерела енергії",
    description:
      "Матеріали LІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2022), Вінниця, 31 травня – 1 червня 2022 р. Електрон. текст. дані. – 2022. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/16157.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 126,
    name: "Leonid Polishchuk, Leonid Kozlov, Yuri Burennikov, Vasil Strutinkiy, Valerii Kravchuk.",
    title:
      "Application of hydraulic automation equipment for the efficiency enhancement of the operation elements of the mobile machinery.",
    description:
      "Informatics Control Measurement in Economy and Environment Protection., n 2 (2019), p. 72 -78, https://www.scopus.com/authid/detail.uri?authorId=7103115784",
    year: 2019,
    type: "article",
  },
  {
    id: 127,
    name: "Бурєнніков Ю., Хом`юк І., Козлов Л., Бурєннікова Н., Хом`юк В.",
    title:
      "Інтегративний підхід до викладання спеціальних і фундамен­тальних дисциплін: сутність та напрями реалізації професійної адаптації студентів першого курсу машинобудів­них спеціаль­ностей",
    description:
      "Нова педагогічна думка. 2023. № 2(114). С. 97-111. DOI: https://doi.org/10.37026/2520-6427-2023-114-2-97-111 http://npd.roippo.org.ua/index.php/NPD/article/view/561 ",
    year: 2023,
    type: "article",
  },
  {
    id: 128,
    name: "Ковалевський С.В., Ковалевська О.С.,  Сидюк Д.М",
    title:
      "Резонансна діагностика виробничого простору генеративних систем штучного інтелекту  ",
    description:
      "Міжнародний науково-технічний журнал «Штучний інтелект». «Фізико-математичні та технічні науки». – 2023. – № 2(96). –  С.94-107 ",
    year: 2023,
    type: "article",
  },
  {
    id: 129,
    name: "S. Kovalevskyy,  D. Sydiuk",
    title:
      "Мodeling of diagnostics of engineering objects to optimize  their life cycle",
    description:
      "Математичне моделювання, Випуск 2 (47), 2022. – С.105-113.  DOI: 10.31319/2519-8106.2(47)2022.26966",
    year: 2022,
    type: "article",
  },
  {
    id: 130,
    name: "С. В. Ковалевський,  О. С. Ковалевська,  О. М. Коваленко",
    title:
      "Вплив широкосмугових мікроамплітудних вібрацій на процес штампування",
    description:
      "Обробка матеріалів тиском, (1(52), 118-125.  https://doi.org/10.37142/2076-2151/2023-1(52)118",
    year: 2076,
    type: "article",
  },
  {
    id: 131,
    name: "М. В.  Маркевич, Б. В. Василишен Л. Г. Козлов",
    title: "Мінітехніка: маленькі машини для робіт",
    description:
      "Конференція ВНТУ Молодь в науці: дослідження, проблеми, перспективи (МН-2023) ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 132,
    name: "KOZLOV Leonid , BURIENNIKOV Yurii, PYLIAVETS Volodymyr , KOTIK Sergii.",
    title: "Adaptive hydraulic system.",
    description:
      "EUROINVENT. Europeanexhibition of creativity and innovation. The “Gheorghe Asachi” Technical University of Iasi, Romania, May 11th - 12th, 2023 : Book of patents abstracts, 2023. Р. 265. ",
    year: 2023,
    type: "article",
  },
  {
    id: 133,
    name: "L. Kozlov, Yu. Buriennikov, P. Michailenko, I. Rusu, V. Pyliavets",
    title: "Adaptive hydraulic circuit for mobile machines",
    description:
      'Матеріали IІІ Міжнародної науково-технічної конференції "Перспективи розвитку машинобудування та транспорту-2023", 01-03 червень 2023 : Збірник тез доповідей. Вінниця: ВНТУ. – 2023. – С. 3. – Режим доступу: https://conferences.vntu.edu.ua/index.php/prmt/pmrt2023/paper/view/18394. ',
    year: 2023,
    type: "thesis",
  },
  {
    id: 134,
    name: "В. І. Перепелиця, Л. Г. Козлов.",
    title: "Аналіз способів синхронізації декількох гідроциліндрів",
    description:
      "Матеріали ІІІ Міжнародної науково-технічної конференція «Перспективи розвитку машинобудування та транспорту, – Вінниця : ВНТУ, 2023. Режим доступу: https://conferences.vntu.edu.ua/index. php/prmt/pmrt2023/paper/viewFile/18152/14990 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 135,
    name: "Сердюк О.В., Сухоруков С.І., Петров О.В.",
    title:
      "Підвищення експлуатаційних характеристик циліндричних поверхонь деталей",
    description:
      "Підвищення експлуатаційних характеристик циліндричних поверхонь деталей / Сердюк О.В., Сухоруков С.І., Петров О.В. // III Міжнародна науково-технічна конференція «Перспективи розвитку машинобудування та транспорту», 01-03 червня 2023 р. – м. Вінниця. – 2023. – 389-390 с. ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 136,
    name: "Біліченко В., Петров О., Косарук О.",
    title:
      "Стан та перспективи розвитку реалізації дуальної форми здобуття освіти у Вінницькому національному технічному університеті в період 2021-2023 рр.",
    description:
      "Стан та перспективи розвитку реалізації дуальної форми здобуття освіти у Вінницькому національному технічному університеті в період 2021-2023 рр. Дуальна форма здобуття освіти: підсумки запровадження пілотного проєкту у закладах вищої та фахової передвищої освіти України : збірник матеріалів Міжнародної науково-практичної конференції, 23 листопада 2023 р., Науково-методичний центр ВФПО. Київ, 2023. С. 17-19. ",
    year: 2021,
    type: "thesis",
  },
  {
    id: 137,
    name: "О.В. Петров, Я.В Молчанов, А.Ю. Фарафон, В.О. Трегубов",
    title:
      "Врахування явища стиснення робочої рідини в загальній системі податливості гідроприводу",
    description:
      "Врахування явища стиснення робочої рідини в загальній системі податливості гідроприводу / О.В. Петров, Я.В Молчанов, А.Ю. Фарафон, В.О. Трегубов // Молодь в технічних науках: дослідження, проблеми, перспективи (МТН-2023). Матеріали міжнародної Інтернет-конференції, м. Вінниця, 15.10.2023-20.05.2024: тези доповідей. – 2023. https://conferences.vntu.edu.ua/index.php/mn/mn2024/paper/view/19743",
    year: 2023,
    type: "thesis",
  },
  {
    id: 138,
    name: "Л. Г. Козлов, Ю. А. Бурєнніков, Petrica Vizurianu",
    title: "Мехатронна гідросистема з адаптивним регулятором",
    description:
      "Матеріали LIІ науково-технічної конференції підрозділів ВНТУ, Вінниця, 21-23 червня 2023 р. : збірник доповідей. – Електрон. текст. дані (PDF: 0,56 Мб). – Вінниця : ВНТУ, 2023. – С. 2821-2825. https://press.vntu.edu.ua/index.php/vntu/catalog/view/788/1373/2632-1",
    year: 2023,
    type: "thesis",
  },
  {
    id: 139,
    name: "Perepelytsia V., Kozlov L., Buriennikov Iu., Burennikova N., Kozlov S., Rusu O.",
    title:
      "Optimization of hydraulic drives for synchronizing the working movements of the machine for automated brick production",
    description:
      "The 11th International Conference on «Modern Manufacturing Technologies in Industrial Engineering». Book of Abstracts, June 14th – 17th 2023, Continental Forum Hotel, Bucharest, Romania. Р. 114 https://iq.vntu.edu.ua/method/getfile.php?fname=135213.pdf&x=1",
    year: 2023,
    type: "article",
  },
  {
    id: 140,
    name: "L. Kozlov, Yu. Buriennikov, V. Pyliavets, S. Kotik",
    title: "Adaptive hydraulic system",
    description:
      "EUROINVENT. Europeanexhibition of creativity and innovation, Romania, May 11th - 12th, 2023 : Book of patents abstracts. – 2023. – Р. 265. https://www.euroinvent.org/cat/EUROINVENT_2023.pdf",
    year: 2023,
    type: "article",
  },
  {
    id: 141,
    name: "Ю. А. Бурєнніков, Л. Г. Козлов, В. В. Савуляк, Д. О. Лозинський, Н. С. Семічаснова",
    title:
      "Підвищення ефективності викладання дисциплін «Вступ до фаху» і дисциплін з інформаційних технологій на машинобудівних спеціальностях",
    description:
      "Матеріали LII науково- технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2023) : збірник доповідей [ Електронний ресурс ]. – Вінниця : ВНТУ, 2023. – ( PDF, 2826 – 2829 с. ). https://press.vntu.edu.ua/index.php/vntu/catalog/view/788/1373/2632-1",
    year: 2023,
    type: "thesis",
  },
  {
    id: 142,
    name: "А. Г. Буда, Ю. А. Бурєнніков",
    title:
      "Комплексний підхід викладання навчальних дисциплін для студентів машинобудівних спеціальностей",
    description:
      "Матеріали LІІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2023). Вінниця, 21-23 червня 2023 р. Електрон. текст. дані. 2023. https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2023/paper/view/17676",
    year: 2023,
    type: "thesis",
  },
  {
    id: 143,
    name: "Репінський С. В., Дерібо О. В., Остапчук Я. Ю.",
    title:
      "Системи автоматизованого проектування (САПР) заготовок деталей машин",
    description:
      "Матеріали LІІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2023). Вінниця, 21-23 червня 2023 р. Електрон. текст. дані. 2023. https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2023/paper/view/18736.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 144,
    name: "Polishchuk L., Gromaszek K., Hmara O., Piontkevych O.",
    title:
      "The influence of the reserve power of the hydraulic drive on its static and dynamic characteristics",
    description:
      'Матеріали IІІ Міжнародної науково-технічної конференції "Перспективи розвитку машинобудування та транспорту-2023", 01-03 червень 2023: Збірник тез доповідей. Вінниця: ВНТУ. – 2023. – С. 2. https://conferences.vntu.edu.ua/index.php/prmt/pmrt2023/paper/view/18260',
    year: 2023,
    type: "thesis",
  },
  {
    id: 145,
    name: "Ницимайло В. О., Піонткевич О. В.",
    title:
      "Інженерний аналіз конструкцій полиць холодильного обладнання для зменшення їх собівартості",
    description:
      'Матеріали IІІ Міжнародної науково-технічної конференції "Перспективи розвитку машинобудування та транспорту-2023", 01-03 червень 2023 : Збірник тез доповідей. Вінниця: ВНТУ. – 2023. – С. 2 https://conferences.vntu.edu.ua/index.php/prmt/pmrt2023/paper/view/18268',
    year: 2023,
    type: "thesis",
  },
  {
    id: 146,
    name: "Савуляк В. В.",
    title:
      "Виготовлення вісесиметричних деталей холодним листовим штампуванням",
    description:
      "Матеріали конференції «Перспективи розвитку машинобудування та транспорту» (1-3 червня 2023 р): збірник доповідей [ Електронний ресурс ]. – Вінниця : ВНТУ, 2023. Режим доступу https://conferences.vntu.edu.ua/index.php/prmt/pmrt2023/paper/view/18197/15035.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 147,
    name: "Солецький О. А., Воловий К. О., Кузьменко П. А., Літвін А. М., Піонткевич О. В.",
    title: "Підбір шпинделя для фрезерно-гравірувального верстата з ЧПК",
    description:
      "Матеріали LIІ науково-технічної конференції підрозділів ВНТУ, Вінниця, 21-23 червня 2023 р. – Електрон. текст. дані. – 2023 https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2023/paper/view/17390",
    year: 2023,
    type: "thesis",
  },
  {
    id: 148,
    name: "Буткалюк І. В., Гуцалюк А. М., Василишен Б. В., Піонткевич О. В.",
    title: "CAD/CAE аналіз елементів фрезерно-гравірувального верстата з ЧПК",
    description:
      "Матеріали LIІ науково-технічної конференції підрозділів ВНТУ, Вінниця, 21-23 червня 2023 р. – Електрон. текст. дані. – 2023 https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2023/paper/view/17391",
    year: 2023,
    type: "thesis",
  },
  {
    id: 149,
    name: "Дерібо О. В., Гарболінський О. В., Мицик І. С.",
    title:
      "До питання визначення складових мінімального проміжного припуску для попередньої обробки головних отворів у литих заготовках деталей типу «Фланець»",
    description:
      "LІІ науково-технічна конференція підрозділів Вінницького національного технічного університету (НТКП ВНТУ), Вінниця, 21 червня – 23 червня 2023 р.  Електронний ресурс: https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2023/paper/view/18759/15543.",
    year: 2023,
    type: "thesis",
  },
  {
    id: 150,
    name: "Баранов В. А., Сухоруков С. І.",
    title:
      "Напрямки покращення процесу пошарового локального деформування листових заготовок",
    description:
      "Перспективи розвитку машинобудування та транспорту-2023 (01-03 червня 2023 р.). ",
    year: 2023,
    type: "article",
  },
  {
    id: 151,
    name: "Рибін Є.В., Сухоруков С.І.",
    title: "Сучасні методи отримання литих заготовок високої точності",
    description:
      "Матеріали LII Науково-технічній конференції факультету машинобудування та транспорту (21-23 червня 2023 р.) ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 152,
    name: "Лозінський Д.О., Бакалєйнік  Н.Д., Кавецький О.І.",
    title:
      "Гідропривод мобільної машини для вантажно-розвантажувальних робіт: ",
    description:
      "матеріали Всеукраїнської науково-практичної інтернет-конференції студентів, аспірантів та молодих науковців Молодь в науці: дослідження, проблеми, перспективи (МН-2023), м. Вінниця, вересень  2022 р. Вінниця, 2022.  https://conferences.vntu.edu.ua/index.php/mn/mn2023/paper/download/16844/14035",
    year: 2023,
    type: "thesis",
  },
  {
    id: 153,
    name: "Лозінський Д.О.,  Кавецький О.І.",
    title:
      "Дослідження гідроприводу мобільної машини для вантажно-розвантажувальних робіт",
    description:
      "Матеріали ІІІ Міжнародної науково-технічної конференції ПЕРСПЕКТИВИ РОЗВИТКУ МАШИНОБУДУВАННЯ ТА ТРАНСПОРТУ. ПМРТ – 2023, м. Вінниця, травень  2023 р. Вінниця, 2023.   https://conferences.vntu.edu.ua/index.php/prmt/pmrt2023/paper/view/18072",
    year: 2023,
    type: "thesis",
  },
  {
    id: 154,
    name: "Лозінський Д.О.,  Гончарук К.І., Гончарук М.К., Метельний О.Д., Гаврилюк В.В.",
    title:
      "Застосування промислових роботів для вирішення задач технологічного виробництва",
    description:
      "матеріали Всеукраїнської науково-практичної інтернет-конференції студентів, аспірантів та молодих науковців Молодь в науці: дослідження, проблеми, перспективи (МН-2024), м. Вінниця, вересень  2023 р. Вінниця, 2023.  https://conferences.vntu.edu.ua/index.php/mn/mn2024/paper/view /19685",
    year: 2024,
    type: "thesis",
  },
  {
    id: 155,
    name: "Древетняк С.А.,  Ковалевський С.В.",
    title:
      "Розробка оптимальної такелажної системи для виробів важкого машинобудування",
    description:
      "Збірник наукових праць Міжнародної молодіжної науково-технічної конференції  «Молода наука – роботизація і нано-технології сучасного машинобудування» 12-14 квітня 2023 р. / за заг. ред. С. В. Ковалевського, д-ра техн. наук., проф. –: ДДМА, 2023. – С. 87–90 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 156,
    name: "Дук В.Г.,  Ковалевський С.В.",
    title:
      "Розробка і обґрунтування технологічних рекомендацій з вдосконалення складання багатоелементних конструкцій технологічної системи (на прикладі багатолезових фрез)",
    description:
      "Збірник наукових праць Міжнародної молодіжної науково-технічної конференції  «Молода наука – роботизація і нано-технології сучасного машинобудування» 12-14 квітня 2023 р. / за заг. ред. С. В. Ковалевського, д-ра техн. наук., проф. –: ДДМА, 2023. – С. 90–96 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 157,
    name: "Зубарєв П.В., Ковалевський С.В.",
    title:
      "Магнітно резонансне об’ємне зміцнення робочих поверхонь зубчастих передач",
    description:
      "Збірник наукових праць Міжнародної молодіжної науково-технічної конференції  «Молода наука – роботизація і нано-технології сучасного машинобудування» 12-14 квітня 2023 р. / за заг. ред. С. В. Ковалевського, д-ра техн. наук., проф. –: ДДМА, 2023. – С. 106-109 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 158,
    name: "Ковалевський С.В., Репченко В.С.,  Тур Т.М., Мироненко М.О. ",
    title: "Перспективні напрямки процесів інноватики в машинобудуванні",
    description:
      "Збірник наукових праць Міжнародної молодіжної науково-технічної конференції  «Молода наука – роботизація і нано-технології сучасного машинобудування» 12-14 квітня 2023 р. / за заг. ред. С. В. Ковалевського, д-ра техн. наук., проф. –: ДДМА, 2023. – С. 147-151 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 159,
    name: "Ковалевський С.В., Сидюк Д.М.",
    title:
      "Особливості переходу машинобудування на п’ятому етапі промислової революції",
    description:
      "Збірник наукових праць Міжнародної молодіжної науково-технічної конференції  «Молода наука – роботизація і нано-технології сучасного машинобудування» 12-14 квітня 2023 р. / за заг. ред. С. В. Ковалевського, д-ра техн. наук., проф. –: ДДМА, 2023. – С. 151-156 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 160,
    name: "Ковалевський С.В., Ковалевська О.С., Коваленко О.М.,  Сидюк Д.М. ",
    title:
      "Моделювання розпізнавання багатовимірних об’єктів в векторному просторі",
    description:
      "Комплексне забезпечення якості технологічних процесів та систем (КЗЯТПС – 2023) : матеріали тез доповідей XІІІ Міжнародної науково-практичної конференції  (м. Чернігів, 25–26 травня 2023 р.) : у 2 т. / Національний університет «Чернігівська  політехніка» [та ін.]. – Чернігів :  НУ «Чернігівська політехніка», 2023. – Т. 1. – С.122-125 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 161,
    name: "Ковалевський С.В., Ковалевська О.С., Сидюк Д.М.,  Коваленко О.М. ",
    title:
      "Концептуальні завдання машинобудування для забезпечення життєвого циклу виробу",
    description:
      "Комплексне забезпечення якості технологічних процесів та систем (КЗЯТПС – 2023) : матеріали тез доповідей XІІІ Міжнародної науково-практичної конференції  (м. Чернігів, 25–26 травня 2023 р.) : у 2 т. / Національний університет «Чернігівська  політехніка» [та ін.]. – Чернігів :  НУ «Чернігівська політехніка», 2023. – Т. 1. – С.125-127 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 162,
    name: "Сергій Ковалевський, Олена Ковалевська, Дар’я Сидюк",
    title:
      "Модель оптимізації процесів життєвого  ціклу  об’єктів машинобудування",
    description:
      "Перспективи розвитку машинобудування та транспорту – 2023: матеріали тез доповідей ІІІ Міжнародної науково-технічної конференції. м.Вінниця, ВНТУ, 2023. – С.68-71 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 163,
    name: "Сергій Ковалевський, Олена Ковалевська",
    title: "Інноваційні напрямки розвитку механоскладального  виробництва",
    description:
      "Перспективи розвитку машинобудування та транспорту – 2023: матеріали тез доповідей ІІІ Міжнародної науково-технічної конференції, м.Вінниця, ВНТУ, 2023. – С.72-75 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 164,
    name: "Ковалевський С.В., Ковалевська О.С., Сидюк Д.М., Коваленко О.М.",
    title: "Магнітно-резонансна обробка листових немагнитних матеріалів",
    description:
      "«Теоретичні и експериментальні дослідження  в сучасних технологіях матеріалознавства и машинобудування»: матеріали тез доповідей 9-й Міжнародної науково-технічної конференції 30 - 31 мая, 2023 р. м.Луцьк, ЛНТУ., 2023. – С.139-141 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 165,
    name: "Ковалевський С.В., Ковалевська О.С., Сидюк Д.М., Коваленко О.М.",
    title:
      "Підвищення якості групування ріжучих пластин для багатолезових фрез",
    description:
      "«Теоретичні и експериментальні дослідження  в сучасних технологіях матеріалознавства и машинобудування»: матеріали тез доповідей 9-й Міжнародної науково-технічної конференції 30 - 31 мая, 2023 р. м.Луцьк, ЛНТУ., 2023. – С.141-143 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 166,
    name: "Ковалевський С.В., Ковалевська О.С.,  Сидюк Д.М.",
    title: "Комплексна оптимізація технологічних процесів",
    description:
      "Матеріали XXI Міжнародної науково-технічної конференції 01 — 20-22 червня 2023 року / за заг. ред. В. Д. Ковальова. — Краматорськ-Тернопіль: ДДМА, 2022. C.56-57 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 167,
    name: "Ковалевський С.В., Ковалевська О.С., Коваленко О.М., Сидюк Д.М.",
    title: "Моделювання впливу мікровібрацій на процеси тертя",
    description:
      "Матеріали XXI Міжнародної науково-технічної конференції 01 — 20-22 червня 2023 року / за заг. ред. В. Д. Ковальова. — Краматорськ-Тернопіль: ДДМА, 2022. C.58-59  ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 168,
    name: "Ковалевський С.В.",
    title:
      "Інноваційна освіта як чинник відбудови і розвитку регіонів і міст України",
    description:
      "Важливість використання сучасних технологій в освіті : матеріали Міжнародної науково-практичної конференції / Міжнародний гуманітарний дослідницький центр (Дніпро, 15 вересня 2023 р). Research Europe, 2023 – С.31-33 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 169,
    name: "Ковалевський С.В.",
    title:
      "Інтеграція штучного інтелекту в освітній процес: напрямки та перспективи розвитку",
    description:
      "Матеріали V Всеукраїнського відкритого науково-практичного онлайн-форуму «Інноваційні трансформації в сучасній освіті: виклики, реалії, стратегії» 20 вересня 2023 року. м. Київ. .Інститут модернізації змісту освіти. 2023. С. 214-216 ",
    year: 2023,
    type: "article",
  },
  {
    id: 170,
    name: "Sergiy Kovalevskyy, Yuliia Volodchenko",
    title:
      "ARTIFICIAL INTELLIGENCE FOR THE RECOVERY AND DEVELOPMENT OF UKRAINE UNDER LIMITED RESOURCES",
    description:
      'Матеріали III Міжнародного наукового сателітного симпозіуму "Інтелектуальні рішення". -  Taras Shevchenko National University of Kyiv, 27-28 September 27-28, 2023, Kyiv/ - C.192-194 ',
    year: 2023,
    type: "article",
  },
  {
    id: 171,
    name: "Ковалевський С.В., Ковалевська О.С., Сидюк Д.М ",
    title:
      "Взаємодія академічних установ, наукових дослідників та бізнес-спільноти для створення інноваційних рішень і підвищення їх конкурентоспроможності",
    description:
      "Integration of Education, Science and Business in Modern Environment: Summer Debates: Proceedings of the 5th International Scientific and Practical Internet Conference (стор. 259-264). Dnipro, Ukraine, 570 p. ",
    year: 2023,
    type: "article",
  },
  {
    id: 172,
    name: "С. В. Ковалевський,  О. С. Ковалевська,  Д. М. Сидюк",
    title:
      "Віртуальна реальність як інструмент оптимізації життєвого ціклу виробів машинобудування",
    description:
      "Нові та нетрадиційні технології в ресурсо- та енергозбереженні: Матеріали міжнародної науково-технічної конференції, 6-7 грудня 2023 р., м. Одеса. – Одеса: 2023. –С.129-131 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 173,
    name: "Ковалевський С.В.,  Сидюк Д.М.,  Дятленко В.І.",
    title: "Методичний підхід до класифікації та оцінки технологічних систем",
    description:
      "Нові та нетрадиційні технології в ресурсо- та енергозбереженні: Матеріали міжнародної науково-технічної конференції, 6-7 грудня 2023 р., м. Одеса. – Одеса: 2023. –С.129-131-133 ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 174,
    name: "Ковалевський С.В.",
    title:
      "Еталонна модель комунікативної ефективності для вдосконалення навчального процесу",
    description:
      "Освіта як індикатор суспільного розвитку: теоретикопрактичний курс: матеріали всеукраїнського науковопедагогічного підвищення кваліфікації, 2 жовтня – 12 листопада  2023 року. – Львів – Торунь : Liha-Pres, 2023. –С.19-23 ",
    year: 2023,
    type: "article",
  },
  {
    id: 175,
    name: "Ковалевський С.В.",
    title: "Роль штучного інтелекту в покращенні освітнього процесу",
    description:
      "Наука та освіта в умовах воєнного часу : матеріали Міжнародної науково-практичної конференції / Міжнародний гуманітарний дослідницький центр (Дніпро, 28 жовтня 2023 р). Research Europe, 2023.  С.35-37. ",
    year: 2023,
    type: "thesis",
  },
  {
    id: 176,
    name: "Дерібо О. В., Дусанюк Ж.П., Репінський С. В., Підлубний В. А.",
    title:
      "Порівняльний аналіз показників точності, що забезпечуються тонким розточуванням на токарному верстаті з ЧПК [Текст]",
    description:
      "/ О. В. Дерібо, Ж.П. Дусанюк, С. В. Репінський, В. А. Підлубний // Збірник тез доповідей Міжнародної науково-технічної інтернет-конференції «Гідро- та пневмоприводи машин – сучасні досягнення та застосування», Вінниця, 27-29 грудня 2018 р. – Вінниця : ВНТУ, 2019. – С. 150-153.",
    year: 2019,
    type: "thesis",
  },
  {
    id: 177,
    name: "Лозінський Д. О.",
    title:
      "Дослідження пропорційного незалежного керування потоками на вході та гідродвигуна в мобільних машинах [Електронний ресурс]",
    description:
      " / Д. О. Лозінський // Матеріали XLVII науково-технічної конференції підрозділів ВНТУ, Вінниця, 14-23 березня 2018 р. – Електрон. текст. дані. – 2018. – Режим доступу: https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2018/paper/view/5350.",
    year: 2018,
    type: "thesis",
  },
  {
    id: 178,
    name: "Бурєнніков Ю. А., Дусанюк Ж. П., Репінський С. В., Олексій М. С.",
    title:
      "Вплив точності виготовлення заготовки деталі типу втулка опорна на технологічну собівартість обробки поверхонь [Текст]",
    description:
      "/ Ю. А. Бурєнніков, Ж. П. Дусанюк, С. В. Репінський, М. С. Олексій // Збірник тез доповідей Міжнародної науково-технічної інтернет-конференції «Гідро- та пневмоприводи машин – сучасні досягнення та застосування», Вінниця, 27-29 грудня 2018 р. – Вінниця : ВНТУ, 2019. – С. 146-149.",
    year: 2018,
    type: "thesis",
  },
  {
    id: 179,
    name: "Наточій Л. І.",
    title:
      "Застосування CAD/CAM-cистем для ті типу втулка опорна навлення деталі фланець [Електронний ресурс]",
    description:
      "/ Л. І. Наточій: наук. кер: Д. О. Лозінський // Матеріали L науково-технічної конференції підрозділів ВНТУ, Вінниця, 10-12 березня 2021 р. – Електрон. текст. дані. – 2021. – Режим доступу: https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2021/paper/view/12282.",
    year: 2021,
    type: "thesis",
  },
  {
    id: 180,
    name: "Лозінський Д. О., Лозінська М. М., Коломійчук А. В., Хоменко І. А.",
    title:
      "Дослідження характеристик пропорційного електрогідравлічного розподільника з незалежним керуванням потоків та стежною системою [Текст]",
    description:
      "/ Д. О. Лозінський, М. М. Лозінська, А. В. Коломійчук, І. А. Хоменко // Збірник тез доповідей Міжнародної науково-технічної інтернет-конференції «Гідро- та пневмоприводи машин – сучасні досягнення та застосування», Вінниця, 27-29 грудня 2018 р. – Вінниця : ВНТУ, 2018. – С. 110-111.",
    year: 2018,
    type: "thesis",
  },
  {
    id: 181,
    name: "Білінський А. М., Мазур П. І., Лозінський Д. О.",
    title:
      "Застосування CAD/CAE-систем для оптимізації конструкції деталей та вузлів машин [Електронний ресурс]",
    description:
      "/ А. М. Білінський, П. І. Мазур, Д. О. Лозінський // Матеріали XLVI науково-технічної конференції підрозділів ВНТУ, Вінниця, 22-24 березня 2017 р. - Електрон. текст. дані. - 2017. - Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2017/paper/view/2893.",
    year: 2017,
    type: "thesis",
  },
  {
    id: 182,
    name: "Трегубов В. О., Ницимайло В. О., Орленко В. Р.",
    title:
      "Удосконалення конструкції секції гідророзподільника на основі інженерного аналізу [Електронний ресур]",
    description:
      "/ В. О. Трегубов, В. О. Ницимайло, В. Р. Орленко // Матеріали Міжнародної науково-практичної інтернет-конференції: «Молодь в науці: дослідження, проблеми, перспективи»(МН-2024), Збірник тез доповідей. Вінниця: ВНТУ. – 2023. – С. 3. – Режим доступу: https://conferences.vntu.edu.ua/index.php/mn/mn2024/paper/view/19738.",
    year: 2024,
    type: "thesis",
  },
  {
    id: 183,
    name: "Барановський А. Д., Гуцалюк А. М., Василишен Б. В., Піонткевич О. В.",
    title:
      "Покращення фізико-механічних характеристик елементів приводу подрібнювача за допомогою CAD/CAE-систем [Електронний ресурс]",
    description:
      "/ А. Д. Барановський, А. М. Гуцалюк, Б. В. Василишен, О. В. Піонткевич // Матеріали LI науково-технічної конференції підрозділів ВНТУ, Вінниця, 31 травня 2022 р. – Електрон. текст. дані. – 2022. – Режим доступу: https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15983.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 184,
    name: "Рижих О. В., Піонткевич О. В.",
    title: "Нейронні мережі для САПР [Електронний ресурс]",
    description:
      "/ О. В. Рижих, О. В. Піонткевич // Матеріали LI науково-технічної конференції підрозділів ВНТУ, Вінниця, 31 травня 2022 р. – Електрон. текст. дані. – 2022. – Режим доступу: https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2022/paper/view/15169.",
    year: 2022,
    type: "thesis",
  },
  {
    id: 185,
    name: "Піонткевич О. В., Сухоруков С. І., Барановський А. Д., Віштак І. І.",
    title:
      "Перспективи використання лазерних технологій в машинобудуванні [Електронний ресурс]",
    description:
      "/ О. В. Піонткевич, С. І. Сухоруков, А. Д. Барановський, І. І. Віштак // Матеріали L науково-технічної конференції підрозділів ВНТУ, Вінниця, 10-12 березня 2021 р. – Електрон. текст. дані. – 2021. – Режим доступу: https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2021/paper/view/12271.",
    year: 2021,
    type: "thesis",
  },
  {
    id: 186,
    name: "Ю. А. Бурєнніков, Ж. П. Дусанюк, С. В. Репінський, В. В. Фабіянов",
    title:
      "Дослідження величини сумарної похибки обробки площини деталі типу «Кришка РТ.05.002» на багатоцільовому верстаті з ЧПК [Електронний ресурс]",
    description:
      " / Ю. А. Бурєнніков, Ж. П. Дусанюк, С. В. Репінський, В. В. Фабіянов // Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2019)», м. Вінниця, 11-30 травня 2019 р. – 2019. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2019/paper/view/6250/5172.",
    year: 2019,
    type: "thesis",
  },
  {
    id: 187,
    name: "Ж. П. Дусанюк, С. В. Репінський, Я. А. Молчанов, Д. А. Тарабанський",
    title:
      "Автоматизація розрахунку кількості обладнання на дільниці (в цеху) механічної обробки заготовок деталей [Електронний ресурс]",
    description:
      " / Ж. П. Дусанюк, С. В. Репінський, Я. А. Молчанов, Д. А. Тарабанський // Матеріали XLVIIІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2019), Вінниця, 13-15 березня 2019 р. – Електрон. текст. дані. – 2019. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2019/paper/view/7103.",
    year: 2019,
    type: "thesis",
  },
  {
    id: 188,
    name: "Ж. П. Дусанюк, С. В. Репінський, О. М. Онищук, О. І. Савчук",
    title:
      "Вплив глибини різання при розточуванні отвору на вибір оптимальних режимів обробки [Електронний ресурс]",
    description:
      " / Ж. П. Дусанюк, С. В. Репінський, О. М. Онищук, О. І. Савчук // Матеріали XLVIIІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2019), Вінниця, 13-15 березня 2019 р. – Електрон. текст. дані. – 2019. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2019/paper/view/7104.",
    year: 2019,
    type: "thesis",
  },
  {
    id: 189,
    name: "О. В. Дерібо, Ж. П. Дусанюк, С. В. Репінський, Р. Ю. Басистюк, К. Ю. Казарян",
    title:
      "Порівняльний аналіз точності обробки отвору остаточним розточуванням на токарному верстаті з ручним та числовим програмним керуванням [Електронний ресурс]",
    description:
      " / О. В. Дерібо, Ж. П. Дусанюк, С. В. Репінський, Р. Ю. Басистюк, К. Ю. Казарян // Матеріали XLVIIІ науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2019), Вінниця, 13-15 березня 2019 р. – Електрон. текст. дані. – 2019. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2019/paper/view/7410.",
    year: 2019,
    type: "thesis",
  },
  {
    id: 190,
    name: "Л. Г. Козлов, Ж. П. Дусанюк, С. В. Репінський, А. М. Збегерський",
    title:
      "Використання 3D-моделювання заготовок деталей машин для визначення показників їх ефективності та вибору оптимального варіанту [Електронний ресурс]",
    description:
      " / Л. Г. Козлов, Ж. П. Дусанюк, С. В. Репінський, А. М. Збегерський // Тези доповідей Всеукраїнської науково-практичної інтернет-конференції студентів, аспірантів та молодих науковців «Молодь в науці: дослідження, проблеми, перспективи (МН-2020)», Вінниця, 01-15 травня 2020 р. – 2020. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2020/paper/viewFile/8425/7134.",
    year: 2020,
    type: "thesis",
  },
  {
    id: 191,
    name: "О. В. Дерібо, Ж. П. Дусанюк, С. В. Репінський, А. О. Захарченко",
    title:
      "Вплив геометричних параметрів різця на оптимальні режими різання при розточуванні",
    description:
      " / О. В. Дерібо, Ж. П. Дусанюк, С. В. Репінський, А. О. Захарченко // Збірник тез доповідей ІV-ої Міжнародної науково-технічної інтернет-конференції «Гідро- та пневмоприводи машин – сучасні досягнення та застосування», Вінниця, 16-27 грудня 2019 р. – Вінниця : ВНТУ, 2020. – С. 115–118. Режим доступу : http://ctam.vntu.edu.ua/index.php?option=com_content&view=article&id=199:0-stijkist-mekhatronnoji-gidrosistemi-na-osnovi-regulovanogo-nasosa-26&catid=52&Itemid=760&lang=ua.",
    year: 2020,
    type: "thesis",
  },
  {
    id: 192,
    name: "Ж. П. Дусанюк, С. В. Репінський, Я. А. Молчанов, М. В. Цекот",
    title:
      "Використання прикладної програми для розрахунку кількості працівників на дільниці (в цеху) механічної обробки заготовок деталей",
    description:
      " / Ж. П. Дусанюк, С. В. Репінський, Я. А. Молчанов, М. В. Цекот // Збірник тез доповідей ІV-ої Міжнародної науково-технічної інтернет-конференції «Гідро- та пневмоприводи машин – сучасні досягнення та застосування», Вінниця, 16-27 грудня 2019 р. – Вінниця : ВНТУ, 2020. – С. 120–123. Режим доступу : http://ctam.vntu.edu.ua/index.php?option=com_content&view=article&id=196:0-stijkist-mekhatronnoji-gidrosistemi-na-osnovi-regulovanogo-nasosa-23&catid=52&Itemid=760&lang=ua.",
    year: 2020,
    type: "thesis",
  },
  {
    id: 193,
    name: "С. В. Репінський, О. В. Паславська, В. Ю. Губницький, Д. С. Зарудняк",
    title:
      "Розробка математичної моделі системи керування гідроприводом змішувального барабана автобетонозмішувача [Електронний ресурс]",
    description:
      " / С. В. Репінський, О. В. Паславська, В. Ю. Губницький, Д. С. Зарудняк // Тези доповідей Всеукраїнської науково-практичної інтернет-конференції «Молодь в науці: дослідження, проблеми, перспективи (МН-2021)», м. Вінниця, 01-14 травня 2021 р. – 2021. – Режим доступу : https://conferences.vntu.edu.ua/index.php/mn/mn2021/paper/view/11001.",
    year: 2021,
    type: "thesis",
  },
  {
    id: 194,
    name: "С. В. Репінський, В. Ю. Губницький, А. О. Подолян, О. Ю. Ткачук",
    title:
      "Аналіз систем керування приводом барабана автобетонозмішувача [Електронний ресурс]",
    description:
      " / С. В. Репінський, В. Ю. Губницький, А. О. Подолян, О. Ю. Ткачук // Матеріали L науково-технічної конференції підрозділів Вінницького національного технічного університету (НТКП ВНТУ–2021), Вінниця, 10-12 березня 2021 р. – Електрон. текст. дані. – 2021. – Режим доступу : https://conferences.vntu.edu.ua/index.php/all-fmt/all-fmt-2021/paper/view/12720.",
    year: 2021,
    type: "thesis",
  },
  {
    id: 195,
    name: "С. В. Репінський, В. Ю. Губницький",
    title:
      "Репінський С. В. Імітаційне дослідження впливу параметрів електрогідравлічного регулятора на динамічні характеристики аксіально-поршневого регульованого насоса",
    description:
      " / С. В. Репінський, В. Ю. Губницький // Матеріали ІІ-ої Міжнародної науково-технічної конференції «Перспективи розвитку машинобудування та транспорту – 2021», Вінниця, 13-15 травня 2021 р. – Вінниця : ВНТУ, 2021. – С. 418–419. Режим доступу : https://conferences.vntu.edu.ua/index.php/prmt/pmrt2021/paper/view/13355.",
    year: 2021,
    type: "thesis",
  },
  {
    id: 196,
    name: "С. В. Репінський, О. В. Дерібо, В. Г. Лозовський, Ю. А. Семенюк",
    title:
      "Розробка алгоритму та комп'ютерної програми для розрахунку собівартості заготовки, виготовленої литтям",
    description:
      " / С. В. Репінський, О. В. Дерібо, В. Г. Лозовський, Ю. А. Семенюк // Матеріали ІІІ-ої Міжнародної науково-технічної конференції «Перспективи розвитку машинобудування та транспорту – 2023», Вінниця, 01-03 червня 2023 р. – Вінниця : ВНТУ, 2023. – С. 376–378. Режим доступу : https://conferences.vntu.edu.ua/index.php/prmt/pmrt2023/paper/view/18294",
    year: 2023,
    type: "thesis",
  },
];

export default articles;
