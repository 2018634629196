import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Main from "../views/Main";
import Structure from "../views/Structure";
import History from "../views/History";
import Science from "../views/Science";
import StudyProcess from "../views/StudyProcess";
import News from "../views/News";
import ForEntrant from "../views/ForEntrant";
import Phd from "../views/Phd";
import Graduates from "../views/Graduates";
import Practice from "../views/Practice";
import Master from "../views/Master";
import CommunicationWithProduction from "../views/CommunicationWithProduction";
import LaboratoryBase from "../views/LaboratoryBase";
import CertificationTraining from "../views/CertificationTraining";
import InternationalCooperation from "../views/InternationalCooperation";


function AppRouter() {
  return (
    <Switch>
      <Route path="/about">
        <Main />
      </Route>
      <Route path="/structure">
        <Structure />
      </Route>
      <Route path="/history">
        <History />
      </Route>
      <Route path="/science">
        <Science />
      </Route>
      <Route path="/study">
        <StudyProcess />
      </Route>
      <Route path="/news">
        <News />
      </Route>
      <Route path="/forEntrant">
        <ForEntrant />
      </Route>
      <Route path="/forMasters">
        <Master />
      </Route>
      <Route path="/forPhd">
        <Phd />
      </Route>
      <Route path="/practice">
        <Practice />
      </Route>
      <Route path="/laboratory">
        <LaboratoryBase />
      </Route>

      <Route path="/communication">
        <CommunicationWithProduction />
      </Route>

      <Route path="/certification">
        <CertificationTraining />
      </Route>      

      <Route path="/internationalсooperation">
        <InternationalCooperation />
      </Route>      

      <Route path="/graduates">
        <Graduates />
      </Route>

      <Redirect to="/forEntrant" />
    </Switch>
  );
}
export default AppRouter;
