import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Paper } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import articles from "../dataLists/articles";
import scientificGroups, {
  ScientificGroupsImages,
} from "../dataLists/ScientificGroups";
import ArticleCard from "../components/ArticleCard";
import sciencePageImage from "../img/sciencePage.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    width: "100vw",
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: "100%",
    },
    textAlign: "justify",
  },
  header: {
    backgroundColor: "#B5D3DE",
  },
  subHeader: {
    backgroundColor: "#a8c6d0",
    fontSize: 17,
    //lineHeight: "normal",
  },
  listItem: {
    fontSize: 14,
    marginLeft: "-35px",
  },

  text: {
    fontSize: 14,
    //lineHeight: "normal",
  },
  paperPadding: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
  photocontainer: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Apply when screen size is small or below (mobile)
    },
  },
  middleTitle: {
    textAlign: "left",
    fontSize: 20,
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
}));
function Science() {
  const classes = useStyles();
  const ArticleItems = articles
    .sort((a, b) => b.year - a.year)
    .map((item, i) => {
      return (
        <ArticleCard
          key={i}
          name={item.name}
          title={item.title}
          description={item.description}
        />
      );
    });

  const scientificGroupsList = scientificGroups.map((item, i) => {
    return (
      <Paper
        elevation={1}
        variant="outlined"
        className={classes.paperPadding}
        sx={{ padding: "0px" }}
      >
        <ol className={classes.listItem}>
          <Typography
            className={classes.subHeader}
            style={{ marginTop: "-15px", marginBottom: "15px" }}
            variant="h6"
          >
            {item.id}. {item.title}
          </Typography>
          <Typography
            variant="h6"
            className={classes.text}
            style={{ marginBottom: "10px" }}
          >
            Керівники: {item.manager}
          </Typography>
          {item.description}
          <lo key={item.id}>
            <a target="_blank" rel="noreferrer" href={item.planLink}>
              План гуртка
            </a>{" "}
            {ScientificGroupsImages.some((a) => a.GroupId === item.id) && (
              <>
                <Typography variant="h6" className={classes.text}>
                  {" "}
                  Робота гуртка:
                </Typography>
                {ScientificGroupsImages.filter(
                  (a) => a.GroupId === item.id
                ).map((groupImage) => {
                  return (
                    <lo>
                      <div className={classes.photocontainer}>
                        <img
                          className={classes.photo}
                          src={groupImage.link}
                          style={{ maxWidth: "300px" }}
                          alt=""
                        />
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        {groupImage.description}{" "}
                      </Typography>
                    </lo>
                  );
                })}
              </>
            )}
          </lo>
        </ol>
      </Paper>
    );
  });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.media}
        component="img"
        alt=""
        image={sciencePageImage}
        studyPage
        title=""
      />

      <Typography paragraph>
        Наукова робота фахівців кафедри ТАМ здійснюється за двома напрямами:
        «Моделювання, синтез та покращення техніко-економічних показників
        гідросистем та гідроагрегатів технологічних та мобільних робочих машин»,
        а також «Розвиток прикладної теорії деформованості суцільних і пористих
        тіл при складному навантаженні та удосконалення на цій основі
        технологічних процесів обробки металів тиском». Перший напрям включає:
        проектування випробувальної техніки та дослідження агрегатів систем
        гідроприводів; дослідження хвильових процесів в довгих трубопроводах
        мобільних робочих машин; підвищення рівномірності руху робочих органів
        технологічних машин з гідроприводом; проектування та дослідження
        розподільної та запірної гідроапаратури. До другого напряму відносяться
        дослідження процесів обробки металів тиском на основі феноменологічної
        теорії деформуємості.
      </Typography>
      <Typography className={classes.header} variant="h6">
        ОСНОВНІ НАУКОВІ НАПРЯМИ РОЗВИТКУ КАФЕДРИ
      </Typography>
      <Typography paragraph>
        Серед основних напрямів розвитку кафедри ТАМ слід відзначити такі:
      </Typography>
      <Typography paragraph>
        <ListItem>
          1. Механотронні системи управління мобільних робочих машин (проф.
          Козлов Л.Г., доцент Піонткевич О.В., ст. викл. Семичаснова Н.С.,
          інж. Товкач А.О., інж. Ковальчук В.А.).
        </ListItem>
        <ListItem>
          2. Підвищення ефективності регульованих гідромеханізмів технологічних
          та мобільних машин (проф. Бурєнніков Ю.А.).
        </ListItem>
        <ListItem>
          3. Пластичне деформування листових матеріалів та циліндричних
          заготовок методами штампування, обкочування та поперечно-клиновій
          прокатки (доц. Савуляк В.В., доц. Сухоруков С.І., доц. Сердюк О.В.).
        </ListItem>
        <ListItem>
          4. Електрогідравлічні стежні приводи технологічних та випробувальних
          машин (доц. Дерібо О.В.)
        </ListItem>
        <ListItem>
          5. Пропорційні системи керування енергоощадними гідроприводами
          мобільних робочих машин (доц. Петров О.В., доц. Лозінський Д.О., доц.
          Репінський С.В.).
        </ListItem>
      </Typography>
      <Typography className={classes.header} variant="h6">
        МІЖНАРОДНА НАУКОВА СПІВПРАЦЯ
      </Typography>
      <Typography paragraph>
        На кафедрі ТАМ підтримуються зв’язки з закордонними виробничими та
        навчальними установами, зокрема: австрійською фірмою «FESTO», німецькою
        фірмою «SIEMENS», технічним університетом імені Георга Асахі міста Яси
        (Румунія).
      </Typography>
      <Typography className={classes.header} variant="h6">
        НАУКОВІ ЗВ’ЯЗКИ КАФЕДРИ
      </Typography>
      <Typography paragraph>
        На кафедрі ТАМ ведуться спільні науково-дослідні роботи з багатьма
        провідними науковими і навчальними установами України: Інститут
        надтвердих матеріалів ім. В.М. Бакуля НАН України, Інститут проблем
        матеріалознавства ім. І.М. Францевича НАН України, Донбаська
        машинобудівна академія (м. Краматорськ), Національна металургійна
        академія (м. Дніпропетровськ), Національний технічний університет
        України «КПІ», Луцький національний технічний університет, Одеський
        національний політехнічний університет, Хмельницький національний
        університет, Кременчуцький національний університет ім. М.В.
        Остроградського, Чернігівський державний технологічний університет.
      </Typography>
      <Typography className={classes.header} variant="h6">
        ДИСЕРТАЦІЇ ВИКОНАНІ НА КАФЕДРІ ТАМ
      </Typography>
      <ListItem>
        У 2023 р. була захищена дисертация на здобуття наукового ступеня ступеня
        доктора філософії Перепелицею В’ячеславом Ігоровичем на тему «Система
        гідравлічних приводів синхронізації робочих рухів автоматизованої
        установки для формування заготовок цегли» науковий керівник - д.т.н.,
        проф. Л.Г. Козлов
      </ListItem>
      <ListItem>
        У 2021 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук Пилявцем Володимиром Георгієвичем на тему
        «Адаптивна гідросистема з перехресним зв’язком для мобільної машини.»
        науковий керівник - д.т.н., проф. Л.Г. Козлов
      </ListItem>
      <ListItem>
        У 2019 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук Піонткевичем Олегом Володимировичем на тему
        «Підвищення ефективності багаторежимного гідроприводу фронтального
        навантажувача.», науковий керівник - д.т.н., проф. Л.Г. Козлов
      </ListItem>
      <ListItem>
        У 2019 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук Сердюк Ольгою Валентинівною на тему
        «Удосконалення технології обкочування роликом із застосуванням методів
        теорії деформовності», науковий керівник - д.т.н., професор Сивак І.О.
      </ListItem>
      <ListItem>
        У 2015 р. була захищена дисертация на здобуття наукового ступеня доктора
        технічних наук Козловим Леонідом Геннадійовичем на тему «Наукові основи
        розробки систем гідроприводів маніпуляторів з адаптивними регуляторами
        на основі нейромереж для мобільних робочих машин», науковий консультант-
        д.т.н., проф. В.Б. Струтинський.
      </ListItem>
      <ListItem>
        У 2011 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук аспірантом кафедри Репінським С.В. на тему
        "Система керування аксіально-поршневого регульованого насоса з
        профільованим вікном золотника комбінованого регулятора подачі ",
        керівник – керівник – к.т.н., професор Бурєнніков Ю.А.
      </ListItem>
      <ListItem>
        У 2010 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук аспірантом кафедри Петровим О.В. на тему
        "Гідропривод чутливий до навантаження на базі мультирежимного
        гідророзподільника", керівник – к.т.н., професор Козлов Л.Г.
      </ListItem>
      <ListItem>
        У 2010 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук аспірантом кафедри Лозінским Д.О. на тему
        "Пропорційний електрогідравлічний розподільник з незалежним керуванням
        потоків для мобільних машин", керівник – к.т.н., професор Козлов Л.Г.
      </ListItem>
      <ListItem>
        {" "}
        2010 р. була захищена дисертация на здобуття наукового ступеня кандидата
        технічних наук аспірантом кафедри Сухоруковим С.І. на тему
        "Удосконалення технології плоскої поперечно-клинової прокатки
        ступінчастих циліндричних заготовок", керівник – д.т.н., професор Сивак
        І.О.
      </ListItem>
      <ListItem>
        У 2007 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук здобувачем кафедри Коцюбівскою К.І. на тему
        "Підвищення ефективності процесів холодного поперечного видавлювання
        осесиметричних заготовок з фланцем за рахунок використання протитиску",
        керівник – к.т.н., професор Бурєнніков Ю.А.
      </ListItem>
      <ListItem>
        У 2007 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук аспірантом кафедри Савуляком В.В. на тему
        "Холодне формування заготовок з мінімальними радіусами кривизни з
        важкодеформівних листових матеріалів", керівник – д.т.н., професор Сивак
        І.О.
      </ListItem>
      <ListItem>
        У 2000 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук здобувачем кафедри Козловим Л.Г. на тему
        "Удосконалення системи керування гідроприводів з LS-регулюванням",
        керівник – к.т.н., професор Бурєнніков Ю.А.
      </ListItem>
      <ListItem>
        У 1999 р. була захищена дисертация на здобуття наукового ступеня
        кандидата технічних наук здобувачем кафедри Гунько І.В. на тему:
        "Груповий гідропривод роботи органів машин сільськогосподарського
        призначення з послідовним з’єднанням гідромоторів", керівник – к.т.н.,
        доцент Іванов М.І.
      </ListItem>




      <Typography className={classes.header} variant="h6">
      Науково-технічні конференції
      </Typography>
      <ListItem>
      Міжнародна науково-технічна інтернет-конференція 17-20 березня 2020 р. «Гідро- та пневмоприводи машин –сучасні досягнення та застосування» -  <a href="http://tam.vntu.edu.ua/data/conference/gpp_2020.pdf">
       тези доповідей
        </a>
      </ListItem>     



      <Typography
        className={classes.header}
        variant="h6"
        style={{ marginTop: "10px" }}
      >
        Наукові гуртки
      </Typography>

      <ol className={classes.listItem}>
        <a href="https://vntu.edu.ua/uploads/Pol_st_gurt_2023.pdf">
          Положення про студентський науковий гурток ВНТУ
        </a>
      </ol>
      <ol className={classes.listItem}>
        <a href="http://tam.vntu.edu.ua/data/scientificGroups/order_042023.pdf">
          Розпорядження про роботу студентських наукових гуртків від 18.04.2023р
        </a>
      </ol>
      <ol className={classes.listItem}>
      <a href="http://tam.vntu.edu.ua/data/scientificGroups/order_092023.pdf">
        Розпорядження про роботу студентських наукових гуртків від 12.09.2023р
        </a>
      </ol>

      {scientificGroupsList}

      <Typography className={classes.header} variant="h6">
        Наукові праці викладачів кафедри
      </Typography>

      <ol>{ArticleItems}</ol>
    </main>
  );
}

export default Science;
