const books = [
    {
        "id": "1",
        "title": "Ю.А.Бурєнніков, І.А.Немировський, Л.Г.Козлов.Гідравліка та гідро-пневмопривод.Навчальний посібник з грифом МОН – Вінниця: ВНТУ, 2012. – 270 с.",
    },
    {
        "id": "2",
        "title": "Ю.А.Бурєнніков, А.А.Кашканов, В.М.Ребедайло.Автомобілі: робочі процеси та основи розрахунку.Навчальний посібник з грифом МОН – Вінниця: ВНТУ, 2012. –  283с.",
    },
    {
        "id": "3",
        "title": "Ю.А.Бурєнніков, І.О.Сивак, С.І.Сухоруков.Нові матеріали та композити.Навчальний посібник з грифом МОН – Вінниця: ВНТУ, 2012. – 158с.",
    },
    {
        "id": "4",
        "title": "Інформатика.Курсове проектування для студентів машинобудівних спеціальностей: навчальний посібник / Козлов Л.Г., Петров О.В., Семічаснова Н.С., Коцюбівська К.І.– Вінниця: ВНТУ, 2012. – 184 с.",
    },
    {
        "id": "5",
        "title": "Теоретичні основи технології виробництва деталей та складання машин.Частина 1: практикум / Дерібо О.В., Дусанюк Ж.П., Репінський С.В.– Вінниця: ВНТУ, 2010. – 114 с.",
    },
    {
        "id": "6",
        "title": "Проектування та виробництво заготовок деталей машин.Литі заготовки: навчальний посібник./ Дусанюк Ж.П., Шиліна О.П., Репінський С.В.та ін.– Вінниця: ВНТУ, 2009. - 199 с.",
    },
    {
        "id": "7",
        "title": "Бурєнніков Ю.А., Кашканов А.А., Ребедайло В.М.Рухомий склад автомобільного транспорту: робочі процеси та елементи розрахунку.Навчальний посібник.– Вінниця: ВНТУ, 2009. – 267 с.",
    },
    {
        "id": "8",
        "title": "Дерібо О.В., Дусанюк Ж.П., Мироненко О.М., Пурдик В.П., Репінський С.В., Черноволик Г.О.Теоретичні основи технології виробництва деталей та складання машин Лабораторний практикум.– Вінниця: ВНТУ, 2006. – 118 с.",
    },
    {
        "id": "9",
        "title": "Дусанюк Ж.П., Сивак І.О., Дусанюк С.В., Репінський С.В.Проектування та виробництво заготовок деталей машин.Гаряче об’ємне штампування.Навчальний посібник.– Вінниця: ВНТУ, 2006. – 106 с.",
    },
    {
        "id": "10",
        "title": "Дусанюк Ж.П., Семичаснова Н.С., Дусанюк С.В., Карватко О.В.Науково-дослідна, конструкторсько-технологічна практика.Частина 2 Навчальний посібник.- Вінниця: ВНТУ, 2006. - 80 с.",
    },
    {
        "id": "11",
        "title": "Дусанюк Ж.П., Семічаснова Н.С., Дусанюк С.В., Карватко О.В.Науково-дослідна, конструкторсько-технологічна практика Частина 1. Навчальний посібник.- Вінниця: ВНТУ, 2006. - 87с.",
    },
    {
        "id": "12",
        "title": "Огородніков В.А., Сивак І.О., Лєбєдєва Г.О.Опір матеріалів.Теоретичні відомості і контрольні завдання.Під загальною редакцією В.А.Огороднікова.Навчальний посібник.– Вінниця: ВНТУ, 2005. – 74 с.",
    },
]
export default books;



