import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";

import photo from "../img/Foto Kozlov.jpg";
import titleUpperImage from "../img/titlePage.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  text: {
    fontSize: 16,
  },
  bossPhoto: {
    height: 320,
  },
}));
function Main() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.media}
        component="img"
        alt=""
        image={titleUpperImage}
        title=""
      />
      <Box>
        <Grid container spacing={1}>
          <Grid item xs="auto">
            <img className={classes.bossPhoto} src={photo} alt="" />
          </Grid>
          <Grid item xs>
            <Typography paragraph>
            Кафедра ТАМ готує та випускає бакалаврів за спеціальністю 131 –
          "Прикладна механіка" (спеціалізація "Комп'ютеризовані технології та
          механотронні системи в машинобудуванні"), а також магістрів за
          спеціальністю 131 – "Прикладна механіка" ( спеціалізація - "Технології
          машинобудування"). На кафедрі здійснюється підготовка докторів
          філософії за спеціальностями: 131–"Прикладна механіка" та 05.03.05 –
          "Процеси та машини обробки тиском".&nbsp;
            </Typography>
          </Grid>
        </Grid>

        {/* <Typography paragraph>
          Кафедра ТАМ готує та випускає бакалаврів за спеціальністю 131 –
          "Прикладна механіка" (спеціалізація "Комп'ютеризовані технології та
          механотронні системи в машинобудуванні"), а також магістрів за
          спеціальністю 131 – "Прикладна механіка" ( спеціалізація - "Технології
          машинобудування"). На кафедрі здійснюється підготовка докторів
          філософії за спеціальностями: 131–"Прикладна механіка" та 05.03.05 –
          "Процеси та машини обробки тиском".&nbsp;
        </Typography> */}
        <Typography paragraph>
          З 2016 р. кафедру очолює д.т.н., професор Козлов Леонід Геннадійович.
        </Typography>
        <Typography paragraph>
          На сьогодні викладачі кафедри викладають понад 50 навчальних дисциплін
          для студентів всіх курсів, керують курсовим та дипломним
          проектуванням. Основними науковими напрямами кафедри є «Моделювання та
          синтез гідросистем та гідроагрегатів технологічних машин», а також
          «Розвиток прикладної теорії деформованості суцільних i пористих тіл
          при складному навантаженні та удосконалення на цій основі
          технологічних процесів обробки металів тиском». Перший з цих напрямів
          започаткований ще у вересні 1966 року з ініціативи професора Ігоря
          Абрамовича Немировського. При кафедрі працюють: галузева
          науково-дослідна лабораторія «Гідроагрегат» та науково-дослідна
          лабораторія: «БОРЕКС-гідравліка» (науковий керівник – проф. Ю.А.
          Бурєнніков, зав. лабораторії – проф. Л.Г. Козлов).
        </Typography>
        <Typography paragraph>
          Розробки науковців кафедри зі значним економічним ефектом впроваджені
          на заводах «Таджикгідроагрегат» (м. Душанбе), НВО «Арсенал» (м. Київ),
          Калинівському машинобудівному заводі (Вінницька обл.) та багатьох
          інших. За участю колективу ГНДЛ «Гідроагрегат» під редакцією I.А.
          Немировського видано каталог гідроагрегатів для сільськогосподарських
          машин п’яти томах.
        </Typography>
        <Typography paragraph>
          Науково-дослідна лабораторія «Пластичність» успішно співпрацює із
          підприємствами КНВО «ФОРТ» (м. Вінниця), установами НАН України:
          Інститутом надтвердих матеріалів ім. В.М. Бакуля, Інститутом проблем
          міцності та багатьма технічними університетами з міст Києва,
          Краматорська та ін. Науково дослідна лабораторія «Борекс-гідравліка»
          починаючи з 1993 року ефективно співпрацює з підприємствами України у
          галузі створення енергоощадних гідроприводів та гідроагрегатів для
          мобільних машин. Результати наукової та навчально-методичної роботи
          кафедри відображені у 8 монографіях (І.А. Немировський, І.О.Сивак,
          Ю.А.Бурєнніков, О.В. Петров), сотнях наукових статей, авторських
          свідоцтв та патентів. Розробки науковців кафедри відзначені медалями
          на науково-технічних виставках в Україні (автори розробок –
          І.А.Немировський, Ю.А. Бурєнніков, Л.Г. Козлов, О.В.Дерібо, Ю.І.
          Муляр, В.О. Чорний, В.П. Пурдик). За досягнення у винахідницькій
          діяльності І.А. Немировський відзначений почесним званням –
          «Заслужений винахідник», а В.П. Пурдик – «Кращий винахідник України».
          На міжнародних виставках винаходів (2000р. – м. Будапешт, 2003р., та
          2017р. – м. Яси) отримано три золоті та одну срібну медалі (Ю.А.
          Бурєнніков, Л.Г. Козлов, С.В. Репінський, Н.С. Семічаснова). Для
          навчання студентів та перепідготовки інженерно-технічних працівників
          промислових підприємств Подільського регіону в галузі автоматизації
          виробничих процесів на базі кафедри ТАМ у 2001 році створений
          навчально-науковий центр «ВНТУ-ФЕСТО» (науковий керівник – проф. Ю.А
          Бурєнніков., директор – проф. Л.Г. Козлов, технічний директор-доц.
          Д.О. Лозінський). Спільно із всесвітньовідомою компанією FESTO центр
          обладнано сучасним німецьким та австрійським обладнанням.
        </Typography>
        <Typography paragraph>
          Кафедрою заключені угоди про створення філій на провідних
          підприємствах м. Вінниця:
        </Typography>

        <ol className={classes.text}>
          <li>ВАТ "Вінницький агрегатний завод".</li>
          <li>Учбово-виробниче підприємство "УТОС".</li>
          <li>Казенне науково-виробниче підприємство "ФОРТ".</li>
          <li>ТОВ "Вінницький авіаремонтний завод".</li>
          <li>ТОВ ТМ «Термія» - ПРАТ Вінницький завод «Маяк».</li>
        </ol>

        <Typography paragraph>
          При кафедрі працюють три науково-дослідні лабораторії:
        </Typography>
        <ol className={classes.text}>
          <li>
            галузева науково-дослідна лабораторія „Гідроагрегат” (ауд.1114а)
          </li>
          <li>лабораторія пластичних деформацій (ауд.1114а)</li>
          <li>науково-дослідна лабораторія „Борекс-гідравліка” (ауд.1116)</li>
        </ol>

        <Typography paragraph>
          За кафедрою ТАМ закріплено 13 приміщень загальною площею понад 800
          кв.м., які повністю забезпечують навчальний процес кафедри, зокрема,
          це навчальні лабораторії:
        </Typography>
        <ol className={classes.text}>
          <li>центр механотроніки „ВНТУ-ФЕСТО”</li>
          <li>
            лабораторія автоматизації процесів машинобудування (ауд. 1104)
          </li>
          <li>лабораторії технології машинобудування (ауд. 1105,1106)</li>
          <li>
            лабораторія взаємозамінності, стандартизації та управління якістю
            продукції (ауд. 1221)
          </li>
        </ol>
      </Box>
    </main>
  );
}

export default Main;
