import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VNTU from "../img/VNTU.png";
import FMT from "../img/FMT.png";
import FB from "../img/FB_group.png";

const useStyles = makeStyles((theme) => ({
  block: {
    direction: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    position: "sticky",
    rigth: 0,
    top: 90,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  toolbar: {
    marginTop: 90,
  },

  root: {
    minWidth: 275,
    margin: 10,
    textAlign: "center",
    //  boxShadow: "100px",
  },
  cardWrapper: {
    minWidth: 275,
    margin: 10,
    textAlign: "center",

    "&:hover": {
      transform: "scaleX(0.97)", //transform: "scaleX(1.1)"
    },
  },
}));

const Partners = () => {
  const classes = useStyles();

  return (
    <div style={{ maxWidth: "300px" }}>
      <div className={classes.toolbar} />

      <Grid container className={classes.block}>
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h7">КОРИСНІ ПОСИЛАННЯ</Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <div className={classes.cardWrapper}>
            <a target="_blank" rel="noreferrer" href="https://vntu.edu.ua/">
              <img
                src={VNTU}
                alt=""
                style={{ width: "100%", maxWidth: "250px" }}
              />
            </a>
          </div>
        </Card>
        <Card className={classes.root}>
          <div className={classes.cardWrapper}>
            <a target="_blank" rel="noreferrer" href="https://fmt.vntu.edu.ua/">
              <img
                src={FMT}
                alt=""
                style={{ width: "100%", maxWidth: "250px" }}
              />
            </a>
          </div>
        </Card>
        <Card className={classes.root}>
          <div className={classes.cardWrapper}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/Прикладна-механіка-436645816708119"
            >
              <img
                src={FB}
                alt=""
                style={{ width: "100%", maxWidth: "250px" }}
              />
            </a>
          </div>
        </Card>

        <Card className={classes.root}>
          <div className={classes.cardWrapper}>
            <a href="https://clustrmaps.com/site/1bphk" title="Visit tracker">
              <img
                src="//clustrmaps.com/map_v2.png?cl=337ab7&w=275&t=tt&d=5u1cJ3_h97lF9btcZZYYZgW6b_t2moEFa1Q1emibI34&co=ffffff&ct=09050c"
                style={{ width: "100%", maxWidth: "250px", minHheight: "200px"}}
              />
            </a>
          </div>
        </Card>
      </Grid>
    </div>
  );
};
export default Partners;
