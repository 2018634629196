const masterComponents = [
  {
    id: "1",
    title: "Філософія науки і техніки",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_1.pdf",
  },
  {
    id: "2",
    title: "Інноваційні та психологічні аспекти сучасної освіти",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_2.pdf",
  },
  {
    id: "3",
    title: "Ділова іноземна мова",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_3.pdf",
  },
  {
    id: "4",
    title: "Економічне обґрунтування інноваційних рішень в галузі механічної інженерії",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_4.pdf",
  },
  {
    id: "5",
    title: "Сучасні технології в машинобудуванні",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_5.pdf",
  },
  {
    id: "6",
    title:
      "Механотроніка",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_6.pdf",
  },
  {
    id: "7",
    title: "Технології автоматизованого машинобудування",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_7.pdf",
  },
  {
    id: "8",
    title: "Комп’ютерний аналіз моделей та систем в машинобудуванні",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_8.pdf",
  },
  {
    id: "9",
    title: "Комп'ютерне проектування технологічного оснащення",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_9.pdf",
  },
  {
    id: "10",
    title: "Роботизовані технологічні комплекси",
    href1: "http://tam.vntu.edu.ua/images/master/m_ok_10.pdf",
  },
  {
    id: "11",
    title: "Переддипломна практика (програма)",
    href1: "https://tam.vntu.edu.ua/images/master/practicemasters.pdf",
  },
  {
    id: "12",
    title: "Виконання магістерської кваліфікаційної роботи (методичні вказівки)",
    href1: "http://tam.vntu.edu.ua/images/master/MV_2023.pdf",
  }, 

];
export default masterComponents;
