const lessonsMagistr = [
    {
        "id": "1",
        "title": "Автоматизація виробництва в машинобудуванні",
        "href1": "http://repinskiy.vk.vntu.edu.ua/file/AVM/3a1cc8a901872ccf20dcd0453b2dc8eb.pdf",
        "href2": "http://kozlov.vk.vntu.edu.ua/",
        "href3": "http://kozlov.vk.vntu.edu.ua/",
        "Dform": "( ДФН, ",
        "Zform": " ЗФН )",
    },
    {
        "id": "2",
        "title": "Комп'ютерне проектування технологічного оснащення",
        "href1": "http://tam.vntu.edu.ua/images/%D0%9A%D0%9F%D0%A2%D0%9E_2016_%D0%9D%D0%9F.pdf",
        "href2": "http://petrov.vk.vntu.edu.ua/stud",
        "href3": "http://sevostyanov.vk.vntu.edu.ua/navch",
        "Dform": "( ДФН )",
        "Zform": "",
    },
    {
        "id": "3",
        "title": "Методологія та організіція наукових досліджень",
        "href1": "http://sevostyanov.vk.vntu.edu.ua/file/6e68c186efe90d20de3cae7b05e1b08f.doc",
        "href2": "http://kozlov.vk.vntu.edu.ua/navch",
        "href3": "",
        "Dform": "( ДФН )",
        "Zform": "",
    },
    {
        "id": "4",
        "title": "Мехатронні системи керування приводами машин",
        "href1": "",
        "href2": "http://kozlov.vk.vntu.edu.ua/navch",
        "href3": "",
        "Dform": "( ДФН )",
        "Zform": "",
    },
    {
        "id": "5",
        "title": "Роботизованні технологічні комплекси та САП верстатів з ЧПК",
        "href1": "http://lozinskyi.vk.vntu.edu.ua/file/2018/4db065ba9861ee42b13d267b83f5728b.pdf",
        "href2": "http://lozinskyi.vk.vntu.edu.ua/navch",
        "href3": "",
        "Dform": "( ДФН )",
        "Zform": "",
    },
    {
        "id": "6",
        "title": "САПР технологічних процесів та підготовки машинобудівного виробництва",
        "href1": "",
        "href2": "http://serdyuk.vk.vntu.edu.ua/",
        "href3": "",
        "Dform": "( ДФН )",
        "Zform": "",
    },
    {
        "id": "7",
        "title": "Сучасні інформаційні технології в науці та освіті",
        "href1": "",
        "href2": "http://sukhorukov.vk.vntu.edu.ua/",
        "href3": "http://sukhorukov.vk.vntu.edu.ua/",
        "Dform": "( ДФН, ",
        "Zform": " ЗФН )",
    },
    {
        "id": "8",
        "title": "Теоретичні та експериментальні дослідження в машинобудуванні",
        "href1": "http://deribo.vk.vntu.edu.ua/file/123/b7848221c2812f7d83222282ad455f1a.pdf",
        "href2": "http://deribo.vk.vntu.edu.ua/",
        "href3": "",
        "Dform": "( ДФН )",
        "Zform": "",
    },
    {
        "id": "9",
        "title": "Технологічні методи виготовлення та підвищення якостей деталей машин",
        "href1": "http://tam.vntu.edu.ua/images/%D0%9D%D0%9F%D0%94_13_%D0%A2%D0%9C%D0%92%D1%82%D0%B0%D0%9F%D0%AF%D0%94%D0%9C_Word.PDF",
        "href2": "http://savulyakv.vk.vntu.edu.ua/",
        "href3": "http://savulyakv.vk.vntu.edu.ua/",
        "Dform": "( ДФН, ",
        "Zform": " ЗФН )",
    },

]
export default lessonsMagistr;