const ScientificGroups = [
  {
    id: 1,
    title: "Мехатроніка в машинобудуванні. Дослідження та інженерні рішення",
    manager: "Козлов Л.Г., Бурєнніков Ю.А., Ковальчук В.А.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_01.pdf",
    description:
      "Студентський науковий гурток створено з метою отримання та розвитку у студентів додаткових знань та умінь на підставі роботи з керівником і самостійного пізнання принципів  інтеграції знань з таких  областей, як прецизійна механіка і комп'ютерне керування, інформаційні технології і мікроелектроніка.  Гурткова діяльність дозволяє  студенту краще зрозуміти освітню програму   «Комп’ютеризовані технології та мехатронні системи в машинобудуванні», отримати навички науково-дослідної діяльності.",
  },
  {
    id: 2,
    title:
      "Інженерні і наукові задачі технології виготовлення деталей на верстатах з ЧПК",
    manager: "Дерібо О.В., Сердюк О.В., Мироненко О.М.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_02.pdf",
    description:
      "Студентський науковий гурток «Інженерні і наукові задачі технології виготовлення деталей на верстатах з ЧПК» створено з метою набуття та розвитку у студентів додаткових знань та умінь завдяки спільній роботі з керівниками і самостійного пізнання особливостей технологічної підготовки сучасного машинобудівного виробництва для виготовлення якісних виробів з використанням обладнання з ЧПК. Спільна діяльність колективу гуртка дозволяє студенту краще зрозуміти освітню програму «Комп’ютеризовані технології та механотронні системи в машинобудуванні» та отримати навички науково-дослідної діяльності.",
  },
  {
    id: 3,
    title: "Проєктування та інженерні дослідження в машинобудуванні",
    manager: "Піонткевич, О. В., Сердюк О.В.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_03_.pdf",
    description:
      "Студентський науковий гурток «Проєктування та інженерні дослідження в машинобудуванні» створено з метою отримання та розвитку у студентів додаткових знань та умінь на підставі роботи з керівником і самостійного пізнання особливостей комп'ютерного моделювання, імітаційного дослідження, інженерного аналізу та використання цих умінь і навичок при проєктуванні нових виробів та машин в машинобудуванні. Спільна діяльність колективу гуртка   дозволяє   студенту   краще   зрозуміти   освітню   програму «Комп’ютеризовані технології та механотронні системи в машинобудуванні» та отримати навички науково-дослідної діяльності.",
  },
  {
    id: 4,
    title:
      "Комп’ютеризовані системи для програмування верстатів з ЧПК. Наукові підходи та інженерна практика",
    manager: "Лозінський Д.О.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_04.pdf",
    description:
      "Студентський науковий гурток «Комп’ютеризовані системи для програмування верстатів з ЧПК. Наукові підходи та інженерна практика» створено з метою отримання та розвитку у студентів додаткових знань та умінь на підставі роботи з керівником і самостійного пізнання принципів  інтеграції знань з таких  областей знань, як 2Д та 3Д моделювання, застосування комп’ютеризованих систем та відповідного програмного забезпечення для роботи з верстами з ЧПК.  Гурткова діяльність сприяє освоєнню та забезпечує поглиблення знань з відповідних освітніх компонентів освітньо-професійної програми «Комп’ютеризовані технології та мехатронні системи в машинобудуванні», а також отриманню навичок науково-дослідної діяльності.",
  },
  {
    id: 5,
    title: "Гідравліка в машинобудуванні. Наука і практика",
    manager: "Репінський С.В.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_05.pdf",
    description:
      "Студентський науковий гурток «Гідравліка в машинобудуванні. Наука і практика» призначений для поглибленого розгляду різноманітних аспектів з області гідравліки та гідродинаміки, зокрема принципів роботи та конструкції гідравлічних машин, гідроприводів та гідроавтоматики, їх взаємодії з іншими механізмами та системами, а також сучасні тенденції розвитку цих технологій. Учасники гуртка матимуть можливість проводити дослідження в цій галузі, а також працювати з різноманітним обладнанням та програмним забезпеченням для моделювання та аналізу гідравлічних систем. Також будуть обговорюватися актуальні проблеми та виклики, що стоять перед гідравлічною промисловістю, та шляхи їх вирішення",
  },
  {
    id: 6,
    title:
      "CAD/CAE технології в машинобудуванні. Наукові та інженерні розробки",
    manager: "Савуляк В.В.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_06.pdf",
    description:
      "Студентський науковий гурток «CAD/CAE технології в машинобудуванні. Наукові та інженерні розробки» створено з метою отримання та розвитку у студентів додаткових знань та умінь на підставі роботи з керівником і самостійного пізнання принципів  інтеграції знань з таких областей, як міцність конструкцій та виробів і їх комп'ютерне моделювання.  Гурткова діяльність дозволяє  студенту краще зрозуміти освітню програму «Комп’ютеризовані технології та мехатронні системи в машинобудуванні», отримати навички науково-дослідної діяльності.",
  },
  {
    id: 7,
    title:
      "Дослідження сучасних інформаційно-комунікаційних технологій в машинобудуванні",
    manager: "Семічаснова Н.С.",
    planLink: "http://tam.vntu.edu.ua/data/scientificGroups/plan_07.pdf",
    description:
      "Студентський науковий гурток «Дослідження сучасних інформаційно-комунікаційних технологій в машинобудуванні» створено з метою: отримання та  розвитку у студентів  додаткових знань з основ інтернет-технологій, принципів функціонування комунікаційних систем в мережі Іnternet, що можуть бути успішно застосовані в інженерній практиці;  набуття практичних навичок користування основними сервісами Інтернету для їх застосування у складі інформаційної системи машинобудівних підприємств; формування наукових інтересів та навичок дослідницької роботи; оволодіння навичками ефективного використання Іnternet-ресурсів для пошуку інформації; оволодіння навичками систематизації та узагальнення отриманих результатів; розвитку навичок критичного сприйняття інформації, логічного та алгоритмічного мислення; отримання навичок командної роботи використовуючи Хмарні технології.",
  },
];

const ScientificGroupsImages = [
  {
    id: 1,
    GroupId: 1,
    link: "http://tam.vntu.edu.ua/data/scientificGroups/mecatronic_01.jpg",
    description:
      "Дослідження функціонування  моделі автоматизованої системи з елементами мехатроніки",
  },
  {
    id: 2,
    GroupId: 1,
    link: "http://tam.vntu.edu.ua/data/scientificGroups/mecatronic_02.jpg",
    description:
      "Дослідження роботизованого обладання з елементами мехатроніки",
  },
  {
    id: 3,
    GroupId: 1,
    link: "http://tam.vntu.edu.ua/data/scientificGroups/mecatronic_03.jpg",
    description:
      "Керівники наукового гуртка «Мехатроніка в машинобудуванні. Дослідження та інженерні рішення» професор, д.т.н. Козлов Л. Г., професор, к.т.н. Бурєнніков Ю. А.",
  },
  {
    id: 4,
    GroupId: 4,
    link: "http://tam.vntu.edu.ua/data/scientificGroups/camcnc_01.jpg",
    description:
      "Дослідження методик програмування верстатів з ЧПК за допомогою комп’ютеризованих системи",
  },
];

export default ScientificGroups;

export {ScientificGroups, ScientificGroupsImages};


