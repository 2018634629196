import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import forEntrantPageImage from "../img/forEntrantPage.png";

import teachers from "../dataLists/teachers";
import internships from "../dataLists/internship";
import { CardMedia, Paper } from "@material-ui/core";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  header: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },
  headerText: {
    width: "100%",
    marginBottom: 20,
    backgroundColor: "#B5D3DE",
    fontSize: "1rem",
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  root: {
    minWidth: 275,
    marginBottom: 20,
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
  listItem: {
    fontSize: 14,
    marginLeft: "-35px",
  },
}));

function CertificationTraining() {
  const classes = useStyles();
  const сertificationList = teachers
    .filter((t) =>
      internships.some((a) => a.teacherId === t.id && a.url !== "")
    )
    .map((item, i) => {
      return (
        <Paper
          elevation={1}
          variant="outlined"
          className={classes.paperPadding}
          sx={{ padding: "0px" }}
          id={item.id}
        >
          <ol className={classes.listItem}>
            <Typography
              className={classes.subHeader}
              style={{ marginTop: "-15px", marginBottom: "0px" }}
              variant="h6"
            >
              {i + 1}. {item.name}
            </Typography>
            <Typography className={classes.text}>
              Загальна кількість:{" "}
              {internships
                .filter((a) => a.teacherId === item.id)
                .reduce((acc, a) => acc + a.credits, 0)}{" "}
              кред.
            </Typography>
            <lo key={item.id}>
              <div
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                  padding: "10px",
                }}
              >
                {internships
                  .filter((a) => a.teacherId === item.id && a.url !== "")
                  .sort((a, b) => a.year - b.year)
                  .map((certificate, i) => {
                    return (
                      <ol className={classes.listItem}>
                        {i + 1}.{" "}
                        <a href={certificate.url} target="_blank">
                          {certificate.description}
                        </a>
                      </ol>
                    );
                  })}
              </div>
            </lo>
          </ol>
        </Paper>
      );
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <CardMedia
        className={classes.header}
        component="img"
        alt=""
        image={forEntrantPageImage}
        title=""
      />
      <div className="text">
        <Typography paragraph>
          Система підвищення кваліфікації забезпечує безперервне зростання
          науково-педагогічної кваліфікації викладачів та покращення якості
          підготовки здобувачів вищої освіти. Науково-педагогічні працівники
          кафедри регулярно проходять підвищення кваліфікації у відповідності зі
          складеними та затвердженими планами. У ВНТУ діє положення{" "}
          <a href="https://vntu.edu.ua/uploads/2020/polmiz.pdf" target="_blank">
            {" "}
            «Про підвищення кваліфікації НПП ВНТУ»
          </a>
          , спрямоване на вдосконалення професійної підготовки викладачів. На
          безкоштовній основі працює
          <a href="http://inomzn.vntu.edu.ua/?page_id=166" target="_blank">
            {" "}
            програма розвитку професійно-педагогічної компетентності НПП ВНТУ
          </a>
          , щорічно працює
          <a
            href="https://iq.vntu.edu.ua/fm/fdb/682/seminar/Plan_Pedmayst_2023_2024.pdf"
            target="_blank"
          >
            {" "}
            семінар підвищення педагогічної майстерності ВНТУ
          </a>
          . Науково-технічна бібліотека організовує власні семінари, а також
          колективні перегляди вебінарів, присвячених роботі з наукометричними
          базами, публікації результатів досліджень в провідних наукових
          виданнях та іншим актуальним питанням наукової діяльності. Університет
          забезпечує проведення наукових конференцій та круглих столів. Усі НПП
          ВНТУ проходять підвищення кваліфікації або стажування не менше 6
          кредитів за 5 років.
        </Typography>

        <Typography
          className={classes.headerText}
          variant="h6"
          style={{ marginTop: "10px" }}
        >
          Підвищення кваліфікації викладачів кафедри:
        </Typography>
        {сertificationList}
      </div>
    </main>
  );
}

export default CertificationTraining;
