import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import newsPageImage from "../img/newsPage.png";
import photoNews01 from "../img/img_news_01.jpg";
import photoNews210623 from "../img/img_news_210623.jpg";
import photoNews200623 from "../img/img_news_200623.jpg";
import photoNews031123 from "../img/img_news_031123.jpg";
import photoNews211123 from "../img/img_news_211123.jpg";
import photoNews181223 from "../img/img_news_181223.jpg";
import photoNews271223 from "../img/img_news_271223.jpg";
import photoNews281223 from "../img/img_news_281223.jpg";
import photoNews140923 from "../img/img_news_140923.jpg";
import photoNews301123 from "../img/img_news_301123.jpg";
import photoNews151123 from "../img/img_news_151123.jpg";
import photoNews251223 from "../img/img_news_251223.jpg";
import photoNews210223 from "../img/img_news_210223.jpg";
import photoNews221223 from "../img/img_doubleDiploma.jpg";
import photoNews261223 from "../img/img_news_261223.jpg";
import photoNews141223 from "../img/img_news_141223.jpg";
import photoNews020223 from "../img/img_news_020223.jpg";
import photoNews270324 from "../img/img_news_270324.jpg";
import photoNews270324_2 from "../img/img_news_270324_2.jpg";
import photoNews110424 from "../img/img_news_110424.jpg";
import photoNews060524 from "../img/img_news_060524.jpeg";

import photoNews160124 from "../img/Photo_Burennikov.jpg";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  header: {
    height: 200,
    width: "100%",
    marginBottom: 20,
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    textAlign: "justify",
  },
  root: {
    minWidth: 275,
    marginBottom: 20,
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
  photo: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    minWidth: "250px",
    height: "auto",
    margin: "auto",
    maxWidth: "500px",
  },
}));
function News() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <CardMedia
        className={classes.header}
        component="img"
        alt=""
        image={newsPageImage}
        title=""
      />
      <div className="text">
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Університет відзначив День науки в Україні
            </Typography>
            <Typography paragraph>23.05.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              23 травня у Вінницькому національному технічному університеті
              відбувся
              <a
                href="https://vntu.edu.ua/uk/news/universytet-vidznachyv-den-nauky-v-ukrayini-2799.html"
                target="_blank"
              >
                {" "}
                День відкритих дверей для майбутніх здобувачів вищої освіти!{" "}
              </a>
              На урочистостях відзначили кращих науковців університету. За
              особистий внесок у підготовку висококваліфікованих спеціалістів та
              з нагоди Дня науки нагрудний знак «Відмінник освіти України»
              отримав кандидат технічних наук, доцент, декан факультету
              машинобудування та транспорту Сергій Сухоруков. Грамоту від
              департаменту гуманітарної політики Вінницької обласної військової
              адміністрації отримав доктор технічних наук, професор, завідувач
              кафедри технологій та автоматизації машинобудування Леонід Козлов
            </Typography>
            <div className={classes.photocontainer} style={{ display: "flex" }}>
              <img
                className={classes.photo}
                src={
                  "https://vntu.edu.ua/sites/default/files/images/664ca00a6c5c3.jpg"
                }
                alt=""
              />

              <img
                className={classes.photo}
                src={
                  "https://vntu.edu.ua/sites/default/files/images/664ca012c611b.jpg"
                }
                alt=""
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              День відкритих дверей ВНТУ
            </Typography>
            <Typography paragraph>23.05.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              23 травня у Вінницькому національному технічному університеті
              відбувся
              <a
                href="https://vntu.edu.ua/uk/news/u-vntu-proyshov-den-vidkrytykh-dverey-2801.html"
                target="_blank"
              >
                {" "}
                День відкритих дверей для майбутніх здобувачів вищої освіти!{" "}
              </a>
              Зустріч була присвячена Правилам прийому для здобуття вищої освіти
              у ВНТУ – 2024. Ми щиро вдячні школярам та випускникам коледжів за
              те, що вони завітали до нас. Сподіваємося побачити їх вже
              студентами обраної ними спеціальності саме у Вінницькому
              національному технічному університеті. Щира зацікавленість
              вступників підтвердила доцільність проведення таких заходів і
              вкотре довела, що Вінницький національний технічний університет —
              це вибір кращих! Ми пишаємося нашими традиціями, досягненнями та
              студентами, які роблять ВНТУ одним із провідних закладів вищої
              освіти в Україні! ❤️
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={
                  "https://vntu.edu.ua/sites/default/files/images/664f50d598926_0.jpg"
                }
                alt=""
                //   style={{ maxWidth: "800px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Участь у благодійній акції
            </Typography>
            <Typography paragraph>06.05.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Студенти групи 2ПМ-22б Побережець Владислав, Лебідь Віталій,
              Харченко Михайло, Ящук Дмитро спеціальності Прикладна механіка,
              взяли участь у благодійній акції зі здачі крові на підтримку
              військовослужбовців. Цей надзвичайно важливий вчинок відображає в
              собі дух солідарності студентської спільноти, та безперечну
              відданість в допомозі тим, хто ризикує своїм життям захищаючи нашу
              країну.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews060524}
                alt=""
                //   style={{ maxWidth: "800px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Відкрита лекція для студентів, що проводиться представниками
              підприємств міста Вінниця
            </Typography>
            <Typography paragraph>27.03.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відкрита лекція для студентів, що проводиться представниками
              підприємств міста Вінниця. Час: 11 квітня 2024 р. Початок о 10.15.
              Посилання: https://ivp.vn.ua/seminar. Запрошуються усі бажаючі!
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews110424}
                alt=""
                style={{ maxWidth: "800px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Зустріч студентів 4 курсу навчання із завідувачем кафедри Козловим
              Л.Г., відповідальним за переддипломну практику на кафедрі ТАМ
              Лозінським Д.О. та співробітниками кафедри
            </Typography>
            <Typography paragraph>27.03.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулася зустріч студентів 4 курсу навчання із завідувачем
              кафедри Козловим Л.Г., відповідальним за переддипломну практику на
              кафедрі ТАМ Лозінським Д.О. та співробітниками кафедри.
              Обговорювались питання стосовно виконання бакалаврської дипломної
              роботи та проходження переддипломної практики.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews270324}
                alt=""
                style={{ maxWidth: "800px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Нагородження!
            </Typography>
            <Typography paragraph>27.03.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              За багаторічну сумлінну працю, вагомий внесок у підготовку
              висококваліфікованих спеціалістів та науково-педагогічну
              діяльність доцента кафедри Піонткевича О.В. нагороджено подякою
              Міністерства освіти і науки України . Бажаємо подальших здобутків
              та звершень.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                style={{ maxWidth: "300px" }}
                src={photoNews270324_2}
                alt=""
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Відкрита зустріч з експертами ОП
            </Typography>
            <Typography paragraph>07.03.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відкрита зустріч з експертами ОП 5527 Технології машинобудування
              (131), магістри. Час: 7 березня 2024 р. з 15.15 по 15.45 За
              посиланням:
              https://us04web.zoom.us/j/71183278606?pwd=OGj7z2ycyZgQ8jNSXBaWmybSomBHI8.1
              Ідентифікатор: 711 8327 8606 Код: 2024 Запрошуються усі
              зацікавлені! Прохання при підключенні вказувати Ім'я та Прізвище.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews210223}
                alt=""
                style={{ maxWidth: "200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Cтворення спеціалізованої вченої ради
            </Typography>
            <Typography paragraph>21.02.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              21 лютого на засіданні Атестаційної колегії Міністерства освіти та
              науки України прийнято рішення про створення спеціалізованої
              вченої ради із присудження наукового ступеня доктора наук за
              спеціальностями 05.02.02 «Машинознавство» та 05.22.20
              «Експлуатація та ремонт засобів транспорту». До складу ради
              увійшов д-р техн. наук, проф. Козлов Л.Г. Голова ради – д-р техн.
              наук, проф. Біліченко В.В. Щиро вітаємо науковців, причетних до
              цієї видатної та важливої події, бажаємо плідної роботи й
              подальших наукових здобутків!
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews210223}
                alt=""
                style={{ maxWidth: "200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Ювілей
            </Typography>
            <Typography paragraph>16.01.2024</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              З нагоди 75-річчя вітаємо професора кафедри технологій та
              автоматизації машинобудування Вінницького національного технічного
              університету, заслуженого працівника освіти України, відмінника
              освіти України, кавалер ордена «За заслуги» III ступеня{" "}
              <a
                href="https://uk.wikipedia.org/w/index.php?title=%D0%91%D1%83%D1%80%D1%94%D0%BD%D0%BD%D1%96%D0%BA%D0%BE%D0%B2_%D0%AE%D1%80%D1%96%D0%B9_%D0%90%D0%BD%D0%B0%D1%82%D0%BE%D0%BB%D1%96%D0%B9%D0%BE%D0%B2%D0%B8%D1%87&stable=1"
                target="_blank"
              >
                {" "}
                Юрія Анатолійовича Бурєннікова
              </a>
              . Зичимо йому міцного здоров'я, наснаги та подальших життєвих і
              професійних здобутків.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews160124}
                alt=""
                style={{ maxWidth: "200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root} id="news281223">
          <CardContent>
            <Typography variant="h5" component="h2">
              Екскурсія на виробництво
            </Typography>
            <Typography paragraph>28.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулася екскурсія викладачів та студентів на підприємство ТДВ
              "Брацлав". Це підприємство є одним з найпотужніших в Вінницькій
              області та має сучасне обладнання. На підприємстві є базові
              практики для студентів спеціальності Прикладна механіка.
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews281223} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root} id="news271223">
          <CardContent>
            <Typography variant="h5" component="h2">
              Захист дисертації
            </Typography>
            <Typography paragraph>27.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              27.12.23р У спеціалізованій вченій раді ДФ 05.052.024 у
              Вінницькому національному технічному університеті відбувся захист
              дисертаційної роботи Перепелиці В’ячеслава Ігоровича на тему
              «Система гідравлічних приводів синхронізації робочих рухів
              автоматизованої установки для формування заготовок цегли» за
              спеціальністю 131 «Прикладна механіка» на здобуття наукового
              ступеня доктора філософії. Керівник роботи професор, д.т.н. Козлов
              Леонід Геннадійович. Вітаємо Перепелицю В.І. та Козлова Л.Г. з
              успішним захистом та бажаємо подальших звершень.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                style={{ maxWidth: "200px" }}
                className={classes.photo}
                src={photoNews271223}
                alt=""
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Виступи та лекції представників стейкхолдерів
            </Typography>
            <Typography paragraph>26.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Було проведено відкриту лекцію начальником
              виробничо-технологічного відділу ТОВ «Грін Кул» Ницимайлом В.О. на
              тему «Система Lean Management»
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews261223}
                alt=""
                style={{ maxWidth: "1200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Міжнародне стажування та тренінг
            </Typography>
            <Typography paragraph>25.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Вітаємо старосту групи 1ПМ-23м Леонтьєва Володимира із успішним
              завершенням міжнародного стажування за темою «Modern Methods in
              Educatiom». Бажаємо подальших успіхів!
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews251223}
                alt=""
                style={{ maxWidth: "300px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Зустріч щодо програм академічної мобільності
            </Typography>
            <Typography paragraph>22.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Для здобувачів ФМТ інформаційну зустріч щодо програм академічної
              мобільності "Подвійний диплом" буде проведено 22.12.2023 р. о
              14-15 за{" "}
              <a href="https://meet.google.com/pkr-ewbu-fjw" target="_blank">
                посиланням
              </a>
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews221223}
                alt=""
                style={{ maxWidth: "600px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Захист випускних робіт
            </Typography>
            <Typography paragraph>18.12 - 20.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              З 18.12-20.12 відбувся захист магістерських кваліфікаційних робіт
              магістрантів денної форми навчання зі спеціальності Прикладна
              механіка. Захищено 27 магістерських кваліфікаційних робіт, серед
              яких є комплексні роботи та роботи рекомендовані до впровадження.
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews181223} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Виступи та лекції представників стейкхолдерів
            </Typography>
            <Typography paragraph>14.12.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Заступник начальника з науки та досліджень Казенного
              науково-виробничого об'єднання "Форт" МВС України Завадюк Сергій
              Вікторович провів студентам 131 спеціальності «Прикладна механіка»
              лекцію на тему "Інжекціне лиття порошку. Рекомендації з
              конструювання литих деталей"
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews141223}
                alt=""
                style={{ maxWidth: "1200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Зустріч магістрів 1 курсу навчання із завідувачем кафедри Козловим
              Л.Г. та куратором Сердюк О.В.
            </Typography>
            <Typography paragraph>30.11.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулася зустріч магістрів 1 курсу навчання із завідувачем
              кафедри Козловим Л.Г. та куратором Сердюк О.В.. Здобувачів
              ознайомили із наказом №323 від 20.11.2023р. "Про підготовку та
              проведення зимової заліково-екзаменаційної сесії для здобувачів
              вищої освіти денної та заочної форм навчання у 2023-2024 н.р.
              Обговорили питання успішності навчання.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews301123}
                alt=""
                style={{ maxWidth: "800px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Захист проєктів школи "Sikorsky Challenge Vinnytsia"!
            </Typography>
            <Typography paragraph>21.11.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              21 листопада 2023 року відбувся фінальний захист проєктів VIIІ
              сезону стартап школи "Sikorsky Challenge Vinnytsia". На фінальному
              заході приймали участь студенти Факультету машинобудування та
              транспорту кафедри технологій та автоматизації машинобудування –
              Микола Маркевич, Богдан Василишен, Поліна Кузьменко та Артур
              Літвін. Наукові керівники – д.т.н., проф. Леонід Козлов та к.т.н.
              Олег Піонткевич. Сформована команда представляла стартап проєкт
              під назвою «EcoWorker». Основною ідеєю стартап проєкту є розробка,
              яка полегшить працю фермерів у садках. Ecoworker – це інноваційний
              транспортний засіб, що працює виключно на електроенергії. Це
              дозволяє не лише зменшити екологічне навантаження, але й значно
              знизити витрати на пальне під час роботи. Проте це не єдина його
              перевага. Надто важливою є те, що він оснащений системою ножичного
              підйому, що дозволяє ефективно піднімати вантажі до 500 кг та
              забезпечує безпечне транспортування працівників на висоту.
              Компактність, простота використання та універсальність роблять
              його ідеальним рішенням для власників садів різних розмірів,
              підвищуючи продуктивність. Усі учасники фінальних заходів VIIІ
              сезону стартап школи "Sikorsky Challenge Vinnytsia" отримали
              сертифікати про її завершення, сертифікати про проходження
              закордонного стажування на 4 кредити та грошові винагороди
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews211123} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Міжнародне стажування та тренінг
            </Typography>
            <Typography paragraph>15.11.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Вітаємо наших студентів Рижих Олександру та Мицик Івана із
              завершенням тренінгу на тему «Формуємо активну соціальну
              поведінку» і підтвердженням вміння «Ведення взаємовигідних
              переговорів та уміння створювати надихаючу візію». Успіхів у
              подальших звершеннях!
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews151123} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Екскурсія на виробництво
            </Typography>
            <Typography paragraph>04.11.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Студенти ФМТ відвідали підприємство ViOil “Вінницький
              олійножировий комбінат”{" "}
              <a
                href="https://fmt.vntu.edu.ua/studenty-fmt-vidvidaly-pidpryiemstvo-vinnytskyj-olijnozhyrovyj-kombinat-vinoil/#more-1581"
                target="_blank"
              >
                (посилання)
              </a>
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={
                  "https://fmt.vntu.edu.ua/wp-content/uploads/2023/11/vinoil02-1024x768.jpg"
                }
                alt=""
                style={{ maxWidth: "600px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Студентська осінь!
            </Typography>
            <Typography paragraph>03.11.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Студентська осінь 🍁- традиційний захід для першокурсників ВНТУ -
              Вінницький національний технічний університет , де 8 факультетів
              демонструють свою творчу майстерність та таланти в різних
              номінаціях.
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews031123} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Збори магістрантів 1 курсу
            </Typography>
            <Typography paragraph>14.09.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулися перші збори магістрантів 1 курсу та викладачів кафедри
              технологій та автоматизації машинобудування. Студенти
              познайомилися із завідувачем кафедри д.т.н. проф. Козловим
              Леонідом Геннадійовичем та викладачами кафедри. Крім того
              студентів ознайомлено із рядом положень університету:
              <ol>
                Положення про рейтингову систему оцінювання навчальних досягнень
                здобувачів вищої освіти у Вінницькому національному технічному
                університеті
              </ol>
              <ol>
                Положення про запобігання та протидію булінгу у Вінницькому
                національному технічному університеті
              </ol>
              <ol>
                Положення про організацію індивідуального графіка навчання
                здобувачів вищої освіти у Вінницькому національному технічному
                університеті
              </ol>
              <ol>
                Положення про академічну доброчесність у ВНТУ Положення про
                освітню, організаційну, інформаційну, консультаційну та
                соціальну підтримку здобувачів вищої освіти у Вінницькому
                національному технічному університеті
              </ol>
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews140923}
                alt=""
                style={{ maxWidth: "800px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root} id="news210623">
          <CardContent>
            <Typography variant="h5" component="h2">
              Нагородження!
            </Typography>
            <Typography paragraph>21.06.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Нагрудним знаком МОН України «Відмінник освіти» нагороджено:
              завідувача кафедри технологій та автоматизації машинобудування,
              д-ра техн. наук, проф. Леоніда Козлова; Бажаємо подальших
              здобутків та звершень.
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews210623} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Захист випускових робіт
            </Typography>
            <Typography paragraph>20.06.2023-21.06.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              20-21 червня 2023 відбувся захист бакалаврських та магістерський
              випускових робіт студентів. Вітаємо студентів з успішним захистом
              та бажаємо падальших успіхів у житті
            </Typography>
            <div className={classes.photocontainer}>
              <img className={classes.photo} src={photoNews200623} alt="" />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root} id="news200223">
          <CardContent>
            <Typography variant="h5" component="h2">
              Нагородження!
            </Typography>
            <Typography paragraph>20.02.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Доцента кафедри Сердюк О.В. нагороджено грамотою Вінницької
              обласної ради. Бажаємо подальших здобутків та звершень.
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                style={{ maxWidth: "300px" }}
                src={photoNews01}
                alt=""
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Зустріч зі стейкхолдерами
            </Typography>
            <Typography paragraph>02.02.2023</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Відбулася зустріч колективу кафедри ТАМ із стейкхолдерами
              освітньо-професійної програми «Технології машинобудування» за
              спеціальністю 131 «Прикладна еханіка». На зустрічі були присутні
              представники з виробництва, зокрема начальник
              виробничо-технологічного відділу ТОВ «Грін Кул» Ницимайла В.О.,
              головний технологог ПрАТ «Вінницький завод «МАЯК» Марчук А.П.,
              студенти, випускники ). Усі підтримали ОПП, водночас внесли
              важливі пропозиції, що обовязково будуть враховані. Дякуємо усім
              за співпрацю!
            </Typography>
            <div className={classes.photocontainer}>
              <img
                className={classes.photo}
                src={photoNews020223}
                alt=""
                style={{ maxWidth: "1200px" }}
              />
            </div>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              День машинобудівника!
            </Typography>
            <Typography paragraph>26.09.2021</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              Колектив Факультету машинобудування і транспорту вітає вас з
              професійним святом – Днем машинобудівника! Машинобудування є
              ключовою галуззю і основою промислового потенціалу України. Від
              ефективності нашої роботи, впровадження інноваційної продукції,
              підвищення її конкурентоспроможності багато в чому залежить
              динаміка економічного зростання країни та добробут її громадян.
              Сьогодні наше спільне свято! Бажаємо вам професійних успіхів,
              нових розробок, творчого натхнення та жіттєвої енергії!
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Зміни в організації освітнього процесу з 23.09.2021 р.
            </Typography>
            <Typography paragraph>22.09.2021</Typography>
            <Typography paragraph style={{ textAlign: "justify" }}>
              До уваги студентів ФМТ всіх курсів, денної та заочної форм
              навчання! У зв’язку із рішенням Державної комісії з питань
              техногенно-екологічної безпеки та надзвичайних ситуацій щодо
              переходу України в “жовту” зону з 23 вересня відбулись зміни в
              організації освітнього процесу. Всі заняття здобувачів денної та
              заочної форм навчання відбувається згідно розкладу в дистанційному
              режимі (on-line). Робота деканату проходить у штатному режимі, але
              із дотриманням вимог дії карантину. Просимо дотримуватись
              необхідних заходів безпеки та індивідуального захисту під час
              відвідування деканату. З метою полегшення вирішення питань, які
              можуть бути вирішені у дистанційному режимі, просимо скористатися
              контактним телефоном деканату (в тому числі Viber, Telegram чи
              WhatsApp) або електронною поштою.
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2">
              ІІ тур Всеукраїнського конкурсу студентських робіт із напрямку
            </Typography>
            <Typography variant="h5" component="h2">
              «Прикладна механіка (Механотроніка)»
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              size="small"
              className={classes.pos}
              target="_blank"
              rel="noreferrer"
              href="http://tam.vntu.edu.ua/images/Results.pdf"
            >
              Результати
            </Button>
          </CardActions>
        </Card>
        <Card className={classes.root}>
          <CardContent>
            <Typography
              variant="h5"
              component="h2"
              style={{ textAlign: "justify" }}
            >
              Захист наукових робіт на підсумковій науково-практичній
              конференції відбувся 28-29 квітня 2021 року у режимі
              відеоконференції.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </main>
  );
}

export default News;
